/* eslint-disable no-unused-vars */
import React from "react";
import { useContext, useState } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import PhoneInput from 'react-phone-input-2'
import Tip from "../../forms/sub-components/widgets/Tip";
import { formatPhone, unformatedPhone } from "general/FormatNumber";
import startsWith from 'lodash.startswith';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import ValidationError from "../ValidationError";
import { useForm } from "react-hook-form";
import RoundedButton from "components/Buttons/RoundedButton";
import { orderCreate, orderUpdate } from "redux/actions/OrderAction";
import { toast } from "react-toastify";

const SimplePhone = ({ setStep, activeStep, stepKey, heading, placeholder, tip, prevStep, tipBody, tipQuestion }) => {

	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const dispatch = useDispatch();
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [tipEnable, setTipEnable] = useState(false)
	const [loading, setLoading] = useState(false)
	const containerClass = "PhoneInput";
	const [phoneNumber, setPhoneNumber] = useState(ExistingNumberData?.first_number?.phoneNumber)
	const [btnPhoneNumber, setBtnPhoneNumber] = useState(ExistingNumberData?.btn_number?.phoneNumber)

	const { handleSubmit, formState: { errors, isValid }, setError, clearErrors, setValue } = useForm({
		defaultValues: {
			'first_number': ExistingNumberData?.first_number?.phoneNumber || "",
			'btn_number': ExistingNumberData?.btn_number?.phoneNumber || ""
		}
	});

	if (ExistingNumberData?.is_this_the_main_number_on_the_business_account === "YES") {
		if (prevStep === 8) {
			setStep(activeStep + 1)
		} else if (prevStep === 10) {
			setStep(activeStep - 1)
		}
	}
	if (ExistingNumberData.first_number.number_type === "TOLL_FREE") {
		console.log("Simple Phone", prevStep)
		if (prevStep === 8) {
			setStep(activeStep + 1)
		} else if (prevStep === 10) {

			setStep(activeStep - 2)
		}
	}
	const handleOnChange = (e, value) => {
		// validate phone via library
		if (e.length > 1) {
			clearErrors() // remove validation errors on change
			const phoneNumber = parsePhoneNumber(e, value.countryCode.toUpperCase())
			// checking if number is valid or not via libphonenumber-js
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, 'ready': false })
				setError('first_number', {
					message: "Number is not valid"
				})
				setError('btn_number', {
					message: "Number is not valid"
				})
				return false
			}

			if (phoneNumber.isValid() && phoneNumber.getType()) {
				let phoneNumberP = {
					phoneNumber: formatPhone(e),
					dialCode: value.dialCode,
					name: value.name,
					countryCode: value.countryCode,
					countryId: countryList.filter(row => row.code === value.countryCode)[0]?.value,
					number_type: phoneNumber.getType()
				}
				if (stepKey === 'lets_start_with_the_first_phone_number_you_want_to_move') {
					setValue('first_number', e)
					setPhoneNumber(e)
					setExistingNumberData({ ...ExistingNumberData, 'first_number': phoneNumberP, error: [], processing: false })
				}
				if (stepKey === 'please_specify_the_main_number_on_the_business_account') {
					setValue('btn_number', e)
					setBtnPhoneNumber(e)
					setExistingNumberData({ ...ExistingNumberData, 'btn_number': phoneNumberP, error: [], processing: false })
				};
			}
		}

	}
	const submitHandler = async (e) => {
		if (stepKey === 'lets_start_with_the_first_phone_number_you_want_to_move') {
			setLoading(true)

			// if order already created it will just update the order otherwise it will create new
			if (ExistingNumberData?.order_did.length) {
				const payload = {
					'number_id': ExistingNumberData.order_did[0]?.id, // order did Id
					'order_id': ExistingNumberData.order_did[0]?.order_id, // order did Id
					"number": unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
					"country_id": ExistingNumberData?.first_number?.countryId
				}

				await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(response => {
					setLoading(false)
					showToast()
					setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
					setStep(activeStep + 1)
				}).catch(exp => {
					console.log(exp)
					// setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
					setLoading(false)
				})

			} else {

				if (ExistingNumberData?.first_number === "") {
					setError('first_number', { 'message': "Number field is required" })
					setLoading(false);
					return false;
				}

				const payload = {
					"new_number": false,
					"user_id": ExistingNumberData.user_id,
					"authorized_first_name": ExistingNumberData.firstname,
					"authorized_last_name": ExistingNumberData.lastname,
					"number": unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
					"country_id": ExistingNumberData?.first_number?.countryId
				}

				await dispatch(orderCreate(payload, ExistingNumberData.userData.token)).then(response => {
					setLoading(false)
					showToast()
					setExistingNumberData({ ...ExistingNumberData, 'order_id': response?.result?.order_id, 'order_did': [response.result], error: [], processing: false, ready: false })
					setStep(activeStep + 1)

				}).catch(exp => {
					setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
					console.log(exp)
					setLoading(false)
				})
			}
		}
		if (stepKey === 'please_specify_the_main_number_on_the_business_account') {
			setLoading(true)
			if (ExistingNumberData?.btn_number === "") {
				setError('first_number', { 'message': "Number field is required" })
				setLoading(false);
				return false;
			}
			const payload = {
				'order_id': ExistingNumberData?.order_id,
				"btn": unformatedPhone(ExistingNumberData?.btn_number?.phoneNumber)
			}
			await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(response => {
				setLoading(false)
				showToast()
				setStep(activeStep + 1)
				setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
			}).catch(exp => {
				setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
				console.log(exp)
				setLoading(false)
			})
		}
	}


	const showToast = () => {
		toast.success("Your Data has been saved", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light", })
	}

	useEffect(() => {
		setExistingNumberData({ ...ExistingNumberData, ready: false, error: [] })
		if (stepKey === 'lets_start_with_the_first_phone_number_you_want_to_move') {
			if (phoneNumber) {
				if (unformatedPhone(phoneNumber)?.length >= 11) {
					setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
				} else {
					setExistingNumberData({ ...ExistingNumberData, error: ["Invalid Length"], "ready": false })
				}
			}
		}

		if (stepKey === 'please_specify_the_main_number_on_the_business_account') {
			if (btnPhoneNumber) {
				if (btnPhoneNumber?.length >= 11) {
					setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
				} else {
					setExistingNumberData({ ...ExistingNumberData, error: ["Invalid Length"], "ready": false })
				}
			}
		}


	}, [phoneNumber, btnPhoneNumber])

	return (
		<>
			<form onSubmit={handleSubmit(submitHandler)}>
				<ValidationError errors={errors} />
				<div className="step">
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">{heading}</h2>
					<div className="row">
						<div className="col">
							<PhoneInput
								country={'us'}
								inputClass="default"
								onlyCountries={['us', 'ca']}
								value={stepKey === 'lets_start_with_the_first_phone_number_you_want_to_move' ? phoneNumber : btnPhoneNumber}
								countryCodeEditable={false}
								containerClass={(ExistingNumberData.error.includes("initial-number") ? "error" : "") + " " + containerClass}
								onChange={(e, value) => handleOnChange(e, value)}
								placeholder={placeholder}
								isValid={(inputNumber, country, countries) => {
									return countries.some((country) => {
										return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
									});
								}}
							/>
						</div>
					</div>
					<Tip tipBody={tipBody} tipQuestion={tipQuestion} />

					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} loadingText={'Proceed'} showLoader={false}>
									Proceed
								</RoundedButton>
								<div className="pe">
									Press Enter
									<img src="/images/forms/icon-enter.png" alt="" />
								</div>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</div>

			</form>
		</>
	)
}

export default SimplePhone
