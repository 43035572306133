import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const Login = () => {

	const { login, socialLogin } = useAuth();
	const history = useHistory();
	const { isAuthenticated } = useAuth();
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, formState: { errors } } = useForm();

	const submitHandler = async (form) => {
		setLoading(true)
		try {
			await login(form.email, form.pass);

			history.push('/customer/dashboard')

		} catch (e) {
			toast.error(e.message)
			console.log(e);
			setLoading(false)
		}

		setLoading(false)
	}

	useEffect(() => {

		if (isAuthenticated) {
			history.push('/')
		}
	}, [isAuthenticated, history])

	return (
		<>
			<Title title={"Login"} />
			<Topbar />

			<section className="login_section">
				<div className="container">
					<div className="row justify-content-end">
						<div className="col-xl-6 col-md-8 col-12">
							<div className="limiter">
								<div className="container-login100">
									<div className="wrap-login100">
										<form className="login100-form validate-form" onSubmit={handleSubmit(submitHandler)} autoComplete="off">
											<img src="/images/login-icon.png" className="login-icon" alt="logo" />
											<span className="login100-form-title slide-right">
												Login
											</span>
											<p className="login100-form-subtitle">
												Login with your ID and Password.
											</p>
											<div className="wrap-input100">

												<input
													className={errors.email ? "input100 border border-danger" : "input100"}
													type="email"
													placeholder="Email"
													{...register("email", { required: true })}
												/>

												{errors.email && <span className='text-danger text-small input100-valdation'>Email field is required</span>}
											</div>

											<div className="wrap-input100 validate-input" data-validate="Password is required">
												<input className={errors.pass ? "input100 border border-danger" : "input100"} type="password" name="pass" placeholder="Password" {...register("pass", { required: true })} />
												{errors.pass && <span className='text-danger text-small input100-valdation'>Password field is required</span>}
											</div>

											<div className="mt-4">
												<div className="wrap-login100-form-btn">
													<button type='submit' className="login100-form-btn" disabled={loading}>
														{
															loading ?
																<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																:
																"Login"
														}
													</button>

													<div className="text-right forgot_password">
														<Link to="/forgot-password">
															Forgot password?
														</Link>
													</div>
												</div>
											</div>

											<div className="txt1 text-center">
												<span>
													Or Login with
												</span>
											</div>
											<div className="flex-c-m social-login-buttons">
												<div className="login-social">
													<button type="button" onClick={() => { socialLogin("azure", 'login'); setLoading(true) }} disabled={loading} className="social-facebook"><img alt='google' src="/images/microsoft.png"
														width="24px" height="24px" />
														Microsoft
													</button>
												</div>
												<div className="login-social">
													<button type="button" onClick={() => { socialLogin("google", 'login'); setLoading(true)  }} disabled={loading} className="social-google"><img alt='google' src="/images/google.png"
														width="24px" height="24px" />
														Google
													</button>
												</div>
											</div>

											<div className="flex-col-c signup-link">
												<span className="txt2">
													Not a user?
												</span>
												<Link className="text-decoration-none txt2" to="/signup"> {" "}
													Signup now
												</Link>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	)
}

export default Login
