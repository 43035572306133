import React, { useContext, useState } from "react";

import { PhoneInput } from "react-contact-number-input";
import PinField from "react-pin-field"
import { CoverageContext } from "contexts/FormsContext";
import FormFooter from "./FormFooter";

const PhoneVerification = ({ setStep, activeStep, prevStep, setProcessing }) => {
	let { coverage, setCoverage } = useContext(CoverageContext)

	let [verify_req_sent, set_verify_req_sent] = useState(false)
	if (coverage.activeSocialFormat !== "email") {

		if (prevStep === undefined || prevStep < 4) {
			setStep(activeStep + 1)
		} else if (prevStep === 6) {
			setStep(activeStep - 1)
		}
	}
	const disabled = false;
	const containerClass = "PhoneInput";
	const currentCountryCode = "us";
	const handleOnChange = (e) => {

		setCoverage({ ...coverage, first_phone: e })
	};
	const placeholder = "Enter Phone Number";
	const countryData = {
		countryCode: "us",
		dialCode: "1",
		format: "+. (...) ...-....",
		name: "United States",
	}

	// let [verification_pref, setVerificationPref] = useState('text_message');
	// eslint-disable-next-line no-unused-vars
	const [demoCompleted, setDemoCompleted] = useState(false);
	// const [code, setCode] = useState("");
	// const [completed, setCompleted] = useState(false);

	return (
		<div className="step ninth">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Can we get a current working mobile phone number?</h2>
			<div className="row current_working_number mb-3">
				<div className="col d-flex">
					<PhoneInput
						inputClass="default"
						disabled={disabled}
						containerClass={containerClass}
						countryCode={currentCountryCode}
						onChange={handleOnChange}
						placeholder={placeholder}
						countryData={countryData}

					/>
					<button type="button" className="phoneMaskContinue" id="code-button" disabled="" onClick={() => set_verify_req_sent(verify_req_sent = true)}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
							<path fill="var(--ci-primary-color, currentColor)" d="M474.444,19.857a20.336,20.336,0,0,0-21.592-2.781L33.737,213.8v38.066l176.037,70.414L322.69,496h38.074l120.3-455.4A20.342,20.342,0,0,0,474.444,19.857ZM337.257,459.693,240.2,310.37,389.553,146.788l-23.631-21.576L215.4,290.069,70.257,232.012,443.7,56.72Z" className="ci-primary"></path>
						</svg>
					</button>
				</div>
			</div>
			{
				verify_req_sent &&
				<div className="row mb-3">
					<div className="col">
						<label className="mb-3">Enter 4 digit verification code sent to above number to verify your identity.</label>
						<div className="code">
							<div className="digits">
								<PinField length={4} validate="0123456789" inputMode="numeric"
									// ref={ref}
									className={"pin-field"} onComplete={() => setDemoCompleted(true)} autoFocus />
							</div>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a href="#" className="resend-code">
								<img src="/images/forms/icon-resend-code-blue.png" alt="" />
								Resend the code
							</a>
						</div>
						<div id="timer"></div>
					</div>
				</div>
			}

			<FormFooter action={"signup-update"} setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default PhoneVerification
