import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCarriers } from "redux/actions/CarrierAction";
import { getCountries } from "redux/actions/StreetMapAction";
import FormFooter from "./FormFooter";

const Initial = ({ setStep, activeStep, activeFunnel, setFunnel }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getCarriers())
		dispatch(getCountries())
	}, [dispatch])

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading text-center">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading text-center">Select Service you are Interested in</h2>
			<div className="mt-25 container1">
				<div className="radio-group row justify-content-evenly align-items-center px-3 text-center a">
					<div className={((activeFunnel === 0) ? "selected" : "") + " col-auto ml-sm-2 mx-1 card-block text-center p-0 radio"} id="existing_num" onClick={() => setFunnel(activeFunnel = 0)}>
						<div className="flex-row">
							<div className="col">
								<div className="pic"> <img className="irc_mut img-fluid" src="/images/forms/existing-num.png" width="80" alt='' height="80" />
								</div>
								<p>Keep my existing number</p>
							</div>
						</div>
					</div>
					{/* <div className="col-2">
						<img src="/images/forms/or.png" className="img-fluid" alt='' />
					</div> */}
					{/* <div className={((activeFunnel === 1) ? "selected" : "") + " col-auto ml-sm-2 mx-1 card-block text-center p-0 radio"} id="new_num" onClick={() => setFunnel(activeFunnel = 1)}> */}
					{/* <div className={((activeFunnel === 1) ? "selected" : "") + " col-auto ml-sm-2 mx-1 card-block text-center p-0 radio"} id="new_num" >
						<div className="flex-row">
							<div className="col">
								<div className="pic"> <img className="irc_mut img-fluid" src="/images/forms/new-number.png" width="80" alt="" height="80" />
								</div>
								<p>Get a new number</p>
							</div>
						</div>
					</div> */}
				</div>
			</div>

			<FormFooter activeStep={activeStep} setStep={setStep} centerProceed={true} />
		</div>
	)
}

export default Initial
