import React from 'react'
import MultiCarousel from '../MultiCarousel'
import 'pages/order/assets/css/plans.scoped.css';
import { useDispatch, useSelector } from 'react-redux';
import FormFooter from '../FormFooter';
import { useContext } from 'react';
import { ExistingNumberContext } from 'contexts/FormsContext';
import { useState } from 'react';
import { getPlansByCategory } from 'redux/actions/OrderAction';
import { useEffect, useRef } from 'react';
import MiniLoader from 'components/Loader/MiniLoader';
import Select from 'react-select';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';
import { getCarriers } from 'redux/actions/CarrierAction';

const PlanOptions = ({ setStep, activeStep }) => {
	const [count,setCount] = useState(1);
	const isPeriodSet = useRef(false);
	const dispatch = useDispatch()
	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const planForCountry = ExistingNumberData.first_number.countryId || 230;
	const [selectedCategory, setSelectedCategory] = useState(ExistingNumberData?.planCategory || '')
	const { planCategoryList } = useSelector(state => state?.orders)
	const [selectedRate, setSelectedRate] = useState(ExistingNumberData.plan_rate_id || ''); //selected rate id
	const [selectedRateDetails, setSelectedRateDetails] = useState(ExistingNumberData?.plan_details);
	const plansWithRates = useSelector(state => state.orders?.planByCategory);
	const [ratesList, setRatesList] = useState(plansWithRates);
	const [down, setDown] = useState(false);
	const [up, setUp] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(0); //selected rate id

	const [monthList, setMonthList] = useState([]); //selected rate id

	const carrierImageURL = `${process.env.REACT_APP_API_URL}/uploads/carrier-images/`;
	const defaultCarrierImg = `/images/forms/test-icon.png`;
	const [period, setPeriod] = useState([]); //selected rate id
	

useEffect(() => {
	// Ensure ratesList is available and not empty
	if (ratesList.length > 0 && !isPeriodSet.current) {
		// Get unique periods and sort them
		const uniquePeriods = Array.from(new Set(ratesList.map(item => item.period)))
			.sort((a, b) => a - b);

		// Update the state
		setPeriod(uniquePeriods);
		isPeriodSet.current = true; // Mark as set

		
	}
}, [ratesList]);

	const carrierList = useSelector(state => state.carrier.map(carrierRow => {
		return {
			label: carrierRow?.name,
			value: carrierRow?.id,
			image: carrierRow?.carrier_image ? carrierImageURL + carrierRow?.carrier_image : defaultCarrierImg
		}
	}))
	const customStyles = {
		control: () => ({
			// none of react-select's styles are passed to <Control />
			height: 56,
			width: "100%",
			background: "#fff",
			padding: "31px 15px",
			display: "flex",
			alignItems: "center",
			border: "1px solid #dbdbd9",
			borderRadius: "4px"
		}),
	}
	const customStyle = {
		carrierImage: {
			marginRight: "10px",
			borderRadius: "50%",
			height: "40px",
			objectFit: "cover",
			objectPosition: "center"
		}
	}
	var rates = [];
	const handleSelectedCategoryChange = (e) => {
		setRatesList([])
		setSelectedCategory(e);

		setExistingNumberData({
			...ExistingNumberData,
			'planCategory': e,
			'planChange': false,
			'plan_voice_email': "",
			'plan_text_email': "",
			'plan_forward_number': "",
			'plan_forward_number_carrier': "",
			'plan_forward_number_country_id': "",
		})
		let filterNumber = ""

		switch (true) {
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "YES":
				filterNumber = 'fax';
				break;
			case ExistingNumberData.is_this_a_mobile_number === "YES":
				filterNumber = 'cell phone';
				break;
			case ExistingNumberData.first_number?.number_type === "TOLL_FREE":
				filterNumber = 'toll free';
				break;
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "NO" && ExistingNumberData.is_this_a_mobile_number === "NO":
				filterNumber = 'regular landline';
				break;
			default:
				filterNumber = '';
				break;
		}
		const payload = {
			'country_id': planForCountry,
			'category_id': e,
			'phone_type': filterNumber,
		};

		dispatch(getPlansByCategory(payload));
		setSelectedMonth(0);
		setSelectedRateDetails({});
	}

	const handleChange = ({ target: { dataset, value } }) => {
		setSelectedRate(value)
		setExistingNumberData({
			...ExistingNumberData,
			'plan_rate_id': value,
			'plan': dataset?.planId,
			'planChange': true,
			'plan_details': plansWithRates.filter((data) => data.id == value)[0]

		})
		setSelectedRateDetails(plansWithRates.filter((data) => data.id == value)[0]);
	}
	const handleIncreaseDecrease = (op) => {

		if (op == -1) {
			setDown(true);
			setUp(false);
		}
		else {
			setDown(false);
			setUp(true);
		}
		 
		setTimeout(() => {
			setSelectedMonth(selectedMonth + op);
			
			setDown(false);
			setUp(false);

			
		}, 500);

		//  FILTER RATES LIST ACCORDING TO MONTHS
		var ratesList = plansWithRates.filter((x) => x.period == monthList[selectedMonth + op])
		setRatesList(ratesList)
	}
	const inputChangeHandler = ({ target: { name, value } }) => {

		setExistingNumberData({ ...ExistingNumberData, [name]: value })
	}
	const selectChangeHandler = (e) => {
	
		setExistingNumberData({ ...ExistingNumberData, "plan_forward_number_carrier": e })
	}
	const handleOnChange = (e, value) => {
		// validate phone via library
		if (e.length > 10) {
			setExistingNumberData({ ...ExistingNumberData, error: [], ready: true })
			const phoneNumber = parsePhoneNumber(e, value.countryCode.toUpperCase())
			// checking if number is valid or not via libphonenumber-js
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, error: ['invalid_phone'] })
				return false
			}

			if (phoneNumber.isValid() && phoneNumber.getType()) {
				setExistingNumberData({
					...ExistingNumberData,
					'plan_forward_number': e,
					'plan_forward_number_country_id': countryList.filter(row => row.code === value.countryCode)[0]?.value,
					error: [],
					processing: false
				})
			}
		}

		
	}
	
	useEffect(() => {
		setRatesList(plansWithRates)
		//SLICING AVAILABLE PERIODS/MONTHS
		var months = plansWithRates.map((x) => x.period);
		// UNIQUE MONTHS
		var uniquemonths = months.filter((value, index, array) => array.indexOf(value) == ('0' + index).slice(-2));
		// SORTING
		uniquemonths.sort((a, b) => a - b);
		//ADDING ZERO
		var addzero = uniquemonths.map((x) => ('0' + x).slice(-2))
		setMonthList(addzero)
		dispatch(getCarriers())

		const { plan } = ExistingNumberData;
		if (!plan) {
			setExistingNumberData({ ...ExistingNumberData, 'ready': false, 'error': ['Empty Id'] })
		} else if (plan) {
			setExistingNumberData({ ...ExistingNumberData, 'ready': true, 'error': [] })
		}

	}, [plansWithRates, ExistingNumberData.plan, ExistingNumberData.plan_rate_id])

	const imageArray =["cat_one_icon","cat_two_icon","cat_three_icon","cat_four_icon"];

	const [activeButton, setActiveButton] = useState('toggle-button1');
	


	const handleClick = (id, month) => {
		
		setActiveButton(id);
	

			const newMonthIndex = selectedMonth;
			if (newMonthIndex >= 0 && newMonthIndex < monthList.length) {
			
				const filteredRates = plansWithRates.filter(x => x.period == month);
				setRatesList(filteredRates);

			}
	};

		

	return (
		<>
			<div className="row  row-cols-1 row-cols-md-1 mb-3 cat_options">
				<div className="col">
					<h5 className="input-label mb-2 mt-4 text-uppercase text-left">Selected plan category</h5>
					<div className="select-img d-flex ">
						<div className="img-box">
							<img className="category-icon mt-3 mb-4" src="/images/phone.png" alt="" height={34} />
						</div>

						<select className='sp-heading-new form-select form-select-lg mb-3' onChange={(e) => handleSelectedCategoryChange(e.target.value)} value={selectedCategory}>
							{
								planCategoryList?.map((category, index) => {
									return <option key={index} value={category?.id}>{category?.name}</option>
								})
							}
						</select>
					</div>
				</div>
				{ratesList?.length > 0 ? <div className="col d-flex justify-content-center mt-3 mb-4">
					<div className='roller-month d-flex flex-column  align-items-md-center'>
						{/* <label htmlFor="Selected Month ">Select your billing cycle</label> */}
						{/* <div className='in-de-box d-flex '>
							<button disabled={selectedMonth == 0 ? true : false} onClick={() => handleIncreaseDecrease(-1)}><i className="fa fa-angle-down" aria-hidden="true"></i></button>
							<div className='counter'>
								<div className={'number ' + (up ? 'up ' : '') + (down ? 'down' : '')}>{monthList[selectedMonth - 1]} <span>Month</span> </div>
								<div className={'number ' + (up ? 'up ' : '') + (down ? 'down' : '')}>{monthList[selectedMonth]} <span>Month</span></div>
								<div className={'number ' + (up ? 'up ' : '') + (down ? 'down' : '')}>{monthList[selectedMonth + 1]} <span>Month</span></div>
							</div>
							<button disabled={selectedMonth == monthList.length - 1 ? true : false} onClick={() => handleIncreaseDecrease(+1)}><i className="fa fa-angle-up" aria-hidden="true"></i></button>
						</div> */}
						 <div className="tri-state-toggle">
							{/* <button
								className={`tri-state-toggle-button ${activeButton === 'toggle-button1' ? 'active' : ''}`}
								id="toggle-button1"
							

								onClick={() =>{ handleClick('toggle-button1', 2); }}
							>
								{monthList[selectedMonth - 1]} Months
							</button>
							<button
								className={`tri-state-toggle-button ${activeButton === 'toggle-button2' ? 'active' : ''}`}
								id="toggle-button2"
								onClick={() =>{ handleClick('toggle-button2', 11);} }
							>
								<span><img src="images/plan_select_icon.png" alt="icon" className='img-fluid' /></span>
								<span className='ps-2'> {monthList[selectedMonth - 1]} Months</span>
							</button>
							<button
								className={`tri-state-toggle-button ${activeButton === 'toggle-button3' ? 'active' : ''}`}
								id="toggle-button3"
								onClick={() =>{ handleClick('toggle-button3', 23);}}
								
							>
								{monthList[selectedMonth - 1]} Months
							</button> */}

								{/* {period.sort((a, b) => a - b).map((p, index) => (
                                <button
                                    key={index}
                                    className={`tri-state-toggle-button ${activeButton === `toggle-button${index}` ? 'active' : ''}`}
                                    id={`toggle-button${index}`}
                                    onClick={() => handleClick(`toggle-button${index}`, p)}
                                >
                                  
								  <>
		
								  {(p==12) ? <span><img src="images/plan_select_icon.png" alt="icon" className='img-fluid' /></span> : ""}
								  </>

									<span className='ps-2'> {p} Months</span>

                                </button>
                            ))} */}

								{period.sort((a, b) => a - b).map((p, index) => (
										<button
											key={index}
											className={`tri-state-toggle-button ${activeButton === `toggle-button${index+1}` ? 'active' : ''}`}
											id={`toggle-button${index}`}
											onClick={() => handleClick(`toggle-button${index+1}`, p)}
										>
											 {activeButton === `toggle-button${index + 1}` && (
            <span><img src="images/plan_select_icon.png" alt="icon" className='img-fluid' /></span>
        )}
											<span className='ps-2'>{p} Months</span>
										</button>
									))}
						</div>

					</div>
				</div> : <></>
				}
				{
					ratesList?.length > 0 ?
						// <></>

						ratesList.map((row, index) => {

							const imageIndex = index % imageArray.length;

							var items = (
								<>
									<div className='col p- mb-4 selecotr-item new_plan_cards' >
										<input value={row.id} onChange={handleChange} type="radio" id={'radio' + rates.length} name='' className='selector-item_radio' data-plan-id={row.plan_id} checked={selectedRate == row.id} />
										<label htmlFor={'radio' + rates.length} className={(selectedRate == row.id) ? 'selector-item_label checked' : "selector-item_label"}>
											<i className="fa fa-check selected-check" aria-hidden="true"></i>
											<div className="option-box">
											
												{/* <h2 className='p-3 mb-0'>Option {rates.length + 1}</h2> */}
												<div className="side_triangle">
													<div className="d-flex justify-content-between align-items-center flex-column header_wrapper">
														<div className="plan_choice">
															<p className="choice_text text-uppercase">Best Choice</p>
														</div>
														<div className={"category-icon-wrapper"}>
															<img className="category-icon mt-3 mb-4" key={index} src={`/images/${imageArray[imageIndex]}.png`} alt="" height={34} />
														</div>
														<h2 className='p-3 mb-0 category_title'>{row?.title}</h2>
													</div>
												</div>
													<div className="box-description">
														
														<div className='white-bg-text text-center plan_text fw-bold text-uppercase'>{row?.description} </div>
													</div>
												<div className="p-4 pt-0 pb-0 box-content">

													<div className="bg_grey row-col align-items-center d-flex justify-content-between">
														<strong className='text-uppercase'>Porting fee</strong>
														<span >{parseInt(row?.porting_price) > 0 ? '$' + row?.porting_price : 'None'}</span>
													</div>
													{
														row?.minutes > 0 &&
														<div className="box-description">
															<strong className='text-uppercase d-flex justify-content-between bg_grey'>
																<span>Minutes:</span>
																<span>{row?.minutes}</span>
															</strong>
														</div>
													}
													
													<div className=" p-2 price-div d-flex justify-content-center align-items-center">
														<strong className='plan-price'>${row?.price}  </strong>
														<span className='plan-duration per_month_text ps-3 fw-bold'> per {row?.period !== 1 && row?.period} {row?.period_type}</span>
														
													</div>
													<div className="plan_text mb-3" style={{ fontSize: 14 }}>
														Including all Taxes & Fees.
													</div>
												</div>
											</div>
										</label>
									</div>
								</>
							)
							rates = [...rates, items]

						}) :
						<div className="half-screen-loader">
							<MiniLoader />
						</div>
				}
				<div className="col">
					<MultiCarousel items={rates} />
				</div>
				{/* <div>
					{
						selectedRateDetails?.text_message && (
							<>
								<h4 className='sm-heading text-uppercase'>Please provide destination email for the select plan options</h4>
								<p className="mb-2">Email</p>
								<input
									type="text"
									placeholder='Email for text message'
									name="plan_text_email"
									className='form-control py-2 mb-3'
									value={ExistingNumberData?.plan_text_email}
									onChange={inputChangeHandler}

								/>
							</>
						)
					}
					{
						selectedRateDetails?.forwarding && (
							<>
								<h4 className='sm-heading text-uppercase'>Please provide destination phone number and carrier for the select plan options</h4>
								<div className="d-flex row1-col2 mb-2">

									<div class="form-group w-50 px-1">
										<p className="mb-2">Number </p>

										<PhoneInput
											country={'us'}
											inputClass={"default"}
											onlyCountries={['us', 'ca']}
											value={ExistingNumberData?.plan_forward_number}
											countryCodeEditable={false}
											onChange={(e, value) => handleOnChange(e, value)}

										/>
									</div>
									<div class="form-group w-50 px-1">
										<p className="mb-2">Carrier </p>
										<Select
											onChange={(e) => selectChangeHandler(e)}
											value={ExistingNumberData?.plan_forward_number_carrier}
											className='w-100'
											//name='plan_forward_number_carrier_id'
											options={carrierList}
											styles={customStyles}
											formatOptionLabel={carrier => (
												<div className="carrier-option">
													<img alt="carrier" style={customStyle.carrierImage} width={40} className={"mr-1"} src={carrier.image} />
													<span>{carrier.label}</span>
												</div>
											)}
											menuPlacement="top" // Set the menuPlacement prop to "top"
										/>

									</div>
								</div>

							</>
						)
					}
					{
						selectedRateDetails.voicemail && (
							<>
								<h4 className='sm-heading text-uppercase'>Please provide destination email for the select plan options</h4>
								<p className="mb-2">Email</p>
								<input
									type="text"
									placeholder='Enter email'
									name="plan_voice_email"
									className='form-control py-2 mb-3 email-box'
									value={ExistingNumberData?.plan_voice_email}
									onChange={inputChangeHandler}
								/>
							</>
						)
					}
				</div> */}
			</div>

			<FormFooter
				action={'save-plan'}
				activeStep={activeStep}
				setStep={setStep}
				centerProceed={true}
			/>
		</>
	)
}

export default PlanOptions
