import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'pages/order/assets/css/plans.scoped.css';
import MiniLoader from 'components/Loader/MiniLoader';
import RoundedButton from 'components/Buttons/RoundedButton';
import { useContext } from 'react';
import { ExistingNumberContext } from 'contexts/FormsContext';
import { getPlansByCategory, getPlansByCategoryWithRates } from 'redux/actions/OrderAction';
import { useEffect } from 'react';

const Category = ({ activeSteps, setActiveSteps = () => { } }) => {

	const dispatch = useDispatch()
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const planForCountry = ExistingNumberData.first_number.countryId || 230;
	const [selectedCategory, setSelectedCategory] = useState(ExistingNumberData?.planCategory || '')
	const categoryList = useSelector(state => state?.orders?.planCategoryList)

	const handleNext = async (id) => {
		if (!selectedCategory) {
			setExistingNumberData({ ...ExistingNumberData, 'ready': false, 'error': ['Empty Id'] })
			return false;
		}
		setExistingNumberData({ ...ExistingNumberData, 'planCategory': selectedCategory })
		let filterNumber = ""
		switch (true) {
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "YES":
				filterNumber = 'fax';
				break;
			case ExistingNumberData.is_this_a_mobile_number === "YES":
				filterNumber = 'cell phone';
				break;
			case ExistingNumberData.first_number?.number_type === "TOLL_FREE":
				filterNumber = 'toll free';
				break;
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "NO" && ExistingNumberData.is_this_a_mobile_number === "NO":
				filterNumber = 'regular landline';
				break;
			default:
				filterNumber = '';
		}
		const payload = {
			'country_id': planForCountry,
			'category_id': selectedCategory,
			'phone_type': filterNumber
		};
		dispatch(getPlansByCategory(payload));
		setActiveSteps(id);
	}

	const handleSelectCategory = (id) => {
		setSelectedCategory(id)
		setExistingNumberData({
			...ExistingNumberData,
			'planCategory': selectedCategory,
			'ready': true,
			'error': [],
			'plan': false,
			'planChange': false,
			'plan_details': false,
			'plan_rate_id': false,
			'plan_voice_email': "",
			'plan_text_email': "",
			'plan_forward_number': "",
			'plan_forward_number_carrier': "",
			'plan_forward_number_country_id': "",
		})
	}

	useEffect(() => {
		let filterNumber = "";

		switch (true) {
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "YES":
				filterNumber = "fax";
				break;
			case ExistingNumberData.is_this_a_mobile_number === "YES":
				filterNumber = "cell phone";
				break;
			case ExistingNumberData.first_number?.number_type === "TOLL_FREE":
				filterNumber = "toll free";
				break;
			case ExistingNumberData.use_number_only_to_send_receive_faxes === "NO" &&
				ExistingNumberData.is_this_a_mobile_number === "NO":
				filterNumber = "regular landline";
				break;
			default:
				filterNumber = "";
		}
		const data = {
			country_id: planForCountry,
			phone_type: filterNumber
		};
		console.log(ExistingNumberData.use_number_only_to_send_receive_faxes)
		dispatch(getPlansByCategoryWithRates(data));

	}, [ExistingNumberData.planCategory, ExistingNumberData.use_number_only_to_send_receive_faxes, ExistingNumberData.is_this_a_mobile_number, ExistingNumberData.first_number?.number_type])


	return (
		<>
			<div className="row  row-cols-4 justify-content-center row-cols-md-4 mt-5 mb-3 text-center">

				{/* CATEGORY LIST */}
				{
					categoryList?.length > 0 ?
						categoryList?.map((categoryRow, index) => {
							return (
								<>
									{categoryList.length === 0 ?
										<></>
										:
										<div className="col cstm_width" onClick={() => handleSelectCategory(categoryRow.id)}>
											<div className={"card mb-4 rounded-4 shadow-sm plans_card" + (selectedCategory === categoryRow.id ? " active" : "")} onClick={() => setSelectedCategory(categoryRow.id)}>
												<i className="fa fa-check selected-check" aria-hidden="true"></i>
												<div className={"card-header py-3"}>
													<img className="category-icon mt-3 mb-4" src="/images/phone.png" alt="" height={34} />
													<h4 className="my-0 fw-bold font-22 mb-2 text-uppercase">{categoryRow?.name}</h4>
												</div>
												<div className="card-body">
													<p className="text-center">Starting from</p>
													<h1 className="card-title pricing-card-title fw-bold d-flex justify-content-center  align-items-md-start">${categoryRow?.least_price}<small className="power-text fw-bold text-uppercase ">/mo</small></h1>
													{/* <label className={"text-uppercase btn select_pln" + (selectedPlan === categoryRow.id ? " checked " : "")}>View Plans</label> */}
												</div>
												<input type="radio" className="btn-check" id="btn-check-1" name="plans_select" onClick={() => setSelectedCategory(categoryRow.id)} />

											</div>
										</div>
									}
								</>
							)
						})
						:
						<div className="half-screen-loader">
							<MiniLoader />
						</div>
				}

			</div>
			<RoundedButton
				loading={false}
				loadingText={""}
				onClick={() => handleNext(2)}
				showLoader={false}
				className={"m-auto"}
			>
				{"Next"}
			</RoundedButton>
		</>
	)
}

export default Category
