/* eslint-disable react/style-prop-object */
import { ExistingNumberContext } from "contexts/FormsContext";
import React, { useContext, useState } from "react";
import Cards from '@snowpak/react-credit-cards';
import "../assets/css/credit-card.css";
import '@snowpak/react-credit-cards/lib/styles-compiled.css'
import "../assets/css/form-style.css";
import { useEffect } from "react";
import RoundedButton from "components/Buttons/RoundedButton";
import { useDispatch, useSelector } from "react-redux";
import { creditCardList } from "redux/actions/PaymentAction";
import ExistingPaymentProfile from "./ExistingPaymentProfile";
import NewPaymentProfile from "./NewPaymentProfile";
import MiniLoader from "components/Loader/MiniLoader";

const PaymentDetails = ({ setStep, activeStep }) => {
	const dispatch = useDispatch()
	const [isAlive, setIsAlive] = useState(true)
	const [toggleActive, setToggleActive] = useState("")

	const paymentProfileList = useSelector(state => state.payment.creditCardList);
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	const handleToggle = (e) => {
		// console.log("Here inside handleToggle", e)
		setToggleActive(e)
	}

	useEffect(() => {

		const getData = async () => {
			await dispatch(creditCardList(ExistingNumberData.userData.token)).then(row => {
				setIsAlive(false)
			});
		}
		if (isAlive) {
			getData()
		}

		setToggleActive(paymentProfileList?.result?.length > 0 ? "select-card" : "add-new-card")
		setExistingNumberData({ ...ExistingNumberData, error: [], "ready": false })

	}, [paymentProfileList, setToggleActive])


	return (
		<>

			<div className="step">
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				{/* Existing Cards */}
				{

					isAlive ?
						<div className="m-5 p-5">
							<MiniLoader />
						</div>
						:
						toggleActive == "select-card" ?
							<ExistingPaymentProfile setStep={setStep} activeStep={activeStep} toggleStep={handleToggle} />
							:
							<NewPaymentProfile setStep={setStep} activeStep={activeStep} toggleStep={handleToggle} />
				}
			</div>
		</>
	)
}
export default PaymentDetails
