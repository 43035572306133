
import { GET_RECURING_PAYMENT } from "../actions/PaymentAction"
import { GET_PASTDUES_PAYMENT } from "../actions/PaymentAction"
import { GET_PAYMENT_HISTORY } from "../actions/PaymentAction"
import { GET_CREDIT_CARD_LIST } from "../actions/PaymentAction"


const initialState = {
	recuringPayment: [],
	pastDues: [],
	paymentHistory: [],
	creditCardList: {},
	// creditCardList:[],
	cusId: []
}


const PaymentReducer = function (state = initialState, action) {

	switch (action.type) {
		case GET_RECURING_PAYMENT: {
			return {
				...state,
				recuringPayment: [...action.payload]
			}
		}
		case GET_PASTDUES_PAYMENT: {
			return {
				...state,
				pastDues: [...action.payload]
			}
		}
		case GET_PAYMENT_HISTORY: {
			return {
				...state,
				paymentHistory: [...action.payload]
			}
		}
		case GET_CREDIT_CARD_LIST: {
			return {
				...state,
				creditCardList: { ...action.payload }
			}
		}
		default: {
			return { ...state }
		}
	}
}

export default PaymentReducer
