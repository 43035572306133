import React, { useState } from "react";
import { useContext } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import FormFooter from "./FormFooter";
import { unformatedPhone } from "general/FormatNumber";
import { parsePhoneNumber } from "libphonenumber-js";

const AddingNumbers = ({ setStep, activeStep, heading, placeholder, prevStep }) => {

	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const disabled = false;
	const containerClass = "PhoneInput";
	const currentCountryCode = "us";

	const countryData = {
		countryCode: "us",
		dialCode: "1",
		format: "+. (...) ...-....",
		name: "United States",
	}

	const [moreNumbers, setMoreNumbers] = useState(ExistingNumberData?.how_many_number_you_have_on_the_account);
	var moreNumbers_validate = true;
	const handleOnChange = (e, key, subIndex) => {
		let tempArray = [...moreNumbers];
		tempArray[key][subIndex] = e;
		setMoreNumbers(tempArray);

		// Validating via libphonenumber-js first
		if (tempArray[key]['countryCode'] != "" && e.length > 1) {

			const phoneNumber = parsePhoneNumber(e, tempArray[key]['countryCode']?.toUpperCase())
			// console.log(tempArray[key]['countryCode'])
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, 'error': ["invalid_phone"], 'ready': false })
				return false
			} else {

				// setExistingNumberData({ ...ExistingNumberData, 'error': [], 'ready': false })
			}
		}


		//Same number validation by Yasir
		var valueArr = moreNumbers.map(function (item) { return item.number });
		var isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) != idx
		});

		if (isDuplicate) {
			setExistingNumberData({
				...ExistingNumberData, error: ["same_number"], processing: false, ready: false
			})
		}

		moreNumbers.map((data, index) => {
			if (unformatedPhone(ExistingNumberData?.first_number.phoneNumber) == unformatedPhone(data?.number) || isDuplicate) {
				setExistingNumberData({
					...ExistingNumberData, error: ["same_number"], processing: false, ready: false
				})
				moreNumbers_validate = false;
			}
			else if (unformatedPhone(data?.number).length != 11) {
				moreNumbers_validate = false;
			}
			else {
				setExistingNumberData({
					...ExistingNumberData, error: ["error"], processing: false, ready: false
				})
			}
		})
		if (moreNumbers_validate) {
			setExistingNumberData({
				...ExistingNumberData, error: [], processing: false, ready: true
			})
		}
	}

	const setCountryHandler = (e, key, subIndex) => {
		let tempArray = [...moreNumbers];
		tempArray[key][subIndex] = e.countryCode;
		setMoreNumbers(tempArray);
	}

	const removeRow = (e) => {
		let tempArray = [...moreNumbers];
		tempArray.splice(e, 1);
		setMoreNumbers(tempArray);
		// Revalidate numbers after removal
		let newErrors = [];
		let valid = true;

		tempArray.forEach((data, i) => {
			const unformattedNumber = unformatedPhone(data?.number);
			if (unformattedNumber.length !== 11) {
				valid = false;
				newErrors.push("Invalid Length");
			}

			const isDuplicate = tempArray.some((item, idx) => {
				return idx !== i && unformatedPhone(item.number) === unformattedNumber;
			});

			if (isDuplicate) {
				valid = false;
				newErrors.push("same_number");
			}
		});
		setExistingNumberData({
			...ExistingNumberData,
			error: valid ? [] : newErrors,
			processing: false,
			ready: valid,
		});
	}

	const addRow = () => {
		setMoreNumbers([...moreNumbers, {
			number: "",
			type: ExistingNumberData?.is_this_a_mobile_number === "YES" ? "mobile" : ExistingNumberData?.use_number_only_to_send_receive_faxes === "YES" ? "fax" : "landline",
			countryCode: ""
		}])
		setExistingNumberData({
			...ExistingNumberData, error: ["error"], processing: false, ready: false, how_many_number_you_have_on_the_account: moreNumbers
		})
	}

	useEffect(() => {

		// setExistingNumberData({ ...ExistingNumberData, 'how_many_number_you_have_on_the_account': moreNumbers, 'error': [], 'ready': false });
		moreNumbers?.forEach(element => {

			if (element.number === "") {
				setExistingNumberData({ ...ExistingNumberData, 'error': [], 'ready': false });
			} else {
				if (element.number.length >= 11) {
					setExistingNumberData({ ...ExistingNumberData, 'error': [], 'ready': true });
				} else {
					setExistingNumberData({ ...ExistingNumberData, 'error': ["Invalid Length"], 'ready': false });
				}
			}
		});

		if (ExistingNumberData.are_there_any_more_number_that_you_want_to_port === "NO") {
			if (prevStep === 10) {
				setStep(activeStep + 1)
			} else if (prevStep === 12) {
				setStep(activeStep - 1)
			}
		}

		setExistingNumberData({ ...ExistingNumberData, 'how_many_number_you_have_on_the_account': moreNumbers })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [moreNumbers])

	return (
		<>
			<div className="step more-numbers">
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<h2 className="lg-heading">{heading}</h2>
				<div className="add_new_number_div">
					{
						moreNumbers?.map((number, index) => {
							return (
								<div className="row position-relative">
									<div className="col">
										<PhoneInput
											inputClass="default"
											disabled={disabled}
											containerClass={containerClass}
											country={'us'}
											onlyCountries={['us', 'ca']}
											countryCode={currentCountryCode}
											countryCodeEditable={false}
											onChange={(phone, details) => {
												setCountryHandler(details, index, "countryCode")
												handleOnChange(phone, index, "number");
											}}
											countryData={countryData}
											placeholder={placeholder}
											value={moreNumbers[index].number}
										/>
									</div>
									<div className="col d-flex flex-column mb-2">
										{/* <label className="form-label select-label mb-0">Number Is A:</label> */}
										<div className="number_type">
											<div className="three_main_phone_options">

												<div className="cat action" onClick={(e) => {
													ExistingNumberData?.is_this_a_mobile_number !== "YES" &&
														handleOnChange('landline', index, "type")
												}}>
													<label htmlFor="carrier_option_verizon_wireless" className="cat action">
														<input
															name={`type[${index}]`}
															type="radio"
															className={(ExistingNumberData?.is_this_a_mobile_number === "YES" ? "disabled" : "") + " social_check"}
															value="landline"
															id="carrier_option_landline"
															checked={moreNumbers[index].type === 'landline'}
															onChange={() => handleOnChange('landline', index, "type")}
														/>
														<span><img width="22" height="22" src="/images/forms/call.png" className="number_options" alt="" />Landline</span>
													</label>
												</div>

												<div className="cat action" onClick={(e) => {
													ExistingNumberData?.is_this_a_mobile_number === "YES" &&
														handleOnChange('mobile', index, "type")
												}}>
													<label htmlFor="carrier_option_at_and_t_wireless">
														<input
															name={`type[${index}]`}
															type="radio"
															className={(ExistingNumberData?.is_this_a_mobile_number !== "YES" ? "disabled" : "") + " social_check"}
															value="mobile"
															onClick={(e) => {
																handleOnChange('mobile', index, "type")
															}}
															checked={moreNumbers[index].type === 'mobile'}
															onChange={() => handleOnChange('landline', index, "mobile")}
															id="carrier_option_mobile"
														/>
														<span><img width="22" height="22" src="/images/forms/conversation.png" className="number_options" alt="" />Mobile</span>
													</label>
												</div>
												<div className="cat action" onClick={(e) => {
													ExistingNumberData?.use_number_only_to_send_receive_faxes === "YES" &&
														handleOnChange('fax', index, "type")
												}}>
													<label htmlFor="carrier_option_t_mobile">
														<input
															name={`type[${index}]`}
															type="radio"
															className={(ExistingNumberData?.use_number_only_to_send_receive_faxes !== "YES" ? "disabled" : "") + " social_check"}
															value="fax"
															onClick={(e) => {
																handleOnChange('fax', index, "type")
															}}
															id="carrier_option_fax"
															checked={moreNumbers[index].type === 'fax'}
															onChange={() => handleOnChange('landline', index, "fax")}
														/>
														<span><img width="22" height="22" src="/images/forms/fax.png" className="number_options" alt="" />Fax</span>
													</label>
												</div>

											</div>
										</div>

									</div>
									{
										index !== 0 && (
											<div onClick={() => removeRow(index)} className="numberRemove"><i className="fa fa-trash"></i></div>
										)
									}
								</div>
							)
						})
					}
				</div>
				<button
					type="button"
					className="add-number-btn"
					onClick={() => addRow()}
				>
					+ Add Number
				</button>
				<FormFooter action={'add-more-did'} activeStep={activeStep} setStep={setStep} addNumber={true} />
			</div>

		</>
	)
}

export default AddingNumbers
