import React from 'react'
const profileRoutes = [
    {
        path: '/customer/profile',
        component: React.lazy(() => import('./Profile')),
        auth: ['admin','User'],
    }
]

export default profileRoutes
