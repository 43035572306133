import { ExistingNumberContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";
import React, { useContext } from "react";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { updateUser } from "redux/actions/UserAction";


const SuccessGuest = () => {
	const history = useHistory()
	const [counter, setCounter] = useState(10);
	useEffect(() => {
		const interval = setInterval(() => {
			setCounter((counter) => counter - 1);
		}, 1000);
		const dashboardTimeout = setTimeout(() => {
			history.push('/customer/dashboard')
		}, 10000)
		return () => {
			clearTimeout(dashboardTimeout)
			clearInterval(interval)
		}
	}, []);
	return (
		<div className="step">
			<div className="completed-wrapper">
				<h1 className="success-heading">Success!</h1>
				<img src="/images/forms/order-delivery.png" alt="" className="check-img" />
				<h2 className="text">
					Your order has been submitted. You can always view details of your order from your customer dashboard and you will be notified once the status of your order changes. Click the button below to go to your dashboard.
					{/* <div className="loader-success"></div> */}
				</h2>
				<div className="dashboard-counter">
					{
						(counter > 0) ? counter : '0'
					}
				</div>
				<Link to={"/customer/dashboard"} type="button" className="order-summary-dashboard-btn btn-lg my-3 mx-auto">Go TO DASHBOARD</Link>
			</div>
		</div>
	)
}
const SuccessAuth = () => {

	const { ExistingNumberData } = useContext(ExistingNumberContext);
	const { signupViaCoverage } = useAuth();
	const dispatch = useDispatch();
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const [form, setForm] = useState({
		'id': ExistingNumberData.user_id,
		'first_name': ExistingNumberData.firstname,
		'last_name': ExistingNumberData.lastname,
		'email': ExistingNumberData.email,
		'password': "",
		'user_keys': {
			'is_customer': 1,
			'is_internal_user': 0
		}
	});
	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	const submitForm = async () => {
		await dispatch(updateUser(form, ExistingNumberData.userData.token)).then((resp) => {
			if (resp.bool) {

				signupViaCoverage(ExistingNumberData.userData)

			} else {
				toast.error(resp.message);
			}
		})
	}

	return (
		<div className="step">
			<div className="completed-wrapper">
				<h1 className="success-heading">Success!</h1>
				<img src="/images/forms/order-delivery.png" alt="" className="check-img" />
				<h2 className="text">
					Your order has been saved. Please create an account or sign in to track your order

					{/* <div className="loader-success"></div> */}
				</h2>
				{/* <button type="button" className="order-summary-dashboard-btn btn-lg my-3 mx-auto">Download Invoice</button> */}
				<div className="row mt-3">
					<div className="col-lg-6 col-12 signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/user.png" alt="" />
							<label for="company_name">First Name </label>
							<input name="first_name" value={form.first_name} onChange={handleChange} type="text" placeholder="John" />
						</div>
					</div>
					<div className="col-lg-6 col-12 signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/user.png" alt="" />
							<label for="company_name">Last Name</label>
							<input name="last_name" value={form.last_name} onChange={handleChange} type="text" placeholder="Doe" />
						</div>
					</div>
				</div>
				<div className="row mt-3">
					{/* <div className="col-lg-6 col-12 signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/user.png" alt="" />
							<label for="company_name">Username </label>
							<input name="username" id="company_name" type="text" placeholder="Johndoe" defaultValue="" />
						</div>
					</div> */}
					<div className="col-lg-6 col-12 signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/user.png" alt="" />
							<label for="company_name">Email Address</label>
							<input name="email" id="company_name" type="email" value={form.email} placeholder="user@example.com" onChange={handleChange} />
						</div>
					</div>
					<div className="col-lg-6 col-12 signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/padlock.png" alt="" />
							<label for="company_name">Password </label>
							<div className="pwd-container">
								<input
									name="password"
									placeholder="Enter Password"
									type={isRevealPwd ? "text" : "password"}
									value={form.password}
									onChange={e => { handleChange(e) }}

								/>
								<img
									alt=""
									title={isRevealPwd ? "Hide password" : "Show password"}
									// src={isRevealPwd ? hidePwdImg : showPwdImg}
									src={isRevealPwd ? "/images/forms/hide-password.svg" : "/images/forms/show-password.svg"}
									onClick={() => setIsRevealPwd(prevState => !prevState)}
								/>
							</div>

						</div>
					</div>
				</div>
				{/* <div className="row mt-3">

				</div> */}
				<button type="button" onClick={submitForm} className="order-summary-dashboard-btn btn-lg my-3 mx-auto">GO TO DASHBOARD</button>

			</div>
		</div>
	)
}

const Success = () => {

	const { isAuthenticated } = useAuth();

	return (
		isAuthenticated ?
			<>
				<SuccessGuest />
			</>
			:
			<>
				<SuccessAuth />
			</>
	)
}

export default Success
