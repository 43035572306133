import React from 'react'

import "../assets/loader.css"

const Loading = () => {
  return (
    <div className="loader">
      <img className="loading-logo" src="/images/forms/wizard-logo.png" alt="" />
      <div className="loader-main">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loading
