
import { CoverageContext } from "contexts/FormsContext";
import { useContext } from "react";
import { useState } from "react";
import FormFooter from "./FormFooter";


const Goals = ({ setStep, activeStep }) => {

	const { coverage, setCoverage } = useContext(CoverageContext)

	const [form, setForm] = useState(coverage.goals)
	const handleClick = (e) => {
		const key = e.target.id.split("-")[1]
		const tempArray = [...form];
		tempArray[key].answer = !tempArray[key].answer
		console.log(tempArray[key].answer)
		setForm(tempArray)
		setCoverage({ ...coverage, goals: tempArray })
	}
	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Tell us what you are looking to acheive: <span className="text-secondary">(Optional)</span></h2>
			{
				form?.map((values, keys) =>
					<div className="row mb-5" key={keys}>
						<div className="col">
							<div className="item">
								<input className="custom-cb" id={`p-${keys}`} type="checkbox" checked={values.answer} onChange={handleClick} />
								<label htmlFor={`p-${keys}`}>{values.title}</label>
							</div>
						</div>
					</div>
				)
			}

			<FormFooter action={'update'} activeStep={activeStep} setStep={setStep} final={true} />
		</div>
	)
}

export default Goals
