/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import FormFooter from "../widgets/FormFooter";
import { SignupContext } from "contexts/FormsContext";

const PersonalData = ({ setStep, activeStep, prevStep }) => {
  let [SignupData] = useContext(SignupContext)
  if (SignupData.activeSocialFormat != "email") {
    console.log(prevStep)
    if (prevStep == 0) {
      setStep(activeStep + 1)
    } else if (prevStep == undefined || prevStep > 2) {
      setStep(activeStep - 1)
    }
  }
  console.log(SignupData)
  return (
    <div className="step">
      <h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
      <h2 className="lg-heading">You're almost there! Let's start by telling us your name and email.</h2>
      <div className="row signupbutton_content mb-3">
        <div className="col">
          <div class="email field">
            <img class="icon user" src="/images/forms/user.png" alt="" />
            <label for="first_name">First Name</label>
            <input name="first_name" id="first_name" type="text" placeholder="John" defaultValue={SignupData.firstname} onBlur={(e) => SignupData.firstname = e.target.value} />
            {/* <label class="existing-error" for="first_name">This email address is already registered.</label> */}
          </div>
        </div>
        <div className="col">
          <div class="email field">
            <img class="icon user" src="/images/forms/user.png" alt="" />
            <label for="last_name">Last name</label>
            <input name="last_name" id="last_name" type="text" placeholder="Doe" defaultValue={SignupData.lastname} onBlur={(e) => SignupData.lastname = e.target.value} />
            {/* <label class="existing-error" for="last_name">This email address is already registered.</label> */}
          </div>
        </div>
      </div>
      <div className="row signupbutton_content">
        <div className="col">
          <div class="email field">
            <img class="icon" src="/images/forms/icon-email.png" alt="" />
            <label for="email">Email</label>
            <input name="email" id="email" type="email" placeholder="johndoe@gmail.com" defaultValue={SignupData.email} onBlur={(e) => SignupData.email = e.target.value} />
            {/* <label class="existing-error" for="email">This email address is already registered.</label> */}
          </div>
        </div>
      </div>

      <FormFooter activeStep={activeStep} setStep={setStep} />
    </div>
  )
}

export default PersonalData
