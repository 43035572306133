import React from 'react';

const makeChangesRoutes = [
	{
		path: '/customer/make-changes',
		component: React.lazy(() => import('./Index')),
		exact: true,
		auth: ['admin', 'User'],
	}
];

export default makeChangesRoutes
