
import { combineReducers } from 'redux'
import CarriersReducer from './CarrierReducer'
import OrderReducer from './OrderReducer'
import ServicesReducer from './ServicesReducer'
import SettingReducer from './SettingReducer'
import StreetMapReducer from './StreetMapReducer'
import UserReducer from './UserReducer'
import PaymentReducer from './PaymentReducer'
import ActivityLogReducer from './LogReducer'
import MakeChangesReducer from './MakeChangesReducer'
import TooltipReducer from './TooltipReducer'
import NotificationReducer from './NotificationReducer'

const RootReducer = combineReducers({
	settings: SettingReducer,
	users: UserReducer,
	carrier: CarriersReducer,
	streetMap: StreetMapReducer,
	orders: OrderReducer,
	payment: PaymentReducer,
	services: ServicesReducer,
	logs: ActivityLogReducer,
	makeChanges: MakeChangesReducer,
	tooltips: TooltipReducer,
	notfications: NotificationReducer
})

export default RootReducer
