export function capitalize(str) {
	const lower = str.toLowerCase();
	return str.charAt(0).toUpperCase() + lower.slice(1);
}


export const generatePassword = (length) => {
	const defaultCharacters = 'abcdefghijklmnopqrstuvwxyz';
	const characters = {
		lowercase: defaultCharacters,
		uppercase: defaultCharacters.toUpperCase(),
		numbers: '0123456789',
		symbols: '~!@-#$'
	};
	const pool = characters.lowercase + characters.numbers + characters.uppercase + characters.symbols;
	let password = '';
	let hasUppercase = false;
	let hasLowercase = false;
	let hasNumber = false;
	while (!hasUppercase || !hasLowercase || !hasNumber) {
		hasUppercase = false;
		hasLowercase = false;
		hasNumber = false;
		password = '';
		for (let i = 0; i < length; i++) {
			const index = Math.floor(Math.random() * pool.length);
			const char = pool[index];
			if (characters.uppercase.includes(char)) {
				hasUppercase = true;
			} else if (characters.lowercase.includes(char)) {
				hasLowercase = true;
			} else if (characters.numbers.includes(char)) {
				hasNumber = true;
			}
			password += char;
		}
	}
	return password;
};
