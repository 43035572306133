import { SignupContext } from "contexts/FormsContext";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

const Goals = () => {

	const history = useHistory()
	const { signupData, setSignupData } = useContext(SignupContext)
	const [form, setForm] = useState(signupData.goals)
	const { handleSubmit } = useForm();
	const submitHandler = (e) => {
		setSignupData({ ...signupData, 'goals': form })
		history.push('/signup/success')
	}
	const handleClick = (e) => {
		const key = e.target.id
		const tempArray = [...form];
		tempArray[key].answer = !tempArray[key].answer
		setForm(tempArray)

	}
	return (
		<>
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Tell us what you are looking to acheive: <span className="text-secondary">(Optional)</span></h2>
					{
						signupData?.goals.map((row, index) => {
							return (
								<div className="row mb-5" key={index}>
									<div className="col">
										<div className="item">
											<input className="custom-cb" id={index} type="checkbox" checked={row.answer} onChange={handleClick} />
											<label htmlFor={index}>{row.title}</label>
										</div>
									</div>
								</div>
							)
						})
					}
					{/* <div className="row mb-5">
						<div className="col">
							<div className="item">
								<input className="custom-cb" {...register('goals')} id="preference_two" type="checkbox" value="value1" />
								<label for="preference_two">I am moving to an area where my current provider cannot provide service.</label>
							</div>
						</div>
					</div>
					<div className="row mb-4">
						<div className="col">
							<div className="item">
								<input className="custom-cb" {...register('goals')} id="preference_three" type="checkbox" value="value1" />
								<label for="preference_three">My elderly parents are moving to a home and want to keep their phone number.</label>
							</div>
						</div>
					</div> */}
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<input type="submit" className="proceed-btn mb-2" value="Proceed" />
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => history.goBack()} className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default Goals
