import RoundedButton from 'components/Buttons/RoundedButton'
import { ExistingNumberContext } from 'contexts/FormsContext'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ValidationError from '../ValidationError'
import Select from 'react-select'
import { getDivisionsByCountry } from 'redux/actions/StreetMapAction'
import PlacesAutocomplete, {
	geocodeByAddress,
	geocodeByPlaceId,
	getLatLng,
} from 'react-places-autocomplete';
import { orderUpdate, updateOrderStepTracking } from 'redux/actions/OrderAction'
import { toast } from 'react-toastify'
const ServiceAddress = ({ setStep, activeStep }) => {
	const dispatch = useDispatch()
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const divisionList = useSelector(state => state.streetMap?.divisions?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.short_code.toLowerCase() } }));
	const [loading, setLoading] = useState(false)
	const [isAlive, setIsAlive] = useState(true)
	const [googlePlaces, setGooglePlaces] = useState(ExistingNumberData?.service_address_where_the_phone_physically_installed?.street_address)


	// custom css on input focus
	const [isInputFocused, setIsInputFocused] = useState(false);

	const handleInputFocus = () => {
		setIsInputFocused(true);
	};

	const handleInputBlur = () => {
		setIsInputFocused(false);
	};

	const customStyles = {
		option: (provided, state) => ({
			...provided,
			// borderBottom: '1px dotted pink',
			// color: state.isSelected ? 'red' : 'blue',
			// padding: 20,
		}),
		control: (provided) => ({
			...provided,
			// none of react-select's styles are passed to <Control />
			height: 56,
			width: "100%",
			background: "#fff",
			paddingLeft: 50,
			display: "flex",
			alignItems: "center",
			border: "1px solid #dbdbd9",
		}),
		singleValue: (provided, state) => {
			const opacity = state.isDisabled ? 0.5 : 1;
			const transition = 'opacity 300ms';
			return { ...provided, opacity, transition };
		}
	}

	const { register, handleSubmit, formState: { errors, isValid }, setValue, watch, clearErrors, setError } = useForm({
		defaultValues: {
			'street_address': ExistingNumberData?.service_address_where_the_phone_physically_installed?.street_address || '',
			'city': ExistingNumberData?.service_address_where_the_phone_physically_installed?.city || '',
			'country': { label: ExistingNumberData?.first_number.name, value: countryList.filter(row => row.code === ExistingNumberData?.first_number.countryCode)[0]?.value } || { label: 'United States', value: 230 },
			'zip_code': ExistingNumberData?.service_address_where_the_phone_physically_installed?.zip_code || '',
			'state': ExistingNumberData?.service_address_where_the_phone_physically_installed?.state || '',
		}
	})

	const handleChange = address => {
		setGooglePlaces(address)
		setValue('street_address', address)
	};
	const handleSelect = async (address) => {
		clearErrors()
		// setting stat
		setGooglePlaces(address)
		setValue('street_address', address)
		const results = await geocodeByAddress(address);
		const latLng = await getLatLng(results[0]);
		const place = await geocodeByPlaceId(results[0].place_id);
		const { long_name: postalCode = '' } = place[0].address_components.find(c => c.types.includes('postal_code')) || {};
		const { short_name: stateName = '' } = place[0].address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
		const { long_name: cityName = '' } = place[0].address_components.find(c => c.types.includes("locality")) || place[0].address_components.find(c => c.types.includes("political")) || {};

		const stateIdFromList = divisionList.filter(state => state.code.toLowerCase() === stateName.toLowerCase())[0];

		setValue('street_address', address)
		setValue('city', cityName)
		setValue('zip_code', postalCode)
		setValue('state', stateIdFromList)
	};
	const submitHandler = async (e) => {
		if (e.state == "") {
			setError("state", { message: "State is required" })
		}
		if (e.street_address == "") {
			setError("street_address", { message: "Street Address is is required" })
		}
		setLoading(true)
		setExistingNumberData({ ...ExistingNumberData, 'service_address_where_the_phone_physically_installed': e });

		const payload = {
			"order_id": ExistingNumberData.order_id,
			"service_address": e.street_address,
			"service_city": e.city,
			"service_state": e.state?.code.toUpperCase(),
			"service_zip": e.zip_code,
			"service_country_id": e.country.value ?? ExistingNumberData.first_number.country,
		}
		await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			showToast()
			setStep(activeStep + 1)
		}).catch(exp => {
			console.log(exp)
			setLoading(false)
		})
	}

	const showToast = () => {
		toast.success("Your Data has been saved", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" })
	}


	// if (
	// 	!errors.street_address ||
	// 	!errors.city ||
	// 	!errors.country ||
	// 	!errors.zip_code ||
	// 	!errors.state) {
	// 	setExistingNumberData({...ExistingNumberData, 'ready': true })
	// 	console.log("here wtf")
	// }

	const elementWithGenericClass = document.querySelector('.generic-country-selects');

	// Step 2: Add the class "state-province-select" to the first div inside the element
	if (elementWithGenericClass) {
		const firstDivInsideGenericClass = elementWithGenericClass.querySelector('div:first-child');


		if (firstDivInsideGenericClass) {
			firstDivInsideGenericClass.classList.add('state-province-select');
		}

		if (firstDivInsideGenericClass) {
			const siblingDiv = firstDivInsideGenericClass.nextElementSibling;
			if (siblingDiv && siblingDiv.tagName.toLowerCase() === 'div') {
				siblingDiv.classList.add('state-province-select-span');
			}
		}
	}

	useEffect(() => {
		const fetchCountryData = async () => {
			setValue('country', { label: ExistingNumberData.first_number.name, value: countryList.filter(row => row.code === ExistingNumberData.first_number.countryCode)[0]?.value })
			dispatch(getDivisionsByCountry(watch('country.value')))
		}
		if (isAlive) {
			fetchCountryData()
			setIsAlive(false)
		}
		// if(){

		// }
		if (watch("city")?.length !== 0 && watch('country')?.length !== 0 && watch('state')?.length !== 0 && watch('street_address')?.length !== 0) {
			setExistingNumberData({ ...ExistingNumberData, 'ready': true })
		}

	}, [watch("city"), watch('country'), watch('state'), watch('street_address')])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Please enter the service address where {ExistingNumberData.first_number?.phoneNumber} is physically installed or where you reside.</h2>
					<div className="row signupbutton_content mb-4">
						<div className="col-12">
							<div className="email field">
								<img className="icon user" src="/images/forms/home-address.png" alt="" />
								<label htmlFor="street_address" className="w-100">Service Street Address <span className="float-end blue">(Your Physical Address)</span></label>
								<PlacesAutocomplete
									searchOptions={{
										componentRestrictions: {
											country: [ExistingNumberData.first_number.countryCode],
										}
									}}
									value={googlePlaces}
									onChange={(e) => { handleChange(e) }}
									onSelect={(e) => { handleSelect(e) }}

								>
									{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
										<>
											<input
												{...getInputProps({
													placeholder: 'Search Places ...',
													className: 'location-search-input'
												})}
											/>
											<div className='wrapper-location w-100 position-absolute' style={{ zIndex: 999 }}>
												<div className="autocomplete-dropdown-container">
													{loading && <div className='px-2 py-1 shadow border-bottom suggestion-item'>Loading...</div>}
													{suggestions.map(suggestion => {
														const className = suggestion.active
															? 'px-2 py-1 shadow border-bottom suggestion-item--active'
															: 'px-2 py-1 shadow border-bottom suggestion-item';
														// inline style for demonstration purpose
														const style = suggestion.active
															? { backgroundColor: '#fafafa', cursor: 'pointer' }
															: { backgroundColor: '#ffffff', cursor: 'pointer' };
														return (
															<div
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
															>
																<span>{suggestion.description}</span>
															</div>
														);
													})}
												</div>
											</div>
										</>
									)}
								</PlacesAutocomplete>
							</div>
						</div>
					</div>
					<div className="row signupbutton_content mb-4">
						<div className="col-12 col-md-6">
							<div className="email field">
								<img className="icon user" src="/images/forms/condo.png" alt="" />
								<label htmlFor="city">City</label>
								<input {...register('city', { required: "City is required" })} />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="email field">
								<img className="icon user" src="/images/forms/codepost.png" alt="" />
								<label htmlFor="zip">Zip/postal code</label>
								<input {...register('zip_code', {
									required: "Zip code is required"
								})} />
							</div>
						</div>
					</div>
					<div className="row signupbutton_content">
						<div className="col-12 col-md-6">
							<div className="email field">
								<img className="icon user country" src="/images/forms/country.png" alt="" />
								<label htmlFor="cvv">Country</label>
								<input type="text" value={watch('country.label')} readOnly />
							</div>
						</div>
						<div className="col-12 col-md-6">
							<div className="email state field " style={isInputFocused ? { zIndex: 999 } : {}}>
								<img className="icon user country" src="/images/forms/united-states-of-america.png" alt="" />
								<label htmlFor="cvv">State/Province</label>
								<Select
									options={divisionList}
									name="state"
									onChange={(e) => setValue('state', e)}
									className="generic-country-selects "
									styles={customStyles}
									// defaultValue={}
									value={watch('state')}
									menuPlacement="top"
									onFocus={handleInputFocus}
									onBlur={handleInputBlur}
								/>
							</div>
						</div>
					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} showLoader={false}>
									Proceed
								</RoundedButton>
								<div className="pe">
									Press Enter
									<img src="/images/forms/icon-enter.png" alt="" />
								</div>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default ServiceAddress

