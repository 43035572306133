import dashboardRoutes from 'pages/Dashboard/dashboardRoutes'
import formRoutes from 'pages/forms/formRoutes'
import orderHistoryRoutes from 'pages/orderHistory/orderHistoryRoutes'
import profileRoutes from 'pages/profile/profileRoutes'
import paymentsRoutes from 'pages/payments/paymentsRoutes'
import servicesRoutes from 'pages/services/servicesRoutes'
import { Redirect } from 'react-router-dom'
import makeChangesRoutes from 'pages/make-changes/makeChangesRoutes'



const redirectRoute = [
	{
		path: '/',
		exact: true,
		component: () => <Redirect to="/customer/dashboard" />,
	},
]

const errorRoute = [
	{
		component: () => <Redirect to="/404" />,
	},
]

const routes = [
	// ...pageLayoutRoutes,
	// ...pagesRoutes,
	...dashboardRoutes,
	...servicesRoutes,
	...orderHistoryRoutes,
	...profileRoutes,
	...makeChangesRoutes,
	...paymentsRoutes,
	...redirectRoute,
	...errorRoute,
	...formRoutes,
]

export default routes
