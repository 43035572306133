import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createCarriers } from "redux/actions/CarrierAction";

const AddCarrier = ({ tipBody, tipQuestion, newlyAddedCarrier }) => {
	const dispatch = useDispatch();
	let [tipEnable, setTipEnable] = useState(false)
	const [carrierForm, setCarrierForm] = useState({})

	const handleSubmit = async (e) => {
		e.preventDefault();
		await dispatch(createCarriers(carrierForm)).then(response => {
			if (response.result.bool) {
				newlyAddedCarrier(response.result.result)
				setTipEnable(false);

			}
		});
	}

	function handleChange(e) {
		setCarrierForm({
			...carrierForm, [e.target.name]: e.target.value
		})
	}

	return (
		<>
			<button onClick={() => setTipEnable(true)} type="button" className="add-number-btn my-3">+ Add Other Carrier</button>
			{
				tipEnable &&
				<div className="tipWrapper addCarrier">
					<div className="tipModule">
						<div className="tipClose" onClick={() => { setTipEnable(false) }}>x</div>
						{/* {!submit ? */}
						<form >
							<h3>Add Carrier</h3>
							<input id="carrier-name" name="name" onChange={(e) => handleChange(e)} className="form-control" placeholder="Carrier Name" />
							<input id="carrier-link" name="carrier_link" onChange={(e) => handleChange(e)} className="form-control mt-3" placeholder="Carrier Link (optional)" />
							<button type="submit" onClick={handleSubmit} className="add-carrier-request">Send Request</button>
						</form>
						{/* : */}
						{/* <></>
							// <h4 className="green mb-0">
							// 	Carrier will be added after approval
							// </h4>
						} */}
					</div>
				</div>
			}
		</>
	)
}
export default AddCarrier
