import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import Avatar from 'react-nice-avatar'

const TopHeader = ({ sidebar, toggleSidebar }) => {

  const [dropdown, setDropdown] = useState(false)
  const [logoutLoader, setLogoutLoader] = useState(false);
  const userKeys = useSelector(state => state.users.userKeys)

  const { logout, user } = useAuth();

  // Get first letter of first name and last name
  const fullName = user?.name?.split(' ');
  const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  const firstLetters = initials.toUpperCase();

  useEffect(() => {
    // return () => setLogoutLoader(false)
  })

  return (
    <header className="header header-sticky mb-3 mb-md-4">
      <div className="container-fluid justify-content-between justify-content-md-start">
        {/* <button className="header-toggler px-md-0 me-md-3 order-2 order-sm-2 order-md-1" type="button" onClick="coreui.Sidebar.getInstance(document.querySelector('#sidebar')).toggle()"> */}
        <button className="header-toggler px-md-0 me-md-3 order-2 order-sm-2 order-md-1" type="button" onClick={toggleSidebar}  >
          <img src="/admin/images/icons/icon-menu.png" alt="" />
        </button>

        <div className="cp-heading d-none d-md-block order-md-2">Customer Portal</div>

        <Link className="header-brand d-md-none me-0 order-1 order-sm-1 order-md-3" to="#">
          <img src="/admin/images/logo-lg.png" alt="PMP Logo" />
        </Link>

        <ul className="header-nav align-items-center custom-dd ms-0 ms-md-auto order-3">
          <li className="nav-item dropdown">
            <Link className="nav-link py-0 profile-dropdown" onClick={() => setDropdown(!dropdown)} to="#" role="button" aria-haspopup="true" aria-expanded="false">
              <div className="name-letters">
                {userKeys?.profile_image_type == "picture" &&
                  <img src={userKeys?.profile_image} alt="Profile" className='img-fluid rounded-circle h-100 w-auto' style={{ objectFit: "cover" }} />
                }
                {userKeys?.profile_image_type == "gravatar_api" &&
                  <img src={userKeys?.profile_image} alt="Profile" />
                }
                {
                  userKeys?.profile_image_type == "gravatar" &&
                  <Avatar style={{ width: '3.5rem', height: '3.5rem' }} {...userKeys?.profile_gravatar} />
                }
                {
                  !userKeys?.profile_image_type &&
                  firstLetters
                }
              </div>
              <div className="details">
                <div className="welcome-text">Welcome</div>
                <div className="name-text">{user?.name?.split(" ")[0]}</div>
              </div>
              <div className="avatar avatar-md">
                {/* <img className="avatar-img float-end" src="/assets/img/avatars/8.jpg" alt="user@email.com" /> */}
              </div>
            </Link>
            <div className={dropdown ? "dropdown-menu dropdown-menu-end pt-0 show" : "dropdown-menu dropdown-menu-end pt-0"}>

              <Link className="dropdown-item" onClick={() => setDropdown(!dropdown)} to="/customer/profile">
                <svg className="icon me-2">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user"></use>
                </svg> Profile
              </Link>
              <Link className="dropdown-item" onClick={() => setDropdown(!dropdown)} to="/customer/services">
                <svg className="icon me-2">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-user"></use>
                </svg> Services
              </Link>
              {/* <Link className="dropdown-item" onClick={() => setDropdown(!dropdown)} to="#">
                <svg className="icon me-2">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-settings"></use>
                </svg> Settings
              </Link> */}
              <div className="dropdown-divider d-lg-none d-block"></div>
              <Link className="dropdown-item logout-mobile d-lg-none d-block" to="#" onClick={logout}>
                <svg className="icon me-2">
                  <use xlinkHref="vendors/@coreui/icons/svg/free.svg#cil-account-logout"></use>
                </svg> Logout
              </Link>
            </div>
          </li>
          <button
            className="logout-btn d-none d-lg-inline-flex"
            disabled={logoutLoader}
            onClick={() => { setLogoutLoader(true); logout(); }}
          >
            {
              logoutLoader ?
                <span className="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span>
                :
                <>
                  <img className="icon-blue" src="/admin/images/icons/icon-logout.png" alt="" />
                  <img className="icon-white" src="/admin/images/icons/icon-logout-white.png" alt="" />
                </>
            }
            Logout
          </button>
        </ul>
      </div>
    </header>
  )
}

export default TopHeader
