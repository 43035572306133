import { toast } from "react-toastify"
import axios from "../../axios"

export const GET_RECURING_PAYMENT = 'GET_RECURING_PAYMENT'
export const GET_PASTDUES_PAYMENT = 'GET_PASTDUES_PAYMENT'
export const GET_PAYMENT_HISTORY = 'GET_PAYMENT_HISTORY'
export const GET_CREDIT_CARD_LIST = 'GET_CREDIT_CARD_LIST'

export const getRecurringPayments = () => (dispatch) => {
	axios.get('my-payments/recurring').then((res) => {
		dispatch({
			type: GET_RECURING_PAYMENT,
			payload: res.data.result,
		})
	})
}

export const getPastDuesPayments = () => (dispatch) => {
	axios.get('my-payments/past-due').then((res) => {
		dispatch({
			type: GET_PASTDUES_PAYMENT,
			payload: res.data.result,
		})
	})
}


export const paymentHistory = () => (dispatch) => {
	axios.get('my-payments/payment-history').then((res) => {
		dispatch({
			type: GET_PAYMENT_HISTORY,
			payload: res.data.result,
		})
	})
}


export const creditCardList = (token) => async (dispatch) => {
	return axios.get('user/get-customer-payment-profile', { headers: { 'Authorization': `Bearer ${token}` } }).then(({ data }) => {
		if (data.bool) {
			dispatch({
				type: GET_CREDIT_CARD_LIST,
				payload: data.result
			})
		}
	})
}

export const chargeCreditCard = (dispatch) => async () => {
	// return axios.post('user/transaction', dispatch).then((res) => {
	return axios.post('my-payments/charge-customer', dispatch).then((res) => {
		return res.data;
	})
}

