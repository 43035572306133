import useAuth from 'hooks/useAuth';
import React from 'react'
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom'

const Topbar = () => {
	const history = useHistory();
	const path = history.location.pathname;
	const { isAuthenticated, isInitialised } = useAuth();
	let [toggleNavbar, setToggleNavbar] = useState(false);
	useEffect(() => {
		// if (isAuthenticated) {
		// 	history.push('/')
		// }
	}, [isAuthenticated, history, isInitialised])


	return (
		<div className="container">
			<header className="d-flex flex-wrap justify-content-between justify-content-md-center py-3">
				<Link to="#" className="d-flex mx-sm-auto mb-lg-0 mb-md-3 align-items-center me-md-auto text-dark text-decoration-none">
					<img className="bi me-2" width="100%" height="auto" src="/images/pmp logo.png" alt='' />
				</Link>
				<a href="#" className="navbarToggler" onClick={() => setToggleNavbar(!toggleNavbar)}>
					<img src="/images/forms/hamburger.png" alt="" />
				</a>
				<ul className={toggleNavbar ? "nav nav-pills mobile-show" : "nav nav-pills"}>
					{
						isAuthenticated ?
							<li className="nav-item"><Link to="customer/dashboard" className="nav-link signup_nav me-md-2 mb-2 mb-md-0 d-flex align-items-center">
								<img className="me-2" src="/images/add-friend.png" alt='' />
								Dashboard</Link></li>
							:
							<>
								<li className="nav-item"><Link to="/signup" className="nav-link signup_nav me-md-2 mb-2 mb-md-0 d-flex align-items-center">
									<img className="me-2" src="/images/add-friend.png" alt='' />
									Sign up</Link></li>

								<li className="nav-item"><Link to="/login" className="nav-link signup_nav me-md-2 mb-2 mb-md-0 d-flex align-items-center">
									<img className="me-2" src="/images/add-friend.png" alt='' />
									Login</Link></li>

							</>
					}
					<li className="nav-item me-md-2 mb-2 mb-md-0"><a target='_blank' rel='noreferrer' href="https://myaccount.parkmyphone.com/index.php?page=contact_us" className="nav-link contactus_nav d-flex align-items-center">
						<img className="me-2" src="/images/phone-book.png" alt='' />
						Contact us</a></li>
					<li className="nav-item me-md-2 mb-2 mb-md-0"><Link to="/order" className="nav-link contactus_nav d-flex align-items-center">
						{/* <img className="me-2" src="/images/phone-book.png" alt='' /> */}
						Order</Link></li>
					<li className="nav-item"><Link to="/coverage" className="nav-link contactus_nav d-flex align-items-center">
						{/* <img className="me-2" src="/images/phone-book.png" alt='' /> */}
						Coverage</Link></li>
				</ul>
			</header>
		</div>
	)
}

export default Topbar
