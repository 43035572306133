import RoundedButton from "components/Buttons/RoundedButton";
import { ExistingNumberContext } from "contexts/FormsContext";
import { convertToBase64 } from "general/ImageHelper";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { orderPhoneBill, updateOrderStepTracking } from "redux/actions/OrderAction";
import Tip from "../../forms/sub-components/widgets/Tip";
import ValidationError from "../ValidationError";
import { useEffect } from "react";

const FileUpload = ({ setStep, activeStep, tipBody, tipQuestion }) => {

	const dispatch = useDispatch()
	const [files, setFiles] = useState([])
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [loading, setLoading] = useState(false)
	const { handleSubmit, register, formState: { errors }, setError, clearErrors, setValue, getValues } = useForm({
		'phoneBill': ExistingNumberData?.upload_copy_of_latest_phone_bill
	});
	const submitHandler = async (e) => {
		clearErrors();
		setLoading(true)
		const selectedImage = e.phoneBill[0]
		const base64 = await convertToBase64(selectedImage);
		const payload = {
			"order_id": ExistingNumberData.order_id,
			"phone_bill": base64
		}

		await dispatch(orderPhoneBill(payload, ExistingNumberData.userData.token)).then(async response => {
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			toast.success("Your Data has been saved", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
			setStep(activeStep + 1)
		}).catch(err => {
			setLoading(false)
			setExistingNumberData({ ...ExistingNumberData, 'ready': false })
			setError("phoneBill", { message: err?.message })
			toast.error("Something went wrong!", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
		})
		setLoading(false)
	}

	const handleDrop = (event) => {
		event.preventDefault();
		const { files } = event.dataTransfer;
		if (files.length > 0) {
			setValue('phoneBill', files)
			setFiles([...files]);
		}
	}
	console.log(getValues('phoneBill'))

	const handleDragOver = (event) => {
		event.preventDefault()
	}

	const handleDragStart = (event) => {
		event.dataTransfer.setData("text/plain", event.target.id)
	}

	return (
		<>
			<ValidationError errors={errors} />
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="step">
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Please Upload a copy of your latest phone bill:</h2>

					{/* <div className="d-flex justify-content-center align-content-center file-upload">
						<div>
							<div className="drag-image file-upload-div" onDrop={handleDrop} onDragOver={handleDragOver}>
								<div className="m-2" draggable="true" onDragStart={handleDragStart}>
									<h6>Drag & Drop File Here</h6>
									<span>OR</span>
									<img alt="" className="file-drag-icon" src="/images/forms/cloud-computing.png" width="80px" />
									<div className='file-upload-div'>
										<ul>
											{files.map((file, index) => (
												<li key={index}>{file.name}</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> */}
					<div className="drag-image file-upload-div" onDrop={handleDrop} onDragOver={handleDragOver}>
						<img alt="" className="file-drag-icon" src="/images/forms/cloud-computing.png" width="80px" draggable={true} onDragStart={handleDragStart} />
						<h6>Drag & Drop File Here</h6>
						<span>OR</span>
						{
							files.length ?
								<ul>
									{files.map((file, index) => (
										<li key={index}>{file.name}</li>
									))}
								</ul> :
								<input
									type="file"
									{...register(
										'phoneBill',
										{
											required: "Cannot be empty!",
											value: files[0],
											onChange: (e) => {
												setExistingNumberData({ ...ExistingNumberData, 'ready': false })
												if (e.target.value != "") {
													clearErrors()
													setExistingNumberData({ ...ExistingNumberData, 'ready': true, errors: [] })
												}
											}
										})}
								/>

						}

					</div>

					<Tip tipBody={tipBody} tipQuestion={tipQuestion} stepKey={'upload_copy_of_latest_phone_bill'} setStep={setStep} activeStep={activeStep} />
					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} showLoader={false}>
									Proceed
								</RoundedButton>
							</div>
						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</div>
			</form>
		</>
	)
}

export default FileUpload
