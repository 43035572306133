import { GET_CATEGORIES_PLAN_RATES, GET_MY_ORDERS, GET_ORDER_STATUS, GET_PLAN_CATEGORIES } from "redux/actions/OrderAction"

const initialState = {
	orderList: [],
	orderStatus: [],
	planCategoryList: [],
	planByCategory: [],
}

const OrderReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_MY_ORDERS:
			return {
				...state,
				orderList: [...action.payload]
			}
		case GET_ORDER_STATUS:
			return {
				...state,
				orderStatus: [...action.payload]
			}
		case GET_CATEGORIES_PLAN_RATES:
			return {
				...state,
				planCategoryList: [...action.payload]
			}
		case GET_PLAN_CATEGORIES:
			return {
				...state,
				planByCategory: [...action.payload]
			}
		default:
			return { ...state }

	}
}

export default OrderReducer
