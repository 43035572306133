
import { GET_ACTIVITY_LOG } from "redux/actions/LogAction"




const initialState = {
	activityLogReducer: [],

}


const LogReducer = function (state = initialState, action) {

	switch (action.type) {

		case GET_ACTIVITY_LOG: {
			return {
				...state,
				activityLogReducer: [...action.payload]
			}
		}

		default: {
			return { ...state }
		}
	}
}

export default LogReducer
