import useAuth from 'hooks/useAuth';
import React, {
	useEffect,
	useState,
} from 'react'
import { Redirect, useLocation } from 'react-router-dom'


// const getUserRoleAuthStatus = (pathname, user, routes) => {
//   const matched = routes.find((r) => r.path === pathname);
//   const authenticated =
//     matched && matched.auth && matched.auth.length
//       ? matched.auth.includes(user.role)
//       : true;

//   return authenticated;
// };

const AuthGuard = ({ children }) => {
	const {
		isAuthenticated
	} = useAuth()

	const [previouseRoute, setPreviousRoute] = useState(null)
	const { pathname } = useLocation()


	// const isUserRoleAuthenticated = getUserRoleAuthStatus(pathname, user, routes);
	// let authenticated = isAuthenticated && isUserRoleAuthenticated;
	let authenticated = isAuthenticated;

	useEffect(() => {
		if (previouseRoute !== null) setPreviousRoute(pathname)
	}, [pathname, previouseRoute])

	return authenticated ?
		<>{children}</>
		:
		<>
			<Redirect
				to={{
					pathname: "/login",
					state: { redirectUrl: previouseRoute }
				}}
			/>
		</>
	// else {
	// 	return (
	// 		<Redirect
	// 			to={{
	// 				pathname: "/login",
	// 				state: { redirectUrl: previouseRoute }
	// 			}}
	// 		/>
	// 	)
	// }
}

export default AuthGuard
