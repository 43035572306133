/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import { useContext } from "react";
import { toast } from 'react-toastify'
import { useEffect } from "react";


// function showToast() {
//   if (setStep(activeStep + 2)) {
//     toast.success("Your Data has been saved", {
//       position: toast.POSITION.BOTTOM_CENTER,
//     });
//   }
// }

const FormFooter = ({ setStep, activeStep, centerProceed, final, login, status, checkAddress, dependency = true, cf }) => {
  const { ExistingNumberData } = useContext(ExistingNumberContext)

  const showToast = () => {
    if (activeStep !== 0) {
      if (activeStep % 2 == 0) {
        toast.success("Your Data has been saved", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  }

  useEffect(() => {
    // showToast();
  }, [])


  return (
    <>
      <div className={centerProceed ? "footer justify-content-center" : "footer"} >
        {/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
        {login &&
          <div className="d-flex flex-column align-items-center me-lg-3">
            <button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
              Login&nbsp;<i class="fa fa-sign-in" aria-hidden="true"></i>
            </button>
            <div className="pe">
              &nbsp;
              {/* <img src="/images/forms/icon-enter.png" alt="" /> */}
            </div>
          </div>
        }
        <div className="d-flex flex-column align-items-center">
          {!dependency ?
            <button type="button" onClick={() => cf()} className="proceed-btn mb-2">
              {final ? "Submit" : "Proceed"}
            </button>
            :
            <button type="button" onClick={() => !ExistingNumberData.error ? setStep(activeStep + 1) : ""} className="proceed-btn mb-2">
              {final ? "Submit" : "Proceed"}
            </button>
          }
          {/* <p class="m-0 p-0">{checkAddress ? 'Invalid Address' : ''}</p>  */}
          <div className="pe">
            Press Enter
            <img src="/images/forms/icon-enter.png" alt="" />
          </div>
        </div>

      </div>
      {/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
      <button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
        <img src="/images/forms/icon-prev.png" alt="" />
      </button>
      {/* <a className="next-step" onclick="form.steps('next')"> */}
      <button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right">
        <img src="/images/forms/icon-next.png" alt="" />
      </button>
    </>
  )
}

export default FormFooter
