import React, { useState } from "react";
import { CoverageContext } from "contexts/FormsContext";
import { useContext } from "react";
import FormFooter from "./FormFooter";
import PasswordChecklist from "react-password-checklist"

const AccountInformation = ({ setStep, activeStep, prevStep, setProcessing }) => {
  let { coverage, setCoverage } = useContext(CoverageContext)
  if (coverage.activeSocialFormat !== "email") {
    if (prevStep === 3) {
      setStep(activeStep + 1)
    } else if (prevStep === undefined || prevStep > 4) {
      setStep(activeStep - 1)
    }
  }

  const [form, setForm] = useState({
    'firstname': coverage.firstname,
    'lastname': coverage.lastname,
    'email': coverage.email,
    'confirm_email': coverage.email,
    'password': coverage.password?coverage.password:"",
    'confirm_password': coverage.confirm_password?coverage.confirm_password:"",
  })


  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value })
    setCoverage({ ...coverage, [e.target.name]: e.target.value })

  }


  return (
    <div className="step">
      <h5 className="mb-2 sm-heading">PORTABILITY CHECK</h5>
      <h2 className="lg-heading mb-5">You're almost there! Let's start by telling us your name and email.</h2>
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-user icon-c" aria-hidden="true"></i>
              <input name="firstname" id="showfName" placeholder="First Name" type="text" className="valid" aria-invalid="false" value={form.firstname} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-user icon-c" aria-hidden="true"></i>
              <input name="lastname" id="showlName" placeholder="Last Name" type="text" value={form.lastname} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-at icon-c" aria-hidden="true"></i>
              <input name="email" id="showemail" placeholder="Email" type="email" value={form.email} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-at icon-c" aria-hidden="true"></i>
              <input name="confirm_email" placeholder="Confirm Email" id="showconfirm_email" type="email" value={form.confirm_email} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-lock icon-c" aria-hidden="true"></i>
              <input type="password" name="password" placeholder="Password" autoComplete="off" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required="" value={form.password} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-12">
          <div className="coverage-field">
            <div className="input-container">
              <i className="fa fa-lock icon-c" aria-hidden="true"></i>
              <input name="confirm_password" type="password" autoComplete="off" placeholder="Confirm Password" value={form.confirm_password} onChange={handleChange} />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <h5>Password must contain the following:</h5>
          </div>
        </div>
        <div className="row">
          <PasswordChecklist
            rules={["minLength", "number", "lowercase", "capital", "match"]}
            minLength={6}
            className="password-validation-wrapper"
            value={form.password}
            valueAgain={form.confirm_password}
            messages={{
              minLength: <div className=" ">Min 6 characters</div>,
              number: "Number Required",
              // specialChar: <div className=" ">Special characters required</div>,
              capital: "One Capital Required",
              match: "Password Matched",
            }}
            iconComponents={{
              ValidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/check.png" />,
              InvalidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/cross.png" />,
            }}

          />
        </div>
        <FormFooter action={'signup'} setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
      </div>
    </div>

  )
}

export default AccountInformation
