import { GET_TOOLTIPS } from "redux/actions/TooltipsAction"

const initialState = []


const TooltipReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_TOOLTIPS: {
			return [...action.payload]
		}

		default: {
			return [...state]
		}
	}
}

export default TooltipReducer
