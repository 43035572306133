import axios from "../../axios"
export const GET_SERVICE_LIST = 'GET_SERVICE_LIST'

export const makeChangesList = (data) => async (dispatch) => {
	return axios.get('make-changes/all', data).then(resp => {
		dispatch({
			type: GET_SERVICE_LIST,
			payload: resp.data.result
		});
	})
}

export const updateVoiceMail = (data) => async (dispatch) => {
	return axios.patch('make-changes/update/voice-mail', data).then(resp => {
		return resp.data
	})
}

export const updateForwardingNumber = (data) => async (dispatch) => {
	return axios.patch('make-changes/update/forward-number', data).then(resp => {
		return resp.data
	})
}
