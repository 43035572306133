import { SignupContext } from "contexts/FormsContext";
import { unformatedPhone } from "general/FormatNumber";
import useAuth from "hooks/useAuth";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Success = () => {
	const { signup } = useAuth();
	const history = useHistory();
	const [triggerSignup, setTriggerSignup] = useState(false);
	const { signupData, setSignupData } = useContext(SignupContext)

	const signupSubmit = async () => {
		try {

			const payload = {
				first_name: signupData?.first_name,
				last_name: signupData?.last_name,
				email: signupData?.email,
				phone: signupData.first_phone.phoneNumber ? unformatedPhone(signupData.first_phone.phoneNumber) : "",
				password: signupData?.password,
				password_confirmation: signupData?.password,
				user_keys: {
					goals: JSON.stringify(signupData?.goals),
					account_for: signupData?.accountFor,
					add_more_people: signupData?.add_more_people,
				}
			}
			if (signupData?.activeSocialFormat != 'email') {
				payload['socialMedia'] = {
					id: signupData?.socialMedia?.id,
					provider: signupData?.activeSocialFormat
				}
			}
			await signup(payload).then(response => {
				if (response) {
					history.push('/customer/dashboard')
				}
			})
		} catch (e) {
			console.log(e)
			toast.error(e.message)
		}
	}
	useEffect(() => {
		setTriggerSignup(true)
		if (triggerSignup) {
			signupSubmit()
		}
		return () => {
			setTriggerSignup(false)
			setSignupData(
				{
					first_phone: '',
					activeSocialFormat: "",
					email: '',
					goals: [
						{
							title: 'I am traveling and want to keep my phone number.',
							answer: false
						},
						{
							title: 'I am moving to an area where my current provider cannot provide service.',
							answer: false
						},
						{
							title: 'My elderly parents are moving to a home and want to keep their phone number.',
							answer: false
						}
					]
				})
		}
	}, [triggerSignup])

	return (
		<div className="step">
			<div className="completed-wrapper">
				<h1 className="success-heading">Success!</h1>
				<img src="/images/forms/check-mark.png" alt="" className="check-img" />
				<h2 className="text">
					Your request has been submitted.
					Thank you for filling out the registration form, you will be redirected momentarily.
					<div className="loader-success"></div>
				</h2>
			</div>
		</div>
	)
}

export default Success
