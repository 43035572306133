import { ExistingNumberContext, NewNumberContext } from 'contexts/FormsContext';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'

const PlanRates = ({ show = false, data, setShow = () => { } }) => {

	const [showModal, setShowModal] = useState(show);
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	const setPlanRateId = (e) => {

		setNewNumberData({
			...NewNumberData,
			'plan_id': e.plan_id,
			'planChange': true,
			'plan_rate_id': e.id
		})


	}

	useEffect(() => {
		return () => {
			setShowModal(false)
		}
	}, [showModal, data])

	return (
		<Modal
			show={show}
			size="lg"
			dialogClassName='plan-modal'
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<h3>Please choose options for your selected plan</h3>
				<table className="table">
					<thead>
						<tr>
							<th></th>
							<th>Price</th>
							<th>Fees</th>
							<th>Minimal</th>
							<th>Description</th>
						</tr>
					</thead>
					<tbody>
						{
							data?.plans_rates?.map((row, index) => (
								<tr key={index}>
									<td className='align-middle'>
										<input type="radio" name='plan_price' defaultChecked={row.id === NewNumberData.plan_rate_id} onChange={() => setPlanRateId(row)} value={row.id} />
									</td>
									<td>${row?.price} per {row?.period !== 1 && row?.period} {row?.period_type}</td>
									<td>
										{parseInt(row?.porting_price) > 0 && <>Porting fee:{row?.porting_price} <br /></>}
										{parseInt(row?.shipping_price) > 0 && <>Shipping fee:{row?.shipping_price} <br /></>}
										{parseInt(row?.taxes_price) > 0 && <>Taxes fee:{row?.taxes_price} <br /></>}
										{parseInt(row?.equipment_price) > 0 && <>Equipment fee:{row?.equipment_price} <br /></>}
										{parseInt(row?.activation_price) > 0 && <>Activation fee:{row?.activation_price} <br /></>}
										{
											(row?.porting_price == 0
												&& row?.shipping_price == 0
												&& row?.taxes_price == 0
												&& row?.equipment_price == 0
												&& row?.activation_price == 0)
											&& "--"

										}
									</td>
									<td>{`${row?.minimal_period} ${row?.minimal_period_type}(s)`}</td>
									<td>{row?.description}</td>
								</tr>
							))

						}
					</tbody>
				</table>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={setShow}>Close</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default PlanRates
