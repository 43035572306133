/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import MiniLoader from "components/Loader/MiniLoader";
import { ExistingNumberContext, NewNumberContext } from "contexts/FormsContext";
import { unformatedPhone } from "general/FormatNumber";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getPlansForNewNumber } from "redux/actions/OrderAction";
import PlanDetails from "../../forms/sub-components/widgets/PlanDetails";
import FormFooter from "./FormFooter";
import PlanCarousel from "./PlanCarousel";
import '../assets/css/new-number-plan.scoped.css'
import { useForm } from "react-hook-form";
import PlanRates from "./PlanRates";
import ValidationError from "../ValidationError";
import ExistingNumber from "pages/order/ExistingNumber";



const Plans = ({ setStep, activeStep }) => {


	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	const dispatch = useDispatch()
	const [plan, setPlan] = useState(1)
	const [loading, setLoading] = useState(false)
	const [plansList, setPlansList] = useState([]);
	const [mobilePlans, setMobilePlans] = useState();
	const [planRates, setPlanRates] = useState({
		open: 0,
		data: []
	})

	const { register, setValue, handleSubmit, watch, formState: { errors } } = useForm({
		defaultValues: {
			'plan_id': NewNumberData?.plan_id || "",
			'plan_rate_id': NewNumberData?.plan_rate_id || ""
		}
	});
	const [selectedPlan, setSelectedPlan] = useState(NewNumberData.plan_id)

	const submitHandler = (e) => {
		setLoading(true)
		setNewNumberData({ ...NewNumberData, ...e })
		setStep(activeStep + 1);
		setLoading(false)
	}

	//   const [State, setState] = useState(NewNumberData.plan ? NewNumberData.plan : "");
	let [tipEnable, setTipEnable] = useState(false)
	//   const handler = (e) => {
	//     console.log(e)
	//     setState(e)
	//     setNewNumberData({ ...NewNumberData, 'plan': e })
	//   }

	const handlePlanSelect = (id) => {
		setSelectedPlan(id)
		setPlanRates({
			open: true,
			data: plansList.filter(value => value.id === id)[0]
		})
	}
	// const plans = [

	// 	<div className="col">
	// 		<div className="card mb-4 rounded-3 shadow-sm plans_card">
	// 			<div className="card-header py-3">
	// 				<h4 className="my-0 fw-normal">DEEP FREEZE</h4>
	// 			</div>
	// 			<div className="card-body">
	// 				<h1 className="card-title pricing-card-title">$3<small className="text-muted fw-light">/mo</small></h1>
	// 				<ul className="list-unstyled mt-3 mb-4">
	// 					<li>When paid annually</li>
	// 				</ul>
	// 				<PlanDetails plan={1}></PlanDetails>
	// 			</div>
	// 			<input type="radio" className="btn-check" id="btn-check-1" name="plans_select" defaultChecked={State === "DEEP FREEZE"} onClick={e => handler("DEEP FREEZE")} />
	// 			<label className={(State === "DEEP FREEZE" ? "checked " : "") + "btn select_pkg"} htmlFor="btn-check-1">Select</label>
	// 		</div>
	// 	</div>
	// 	,

	// 	<div className="col">
	// 		<div className="card mb-4 rounded-3 shadow-sm plans_card">
	// 			<div className="card-header bg-orange py-3">
	// 				<h4 className="my-0 fw-normal">VOICEMAIL PLAN</h4>
	// 			</div>
	// 			<div className="card-body">
	// 				<h1 className="card-title pricing-card-title">$5<small className="text-muted fw-light">/mo</small></h1>
	// 				<ul className="list-unstyled mt-3 mb-4">
	// 					<li>When paid annually</li>
	// 				</ul>
	// 				<PlanDetails plan={2}></PlanDetails>
	// 			</div>
	// 			<input type="radio" className="btn-check" id="btn-check-2" name="plans_select" defaultChecked={State === "VOICEMAIL"} onClick={e => handler("VOICEMAIL")} />
	// 			<label className={(State === "VOICEMAIL" ? "checked " : "") + "btn select_pkg"} htmlFor="btn-check-2">Select</label>
	// 		</div>
	// 	</div>
	// 	,
	// 	<div className="col">
	// 		<div className="card mb-4 rounded-3 shadow-sm plans_card">
	// 			<div className="card-header py-3">
	// 				<h4 className="my-0 fw-normal">FORWARDING PLAN</h4>
	// 			</div>
	// 			<div className="card-body">
	// 				<h1 className="card-title pricing-card-title">$9<small className="text-muted fw-light">/mo</small></h1>
	// 				<ul className="list-unstyled mt-3 mb-4">
	// 					<li>When paid annually</li>
	// 				</ul>
	// 				<PlanDetails plan={3}></PlanDetails>
	// 			</div>
	// 			<input type="radio" className="btn-check" id="btn-check-3" name="plans_select" defaultChecked={State === "FORWARDING"} onClick={e => handler("FORWARDING")} />
	// 			<label className={(State === "FORWARDING" ? "checked " : "") + "btn select_pkg"} htmlFor="btn-check-3">Select</label>
	// 		</div>
	// 	</div>

	// ];


	useEffect(() => {
		setValue('plan_rate_id', NewNumberData?.plan_rate_id)
		setValue('plan_id', NewNumberData?.plan_id)
		const fetchData = async () => {

			const collectedData = {
				is_fax: false,
				is_mobile: false,
				is_tollfree: false,
				is_landline: true
			}

			await dispatch(getPlansForNewNumber(collectedData)).then(resp => {
				if (resp.bool) {
					setPlansList(resp.result?.result)
					setMobilePlans(resp.result?.result)
				}
			}).catch(err => {
				console.log(err)
			})
		}
		fetchData();
	}, [NewNumberData.plan_id, NewNumberData.plan_rate_id])


	return (
		<>
			<ValidationError errors={errors} />
			<div className="step plans_box">
				{
					<PlanRates show={planRates.open} data={planRates.data} setShow={() => setPlanRates({ open: false, data: [] })} />
				}
				<form onSubmit={handleSubmit(submitHandler)}>
					<input type="hidden" {...register('plan_id', { required: "Please select a Plan!" })} />
					<input type="hidden" {...register('plan_rate_id', { required: "You need to select plan rate!" })} />
					<h5 className="mb-2 sm-heading text-center">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading text-center">Our Plans</h2>
					<div className="d-lg-block d-none">

						<div className="row justify-content-center row-cols-1 row-cols-md-5 mb-3 text-center order-plans-card">
							{
								plansList.length === 0 ?
									<MiniLoader />
									:
									plansList?.map((planRow, index) => {

										return (
											<>

												{planRow?.plans_rates?.length === 0 ?
													<></>
													:
													<div className="col" onClick={() => handlePlanSelect(planRow.id)}>
														<div className="card mb-4 rounded-3 shadow-sm plans_card" onClick={() => handlePlanSelect(planRow.id)}>
															<div className={selectedPlan === planRow.id ? "card-header py-3 bg-orange" : "card-header py-3"}>
																<h4 className="my-0 fw-normal">{planRow?.plans_category?.name.toUpperCase()}</h4>
															</div>
															<div className="card-body">
																<h1 className="card-title pricing-card-title">{planRow?.plans_rates[0]?.price}<small className="text-muted fw-light">$/mo</small></h1>
																<ul className="list-unstyled mt-3 mb-4">
																	<li>When paid annually</li>
																</ul>
																{/* <a onClick={() => { setTipEnable(true); setPlan(1) }} class="cursor-pointer">View Plan Details</a> */}
															</div>
															<input type="radio" className="btn-check" id="btn-check-1" name="plans_select" onClick={() => handlePlanSelect(planRow.id)} />
															<label className={selectedPlan === planRow.id ? " checked btn select_pkg" : "btn select_pkg"}>View</label>
														</div>
													</div>
												}
											</>
										)
									})
							}
						</div>
					</div>
					{tipEnable &&
						<div className="tipWrapper planDetails">
							<div className="tipModule">
								<div className="tipClose" onClick={() => (setTipEnable(false))}>x</div>
								{
									plan === 1 &&
									<>
										<h2 class="modal-title">DEEP FREEZE</h2>
										<table class="table table-striped">
											<tbody>
												<tr>
													<th scope="row" class="hd_feat">One-Time Charge</th>
													<td class="text-center bg-white">$15 Porting Fee</td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Monthly Minutes*</th>
													<td class="text-center bg-white">0 Minutes</td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Voicemail</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Call Forwarding</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Outbound Calling</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Text Message Forwarding</th>
													<td class="text-center bg-white">Optional Addons</td>
												</tr>
											</tbody>
										</table>
									</>
								}
								{
									plan === 2 &&
									<>
										<h2 class="modal-title">VOICEMAIL PLAN</h2>
										<table class="table table-striped">
											<tbody>
												<tr>
													<th scope="row" class="hd_feat">One-Time Charge</th>
													<td class="text-center bg-white">No Porting Fee ($0)</td>

												</tr>
												<tr>
													<th scope="row" class="hd_feat">Monthly Minutes*</th>
													<td class="text-center bg-white">100 Minutes</td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Voicemail</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/tick-mark.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Call Forwarding</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Outbound Calling</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Text Message Forwarding</th>
													<td class="text-center bg-white">Optional Addons</td>
												</tr>
											</tbody>
										</table>
									</>
								}
								{
									plan === 3 &&
									<>
										<h2 class="modal-title text-center">FORWARDING
											PLAN</h2>
										<table class="table table-striped">
											<tbody>
												<tr>
													<th scope="row" class="hd_feat">One-Time
														Charge</th>
													<td class="text-center bg-white">No Porting
														Fee ($0)</td>

												</tr>
												<tr>
													<th scope="row" class="hd_feat">Monthly
														Minutes*</th>
													<td class="text-center bg-white">500 Minutes</td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Voicemail</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Call
														Forwarding</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/tick-mark.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Outbound
														Calling</th>
													<td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
												</tr>
												<tr>
													<th scope="row" class="hd_feat">Text Message
														Forwarding</th>
													<td class="text-center bg-white">Optional
														Addons</td>
												</tr>
											</tbody>
										</table>
									</>
								}
							</div>
						</div>
					}
					<div className="row d-lg-none d-block row-cols-1 row-cols-md-3 mb-3 text-center">
						{
							plansList.length === 0 ?
								<MiniLoader />
								:
								<MiniLoader />
							// <PlanCarousel items={mobilePlans} />
						}
					</div>


					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">

								<button type='submit' className="proceed-btn mb-2" disabled={loading}>
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Proceed"
									}
								</button>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default Plans
