import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import useAuth from 'hooks/useAuth'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactPasswordChecklist from 'react-password-checklist'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const ChangePassword = () => {

	const { changePassword } = useAuth();
	const history = useHistory();
	const location = useLocation();
	const [loading, setLoading] = useState(false);
	const { register, handleSubmit, setError, formState: { errors }, watch, clearErrors } = useForm();
	const { isAuthenticated } = useAuth();
	const [isStrongPassword, setIsStrongPassword] = useState(false)
	const submitHandler = async (form) => {

		if (!isStrongPassword) {
			setError("weak_password", { message: "Weak Password" })
			setLoading(false)
			return

		} else {
			clearErrors("weak_password")
		}
		setLoading(true)
		try {
			await changePassword({ email: location.state?.email, ...form }).then(result => {

				if (result.bool) {
					toast.success("Password changed Successfully!");
					history.push('/login')
				}
			});
		} catch (e) {
			toast.error(e.message)
			console.log(e);
			setLoading(false)
		}
		setLoading(false)
	}
	console.log(errors)
	useEffect(() => {

		if (isAuthenticated) {
			history.push('/')
		}
	}, [isAuthenticated, history])

	return (
		<>
			<Title title={"Change Password"} />
			<Topbar />

			<section className="login_section">
				<div className="container">
					<div className="row justify-content-end">
						<div className="col-xl-6 col-md-8 col-12">
							<div className="limiter">
								<div className="container-login100">
									<div className="wrap-login100">

										<form className="login100-form validate-form" onSubmit={handleSubmit(submitHandler)} autoComplete="off">
											<img src="/images/forgot-password.png" className="login-icon" alt="logo" />
											<span className="login100-form-title slide-right">
												New Password
											</span>
											<p className="login100-form-subtitle">
												Please enter a strong password.
											</p>

											<div className="wrap-input100">

												<input
													className={"form-control form-control-plaintext bg-transparent text-center text-bold"}
													type="email"
													placeholder="Enter new password"
													value={location.state?.email}
													readOnly
												/>
												{errors.password && <span className='text-danger text-small input100-valdation'>Password field is required</span>}
											</div>
											<div className="wrap-input100">

												<input
													defaultValue={''}
													className={errors.password ? "input100 border border-danger" : "input100"}
													type="password"
													placeholder="Enter new password"
													{...register("password", { required: true })}
												/>

												{errors.password && <span className='text-danger text-small input100-valdation'>Password field is required</span>}
											</div>
											<div className="wrap-input100">

												<input
													defaultValue={''}
													className={errors.confirm_password ? "input100 border border-danger" : "input100"}
													type="password"
													placeholder="Re-enter password"
													{...register("confirm_password", {
														required: "Confirm Password is required",
														validate: (val) => {
															if (watch('password') !== val) {
																return "Your passwords do no match";
															}
														},
													})}
												/>

												{errors.confirm_password && <span className='text-danger text-small input100-valdation'>{errors.confirm_password.message}</span>}
											</div>

											<div className="row mt-3">
												<div className="col">
													<h5>Password must contain the following:</h5>
												</div>
											</div>
											{/* <div className="row"> */}
											<ReactPasswordChecklist
												rules={["minLength", "number", "match", "capital", "lowercase"]}
												minLength={6}
												className="password-validation-wrapper"
												value={watch("password") || ""}
												valueAgain={watch("confirm_password") || ""}
												onChange={(r) => {
													!r ?
														setIsStrongPassword(r)
														:
														setIsStrongPassword(r);
													clearErrors("weak_password")

												}}
												messages={{
													minLength: <div className=" " style={{ fontSize: "12px" }}>Min 6 characters</div>,
													number: <div style={{ fontSize: "12px" }}>Number Required</div>,
													capital: <div style={{ fontSize: "12px" }}>One Capital Required</div>,
													match: <div style={{ fontSize: "12px" }}>Password Matched</div>,
													lowercase: <div style={{ fontSize: "12px" }}>Has a lowercase letter.</div>,
												}}
												iconComponents={{
													ValidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/check.png" />,
													InvalidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/cross.png" />,
												}}

											/>
											{errors.weak_password && <span className='text-danger text-small input100-valdation'>{errors.weak_password?.message}</span>}

											{/* </div> */}
											<div className="mt-4 text-center">
												<div className="wrap-login100-form-btn">
													<button type='submit' className="login100-form-btn" disabled={loading}>
														{
															loading ?
																<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																:
																"Save"
														}
													</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	)
}

export default ChangePassword
