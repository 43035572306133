/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useContext, useState } from "react";
import { SignupContext } from "contexts/FormsContext";
import { useForm } from "react-hook-form";
import ValidationError from "./ValidationError";
import ReactPasswordChecklist from "react-password-checklist";
import { useDispatch } from "react-redux";
import { checkValidateViaAPI } from "redux/actions/UserAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import useAuth from "hooks/useAuth";

const PersonalData = () => {

	const history = useHistory()
	const dispatch = useDispatch()
	const { verifyUser } = useAuth();
	const { signupData, setSignupData } = useContext(SignupContext)

	const [loading, setLoading] = useState(false)
	const [isStrongPassword, setIsStrongPassword] = useState(false)

	const { register, handleSubmit, watch, setError, clearErrors, formState: { errors } } = useForm({
		defaultValues: {
			"confirm_email": signupData?.confirm_email || "",
			"email": signupData?.email || "",
			"first_name": signupData?.first_name || "",
			"last_name": signupData?.last_name || "",
			"confirm_password": signupData?.confirm_password || "",
			"password": signupData?.password || "",
		}
	});

	const submitHandler = async (e) => {

		setLoading(true)

		if (!isStrongPassword) {
			setError("weak_password", { message: "Weak Password" })
			setLoading(false)
			return

		} else {
			clearErrors("weak_password")
		}

		await dispatch(checkValidateViaAPI({ "email": e.email })).then(async (response) => {

			if (response.bool) {
				setSignupData({ ...signupData, ...e })

				await verifyUser({ 'email': e.email, 'name': signupData?.first_name }).then((resp) => {
					if (resp?.bool) {
						toast.success(resp.message);
						history.push('/signup/email-verification')
					}
					setLoading(false)
				})
			}
		}).catch(err => {
			// toast.error(err.message)
			setError("email", { message: err.message })
			setError("confirm_email", { message: err.message })
			setLoading(false)
		})
	}

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">SIGN UP</h5>
					<h2 className="lg-heading mb-5">You're almost there! Let's start by telling us your name and email.</h2>
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										className={errors.first_name ? "error input-field" : "input-field"}
										name="first_name"
										id="first_name"
										type="text"
										placeholder="John"
										{...register("first_name", { required: "First name is required", pattern: { message: "Invalid input", value: /^[a-zA-Z ]+$/ } })}
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										name="last_name"
										className={errors.last_name ? "error input-field" : "input-field"}
										id="last_name"
										type="text"
										placeholder="Doe"
										{...register("last_name", { required: "Last name is required", pattern: { message: "Invalid input", value: /^[a-zA-Z ]+$/ } })}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-at icon-c" aria-hidden="true"></i>
									<input
										name="email"
										className={errors.email ? "error input-field" : "input-field"}
										id="email"
										type="email"
										placeholder="Email"
										{...register("email", {
											required: "Email is required", pattern: {
												value: /\S+@\S+\.\S+/,
												message: "Entered value does not match email format"
											}
										})} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-at icon-c" aria-hidden="true"></i>
									<input
										name="confirm_email"
										className={errors.confirm_email ? "error input-field" : "input-field"}
										type="email"
										placeholder="Confirm Email"
										{...register("confirm_email", {
											required: "Confirm Email is required", pattern: {
												value: /\S+@\S+\.\S+/,
												message: "Entered value does not match email format"
											},
											validate: (val) => {
												if (watch('email') !== val) {
													return "Your Email do no match";
												}
											}

										})} />
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-lock icon-c" aria-hidden="true"></i>
									<input
										name="password"
										className={errors.password ? "error input-field" : "input-field"}
										placeholder="Password"
										type="password"
										{...register("password", {
											required: "password field is required",
											min: 6
										})} />
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-lock icon-c" aria-hidden="true"></i>
									<input
										name="confirm_password"
										placeholder="Confirm Password"
										className={errors.confirm_password ? "error input-field" : "input-field"}
										type="password"
										{...register("confirm_password", {
											required: "Confirm password is required",
											validate: (val) => {
												if (watch('password') !== val) {
													return "Your Password do no match";
												}
											}
										})} />
								</div>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col">
								<h5>Password must contain the following:</h5>
							</div>
						</div>
						<div className="row">
							<ReactPasswordChecklist
								rules={["minLength", "number", "lowercase", "capital", "match"]}
								minLength={6}
								className="password-validation-wrapper"
								value={watch("password") || ""}
								valueAgain={watch("confirm_password") || ""}
								onChange={(r) => {
									!r ?
										setIsStrongPassword(r)
										:
										setIsStrongPassword(r);
									clearErrors("weak_password")

								}}
								messages={{
									minLength: <div className=" ">Min 6 characters</div>,
									number: "Number Required",
									// specialChar: <div className=" ">Special characters required</div>,
									capital: "One Capital Required",
									match: "Password Matched",
								}}

								iconComponents={{
									ValidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/check.png" />,
									InvalidIcon: <img alt="" className="me-2" style={{ width: "20px" }} src="/images/forms/cross.png" />,
								}}

							/>
						</div>

					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<button type='submit' className="proceed-btn mb-2" disabled={loading}>
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Proceed"
									}
								</button>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" onClick={() => history.goBack()} data-title="Previous" className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>

	)
}

export default PersonalData
