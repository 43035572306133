import MiniLoader from 'components/Loader/MiniLoader';
import { ExistingNumberContext } from 'contexts/FormsContext';
import React, { useEffect } from 'react'
import { useContext } from 'react';
import { useState } from 'react';
import FormFooter from './FormFooter';
import PlanCarousel from "./PlanCarousel";
import '../assets/css/order-plan.scoped.css'
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { getCountries } from 'redux/actions/StreetMapAction';
import { startsWith } from 'lodash';
import { parsePhoneNumber } from 'libphonenumber-js';
import { formatPhone } from 'general/FormatNumber';
import Select from 'react-select';

const customStyles = {
	control: () => ({
		// none of react-select's styles are passed to <Control />
		height: 56,
		width: "100%",
		background: "#fff",
		padding: "31px 15px",
		display: "flex",
		alignItems: "center",
		border: "1px solid #dbdbd9",
		borderRadius: "4px"
	}),
}
const customStyle = {
	carrierImage: {
		marginRight: "10px",
		borderRadius: "50%",
		height: "40px",
		objectFit: "cover",
		objectPosition: "center"
	}
}

const PlanOptions = ({ setStep, activeStep, heading, selectedPlan, plans }) => {

	const dispatch = useDispatch()
	const carrierImageURL = `${process.env.REACT_APP_API_URL}/uploads/carrier-images/`;
	const defaultCarrierImg = `/images/forms/test-icon.png`;
	const carrierList = useSelector(state => state.carrier.map(carrierRow => {
		return {
			label: carrierRow?.name,
			value: carrierRow?.id,
			image: carrierRow?.carrier_image ? carrierImageURL + carrierRow?.carrier_image : defaultCarrierImg
		}
	}))

	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [selectedPlanDetails, setSelectedPlanDetails] = useState(ExistingNumberData?.plan_details);
	const [planOption, setPlanOption] = useState(ExistingNumberData?.plan_details?.plans_rates ? ExistingNumberData?.plan_details?.plans_rates : selectedPlan.plans_rates)
	const [plansList, setPlansList] = useState(plans);
	const [isLoading, setIsLoading] = useState(false);
	const [newSelectedPlan, setNewSelectedPlan] = useState(selectedPlan);
	const [selectedRate, setSelectedRate] = useState(ExistingNumberData.plan_rate_id || ''); //selected rate id
	const [selectedPlanId, setSelectedPlanId] = useState(ExistingNumberData.plan || selectedPlan.id); //selected plan id
	var rates = [];
	const handleChange = (e) => {
		setSelectedRate(e.target.value)
		setExistingNumberData({
			...ExistingNumberData,
			'plan_rate_id': e.target.value,
			'planChange': true

		})
	}

	const inputChangeHandler = ({ target: { name, value } }) => {
		setExistingNumberData({ ...ExistingNumberData, [name]: value })
	}
	const selectChangeHandler = (e) => {
		//console.log("aaaaaaaaaa", e)
		setExistingNumberData({ ...ExistingNumberData, "plan_forward_number_carrier": e })
	}
	const handlePlanSelect = (e) => {
		setIsLoading(true)
		const selectedPlanDetails = e.target.options[e.target?.selectedIndex]?.dataset?.plandata
		setSelectedPlanDetails(JSON.parse(selectedPlanDetails))
		setSelectedRate('')
		const newPlanData = plansList.filter(value => value.id == e.target.value)[0]
		setPlanOption(newPlanData.plans_rates)
		setNewSelectedPlan(newPlanData)
		setSelectedPlanId(e.target.value)
		setExistingNumberData({
			...ExistingNumberData,
			"plan": e.target.value,
			'plan_details': newPlanData,
			'plan_voice_email': "",
			'plan_text_email': "",
			'plan_forward_number': "",
			'plan_forward_number_carrier': "",
			'plan_forward_number_country_id': "",
		})
		setIsLoading(false)
	}

	const handleOnChange = (e, value) => {
		// validate phone via library
		if (e.length > 10) {
			setExistingNumberData({ ...ExistingNumberData, error: [], ready: true })
			const phoneNumber = parsePhoneNumber(e, value.countryCode.toUpperCase())
			// checking if number is valid or not via libphonenumber-js
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, error: ['invalid_phone'] })
				return false
			}

			if (phoneNumber.isValid() && phoneNumber.getType()) {
				setExistingNumberData({
					...ExistingNumberData,
					'plan_forward_number': e,
					'plan_forward_number_country_id': countryList.filter(row => row.code === value.countryCode)[0]?.value,
					error: [],
					processing: false
				})
			}
		}

	}

	useEffect(() => {
		dispatch(getCountries());
		setExistingNumberData({ ...ExistingNumberData, 'error': [], ready: true })

		if (ExistingNumberData.plan === "" || ExistingNumberData.plan_rate_id === "") {
			setExistingNumberData({ ...ExistingNumberData, 'error': ["Missing plan data"], ready: false })
		}

	}, [ExistingNumberData.plan, ExistingNumberData.plan_rate_id])

	return (
		<div className="step choose-plan">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">{heading}</h2>
			<div className="row">
				<div className="col">
					<h5 className="mb-2 sm-heading text-uppercase">Selected plan</h5>
					<select className='sp-heading-new form-select form-select-lg mb-3' onChange={handlePlanSelect} name="" id="">
						{
							plansList.length === 0 ?
								<MiniLoader />
								:
								plansList?.map((planRow, index) => {
									index++;
									return (
										<>
											{(planRow?.plans_rates?.length == 0) ?
												<></>
												: <option
													selected={planRow.id == selectedPlanId}
													data-planData={JSON.stringify(planRow)}
													value={planRow.id}
												>
													{planRow?.plans_category?.name}
												</option>
											}
										</>
									)
								})
						}
					</select>
					<div>
						{
							selectedPlanDetails?.text_message && (
								<>

									<input
										type="text"
										placeholder='Email for text message'
										name="plan_text_email"
										className='form-control py-2 mb-3'
										value={ExistingNumberData?.plan_text_email}
										onChange={inputChangeHandler}

									/>
								</>
							)
						}
						{
							selectedPlanDetails?.forwarding && (
								<>
									<div className="d-flex row1-col2 mb-2">

										<div class="form-group w-50 px-1">
											<h5 className="mb-2 sm-heading text-uppercase">Number </h5>
											<PhoneInput
												country={'us'}
												inputClass={"default"}
												onlyCountries={['us', 'ca']}
												value={ExistingNumberData?.plan_forward_number}
												countryCodeEditable={false}
												onChange={(e, value) => handleOnChange(e, value)}

											/>
										</div>
										<div class="form-group w-50 px-1">
											<h5 className="mb-2 sm-heading text-uppercase">Carrier </h5>
											<Select
												onChange={(e) => selectChangeHandler(e)}
												value={ExistingNumberData?.plan_forward_number_carrier}
												className='w-100'
												//name='plan_forward_number_carrier_id'
												options={carrierList}
												styles={customStyles}
												formatOptionLabel={carrier => (
													<div className="carrier-option">
														<img alt="carrier" style={customStyle.carrierImage} width={40} className={"mr-1"} src={carrier.image} />
														<span>{carrier.label}</span>
													</div>
												)}
											/>

										</div>
									</div>

								</>
							)
						}
						{
							selectedPlanDetails.voicemail && (
								<>
									<h5 className="mb-2 sm-heading text-uppercase">Email (required)</h5>
									<input
										type="text"
										placeholder='Enter email'
										name="plan_voice_email"
										className='form-control py-2 mb-3 email-box'
										value={ExistingNumberData?.plan_voice_email}
										onChange={inputChangeHandler}
									/>
								</>
							)
						}
					</div>

					<h3 className='text-secondary mb-3'> Please select a rate plan from the following options: </h3>
					<div className="d-lg-block d-none">
						<div className='row plan-row justify-content-center row-cols-1 row-cols-md-3'>
							{

								(isLoading) ?
									<MiniLoader />
									:
									// <></>
									planOption.map((row, index) => {
										return (

											<>

												<div className='col mb-4 selecotr-item'>
													<input value={row.id} onChange={handleChange} type="radio" id={'radio' + index} name='plan_price' className='selector-item_radio' checked={selectedRate == row.id} />
													<label htmlFor={'radio' + index} className={(selectedRate == row.id) ? 'selector-item_label checked' : "selector-item_label"}>
														<i className="fa fa-check selected-check" aria-hidden="true"></i>
														<div className="option-box">
															<h2 className='p-3 mb-0'>Option {index + 1}</h2>
															<div className="p-4 pt-0 pb-0 box-content">

																<div className="row-col align-items-center d-flex justify-content-between">
																	<strong className='text-uppercase'>Porting fee</strong>
																	<span >{parseInt(row?.porting_price) > 0 ? '$' + row?.porting_price : 'None'}</span>
																</div>
																{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Shipping fee</strong>
																<span >{parseInt(row?.shipping_price) > 0 ? '$' + row?.shipping_price : 'None'}</span>
															</div> */}
																{/* <div className="row-col align-items-center d-flex justify-content-between">
																	<strong className='text-uppercase'>Taxes fee</strong>
																	<span >{parseInt(row?.taxes_price) > 0 ? '$' + row?.taxes_price : 'None'}</span>
																</div> */}
																{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Equipment fee</strong>
																<span >{parseInt(row?.equipment_price) > 0 ? '$' + row?.equipment_price : 'None'}</span>
															</div> */}
																{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Activation fee</strong>
																<span >{parseInt(row?.activation_price) > 0 ? '$' + row?.activation_price : 'None'}</span>
															</div> */}

																<div className="box-description">
																	<strong className='text-uppercase'>Description</strong>
																	<div className='white-bg-text'>{row?.description} </div>
																</div>
																<div className=" p-2 price-div d-flex justify-content-center align-items-start">
																	<strong className='plan-price'>${row?.price}  </strong>
																	<span className='plan-duration'> per {row?.period !== 1 && row?.period} {row?.period_type}</span>
																</div>
																<div className="text-danger" style={{ fontSize: 10 }}>
																	Including all Taxes & Fees.
																</div>

															</div>
														</div>
													</label>
												</div>
											</>
										)
									})
							}

						</div>
					</div>
					<div className="row d-lg-none d-block row-cols-1 row-cols-md-1 mb-3 text-center">
						{

							(isLoading) ?
								<MiniLoader />
								:
								// <></>
								planOption.map((row, index) => {
									var items = (
										<>
											<div className='col mb-4 selecotr-item'>
												<input value={row.id} onChange={handleChange} type="radio" id={'radio' + index} name='plan_price' className='selector-item_radio' checked={selectedRate == row.id} />
												<label htmlFor={'radio' + index} className={(selectedRate == row.id) ? 'selector-item_label checked' : "selector-item_label"}>
													<i className="fa fa-check selected-check" aria-hidden="true"></i>
													<div className="option-box">
														<h2 className='p-3 mb-0'>Option {index + 1}</h2>
														<div className="p-4 pt-0 pb-0 box-content">

															<div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Porting fee</strong>
																<span >{parseInt(row?.porting_price) > 0 ? '$' + row?.porting_price : 'None'}</span>
															</div>
															{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Shipping fee</strong>
																<span >{parseInt(row?.shipping_price) > 0 ? '$' + row?.shipping_price : 'None'}</span>
															</div> */}
															{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Taxes fee</strong>
																<span >{parseInt(row?.taxes_price) > 0 ? '$' + row?.taxes_price : 'None'}</span>
															</div> */}
															{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Equipment fee</strong>
																<span >{parseInt(row?.equipment_price) > 0 ? '$' + row?.equipment_price : 'None'}</span>
															</div> */}
															{/* <div className="row-col align-items-center d-flex justify-content-between">
																<strong className='text-uppercase'>Activation fee</strong>
																<span >{parseInt(row?.activation_price) > 0 ? '$' + row?.activation_price : 'None'}</span>
															</div> */}

															<div className="box-description">
																<strong className='text-uppercase'>Description</strong>
																<div className='white-bg-text'>{row?.description} </div>
															</div>
															<div className=" p-2 price-div d-flex justify-content-center align-items-start">
																<strong className='plan-price'>${row?.price}  </strong>
																<span className='plan-duration'> per {row?.period !== 1 && row?.period} {row?.period_type}</span>
															</div>
															<div className="text-danger" style={{ fontSize: 10 }}>
																Including all Taxes & Fees.
															</div>
														</div>
													</div>
												</label>
											</div>
										</>
									)
									rates = [...rates, items]
								})}
						<PlanCarousel items={rates} />
					</div>
				</div>
			</div>
			<FormFooter action={'save-plan'} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}
export default PlanOptions;
