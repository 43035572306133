/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import { NewNumberContext } from "contexts/FormsContext";
import React, { useContext, useState } from "react";

import Cards from '@snowpak/react-credit-cards';
import "../assets/css/credit-card.css";
import '@snowpak/react-credit-cards/lib/styles-compiled.css'
import "../assets/css/form-style.css";
import FormFooter from "./FormFooter";


const PaymentDetails = ({ setStep, activeStep }) => {

	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	let timeoutID;
	const [formData, setFormData] = useState({
		'name_on_card': NewNumberData.name_on_card ? NewNumberData.name_on_card : "",
		'card_number': NewNumberData.card_number ? NewNumberData.card_number : "",
		'expiry': NewNumberData.expiry ? NewNumberData.expiry : "",
		'expiryyear': NewNumberData.expiryyear ? NewNumberData.expiryyear : "",
		'cvc': NewNumberData.cvc ? NewNumberData.cvc : "",
		'terms_condition': NewNumberData.terms_condition,
		'focus': ''
	})

	const handleTermsChange = (e) => {
		const { value, checked } = e.target;
		setNewNumberData({ ...NewNumberData, terms_condition: checked })
		setFormData({
			...formData, 'terms_condition': checked
		})
	}

	function handleChange(e) {
		setFormData({
			...formData, [e.target.name]: e.target.value
		})
	}

	const setup = () => {
		//if any of the events fire, it resets the timer
		window.addEventListener("keypress", () => {
			resetTimer();
		});
		window.addEventListener("keyup", () => {
			resetTimer();
		});
		window.addEventListener("scroll", () => {
			resetTimer();
		});
		window.addEventListener("keydown", () => {
			resetTimer();
		});
		window.addEventListener("mousemove", () => {
			resetTimer();
		});
		window.addEventListener("mousewheel", () => {
			resetTimer();
		});
		window.addEventListener("mousedown", () => {
			resetTimer();
		});
		window.addEventListener("touchmove", () => {
			resetTimer();
		});
		window.addEventListener("MSPointerMove", () => {
			resetTimer();
		});
		window.addEventListener("DOMMouseScroll", () => {
			resetTimer();
		});
		window.addEventListener(onscroll, () => {
			resetTimer();
		});
		//starts timer of inactivity
		startTimer();
	};
	function goInactive() {
		// alerting about session expiration and clearing session data
		alert("Your Session expired.Please refresh the page.");

		sessionStorage.clear();
	}
	function goActive() {
		//starting timer
		startTimer();
	}
	function resetTimer() {
		window.clearTimeout(timeoutID);
		//calling goactive to again starts the timer once it gets reset
		goActive();
	}
	function startTimer() {
		//checking after every 1 min
		// wait 30 min before calling goInactive
		timeoutID = window.setTimeout(goInactive, 60000 * 30);
	}
	//storing data on submit button click
	const submit = (e) => {
		sessionStorage.setItem("user", JSON.stringify(formData));
		e.preventDefault();

		//restoring initial state of the app
		// setFormData({
		//   name: "",
		//   number: "",
		//   cvc: "",
		//   expiry: "",
		//   expiryyear: "",
		//   focus: ""
		// });
	};

	/*function to remove special characters like + - . e E
	  which are otherwise valid in case of type=number used in case  of cvc*/
	const removeSpecial = (e) => {
		var invalidChars = ["-", "+", "e", "E", " ", "."];
		if (invalidChars.includes(e.key)) {
			e.preventDefault();
		}
	};

	//function to add space after every 4 character in card number
	const addSpace = (e) => {
		const { value, id } = e.target;
		var ele = document.getElementById(id);
		if (value.length === 4 || value.length === 9 || value.length === 14)
			ele.value = value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
	};

	function componentDidMount() {
		setup(); //setting up all window event  listener to detect user activity after component gets mounted
		setInterval(() => {
			var hours = 0.5; // Reset when storage is more than 24hours
			var now = new Date().getTime(); //recording session start time
			var setupTime = sessionStorage.getItem("setupTime"); //pushing setting start time to session storage

			if (setupTime === null) {
				//this only works first time when there is no value in session storage
				sessionStorage.setItem("setupTime", now);
			} else {
				//comparing time passed with the specified time of session

				if (now - setupTime > hours * 60 * 60 * 1000) {
					//session time exceeds 30 min
					sessionStorage.clear(); //clearing storage
					sessionStorage.setItem("setupTime", now); //storing starting time of new session
				}
			}
		}, 1000);
	}

	//function to validate the length of input in case of cvv and replace invalid characters in case of card number
	const validateInput = (e) => {
		const { name, value, maxLength, id } = e.target;
		setNewNumberData({ ...NewNumberData, [name]: value })
		var temp, ele;

		if (id === "cvv") {
			if (value.length > maxLength) {
				temp = value.slice(0, maxLength);
				const num = temp;
				ele = document.getElementById(id);
				ele.value = temp;
				setFormData({ ...formData, [name]: num });
			} else {
				setFormData({ ...formData, [name]: value });
			}
		}
		//works when function is invoked by cardNumber input
		else {
			ele = document.getElementById(id);
			//if user enters any invalid characters it gets replaced
			ele.value = value.replace(
				/[A-Za-z}"`~_=.\->\]|<?+*/,;[:{\\!@#/'$%^&*()]/g,
				""
			);
			setFormData({ ...formData, [name]: ele.value });
		}
	};

	//function to handle focus on input
	const handleInputFocus = (e) => {
		setFormData({ ...formData, focus: e.target.name });
	};

	//function to handle  input and update the state of variable
	const handleInputChange = (e) => {
		const { name, value, id } = e.target;
		setNewNumberData({ ...NewNumberData, [name]: value })
		if (id === "cardHolder") {
			var ele = document.getElementById(id);
			//if user enters any invalid characters it gets replaced
			ele.value = value.replace(
				/[}"`~_=.\->\]|<?+*/,\d;[:{\\!@#/'$%^&*()]/g,
				""
			);
			setFormData({ ...formData, [name]: ele.value });
		} else setFormData({ ...formData, [name]: value });
	};

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Please provide your payment details</h2>
			<div>
				<div className="credit-card ">
					<Cards
						locale={{ valid: "Expires" }}
						placeholders={{ name: "FULL NAME" }}
						cvc={formData.cvc}
						expiry={formData.expiry}
						expiryyear={formData.expiryyear}
						focused={formData.focus}
						name={formData.name_on_card}
						number={formData.card_number}
					/>
				</div>
				<form className="payment-form">
					<div className="row">
						<div className="form-group my-2 col-lg-6 col-12">
							<label htmlFor="cardNumber" className="card-label">
								Card Number
							</label>
							<input
								type="text"
								onChange={validateInput}
								value={formData.card_number}
								onKeyDown={removeSpecial}
								onPaste={(e) => e.preventDefault()}
								onKeyPress={addSpace}
								onFocus={handleInputFocus}
								name="card_number"
								maxLength="19"
								id="cardNumber"
								className="form-control form-control-lg"
							/>
						</div>
						<div className="form-group my-2 col-lg-6 col-12">
							<label htmlFor="cardHolder" className="card-label">
								Card holder
							</label>
							<input
								type="text"
								name="name_on_card"
								spellCheck="false"
								value={formData.name_on_card}
								maxLength="20"
								autoComplete="off"
								onPaste={(e) => e.preventDefault()}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
								id="cardHolder"
								className="form-control form-control-lg"
							/>
						</div>
					</div>
					<div className="row">
						<div className="date-cvv-box col-lg-6 col-12">
							<div className="expiry-class">
								<div className="form-group card-month ">
									<label htmlFor="cardMonth" className="card-label">
										Expiration Date
									</label>

									<div className="form-group my-2 d-flex flex-row">
										<select
											id="cardMonth"
											data-ref="cardDate"
											value={formData.expiry}
											name="expiry"
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											className="form-control form-control-lg"
										>
											<option value="" defaultChecked="true">
												Month
											</option>
											<option value="01">01</option>
											<option value="02">02</option>
											<option value="03">03</option>
											<option value="04">04</option>
											<option value="05">05</option>
											<option value="06">06</option>
											<option value="07">07</option>
											<option value="08">08</option>
											<option value="09">09</option>
											<option value="10">10</option>
											<option value="11">11</option>
											<option value="12">12</option>
										</select>
										<select
											id="cardYear"
											data-ref="cardDate"
											value={formData.expiryyear}
											name="expiryyear"
											onChange={handleInputChange}
											onFocus={handleInputFocus}
											className="form-control form-control-lg ms-2"
										>
											<option value="" defaultChecked="true">
												Year
											</option>
											<option value="2022">2022</option>
											<option value="2023">2023</option>
											<option value="2024">2024</option>
											<option value="2025">2025</option>
											<option value="2026">2026</option>
											<option value="2027">2027</option>
											<option value="2028">2028</option>
											<option value="2029">2029</option>
											<option value="2030">2030</option>
											<option value="2031">2031</option>
											<option value="2032">2032</option>
											<option value="2033">2033</option>
											<option value="2034">2034</option>
										</select>
									</div>
								</div>
							</div>
						</div>

						<div className="cvv-class form-group d-lg-block d-none my-2 col-2">
							<label htmlFor="cvv" className="card-label cvv-label">
								CVV
							</label>
							<input
								type="number"
								onChange={validateInput}
								onKeyDown={removeSpecial}
								onPaste={(e) => e.preventDefault()}
								onFocus={handleInputFocus}
								name="cvc"
								id="cvv"
								value={formData.cvc}
								className="form-control form-control-lg"
								maxLength="4"
							/>
						</div>
					</div>
					<div className="row d-lg-none d-block">
						<div className="cvv-class form-group my-2 col-12">
							<label htmlFor="cvv" className="card-label cvv-label">
								CVV
							</label>
							<input
								type="number"
								onChange={validateInput}
								onKeyDown={removeSpecial}
								onPaste={(e) => e.preventDefault()}
								onFocus={handleInputFocus}
								name="cvc"
								id="cvv"
								value={formData.cvc}
								className="form-control form-control-lg"
								maxLength="4"
							/>
						</div>
					</div>

					{/* <button
              className="btn btn-primary btn-lg btn-block"
              onClick={submit}
            >
              Submit
            </button> */}
				</form>
			</div>
			<div className="row">
				<div className="col-12">
					<div class="item mt-4">
						<input className="custom-cb" id="cb-1" type="checkbox" name="terms_condition" checked={formData.terms_condition} onChange={handleTermsChange} />
						<label htmlFor="cb-1"> I have read and I agree to the <a href="#" target="_blank" className="tnc_link">Terms and Conditions</a></label>
					</div>
				</div>
			</div>
			<FormFooter action={'payment-method'} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default PaymentDetails
