import {
    GET_USER,
    CREATE_USER,
    DELETE_ALL_USER,
    DELETE_USER,
    GET_PAYMENT_PROFILE,
    GET_USER_KEYS
} from '../actions/UserAction'

const initialState = {
  "paymentProfiles": [],
  "userKeys": [],
}

const UserReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_USER: {
            return [...action.payload]
        }
        case GET_USER_KEYS: {
            return {
              ...state,
              userKeys: action.payload
            }
        }
        case CREATE_USER: {
            return [...action.payload]
        }
        case DELETE_USER: {
            return state.filter((item) => item.id !== action.payload)
        }
        case DELETE_ALL_USER: {
            return [...action.payload]
        }
        case GET_PAYMENT_PROFILE: {
            return {
              ...state,
              paymentProfiles: [ ...action.payload]
            }
        }
        default: {
            return {...state}
        }
    }
}

export default UserReducer
