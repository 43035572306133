import RoundedButton from "components/Buttons/RoundedButton";
import { ExistingNumberContext } from "contexts/FormsContext";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { orderUpdate, updateOrderStepTracking } from "redux/actions/OrderAction";
import Tip from "../../forms/sub-components/widgets/Tip";
import ValidationError from "../ValidationError";

const SimpleNumber = ({ setStep, activeStep, heading, stepKey, placeholder, tipBody, tipQuestion, button }) => {
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	const dispatch = useDispatch();
	const { register, handleSubmit, formState: { errors }, setValue } = useForm({
		defaultValues: {
			'account_number': ExistingNumberData?.account_number_for_this_account_with_phone_business || "",
			'port_out_pin': ExistingNumberData?.provide_the_port_out_pin_number || ""
		}
	});
	const [loading, setLoading] = useState(false)

	const submitHandler = async (form) => {
		setLoading(true)
		const submitterName = window.event.submitter.name;
		const payload = {
			'order_id': ExistingNumberData?.order_id,
			'number_id': ExistingNumberData?.order_did[0]?.id,
			'account_number': form.account_number === "" ? 0 : form.account_number,
			'port_out_pin': form.port_out_pin === "" ? 0 : form.port_out_pin
		}

		if (submitterName == 'provide_later_button') {
			payload['account_number'] = 0;
			payload['port_out_pin'] = 0;
		}

		await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async response => {
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			toast.success("Your Data has been saved", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
			setExistingNumberData({
				...ExistingNumberData,
				'account_number_for_this_account_with_phone_business': form.account_number,
				'provide_the_port_out_pin_number': form.port_out_pin,
				error: [],
				processing: false,
				ready: false
			})
			setStep(activeStep + 1)
		}).catch(exp => {
			console.log(exp)
			setLoading(false)
		})
		setLoading(false)
	}

	useEffect(() => {
		// setExistingNumberData({ ...ExistingNumberData, "error": [], "ready": false })
		if (stepKey === 'account_number_for_this_account_with_phone_business') {
			if (ExistingNumberData.account_number_for_this_account_with_phone_business != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
		}
		if (stepKey === 'provide_the_port_out_pin_number') {
			if (ExistingNumberData.provide_the_port_out_pin_number != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (

		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)} encType="multipart/form-data">
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">{heading}</h2>
					<div className="row">
						<div className="col">
							<div className="order-field account_number">

								{
									(stepKey === 'account_number_for_this_account_with_phone_business') ?

										<input
											// name={stepKey}
											id="account-number"
											// className={(ExistingNumberData.error.includes("invalid-simple-number") ? "error" : "")}
											type="text"
											placeholder={placeholder}
											{...register("account_number", { 'maxLength': { value: 20, message: "Max length is 20" } })}
										/>
										:
										<input
											// name={stepKey}
											id="account-number"
											// className={(ExistingNumberData.error.includes("invalid-simple-number") ? "error" : "")}
											type="text"
											placeholder={placeholder}
											{...register("port_out_pin", { 'maxLength': { value: 12, message: "Max length is 20" } })}
										/>
								}
							</div>
						</div>
					</div>
					{/* <button type="submit" className="provide_later_button">I will provide it later</button> */}
					<Tip tipBody={tipBody} tipQuestion={tipQuestion} stepKey={stepKey} />
					{/* <FormFooter action={'account-modification'} payloadForUpdate={answer} activeStep={activeStep} setStep={setStep} /> */}
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} showLoader={false}>
									Proceed
								</RoundedButton>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default SimpleNumber
