import axios from "../../axios"
export const GET_TOOLTIPS = 'GET_TOOLTIPS'


export const getTooltips = () => (dispatch) => {
	axios.get('tooltips/all').then((res) => {
		dispatch({
			type: GET_TOOLTIPS,
			payload: res.data.result,
		})
	})
}
