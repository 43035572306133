import React from "react";
import { useState } from "react";

const PlanDetails = ({ plan }) => {
  let [tipEnable, setTipEnable] = useState(false)
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a onClick={() => setTipEnable(true)}  class="cursor-pointer">View Plan Details</a>
      {tipEnable &&
        <div className="tipWrapper planDetails">
          <div className="tipModule">
            <div className="tipClose" onClick={() => (setTipEnable(false))}>x</div>
            {
              plan === 1 &&
              <>
                <h2 class="modal-title">DEEP FREEZE</h2>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row" class="hd_feat">One-Time Charge</th>
                      <td class="text-center bg-white">$15 Porting Fee</td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Monthly Minutes*</th>
                      <td class="text-center bg-white">0 Minutes</td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Voicemail</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Call Forwarding</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Outbound Calling</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Text Message Forwarding</th>
                      <td class="text-center bg-white">Optional Addons</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            {
              plan === 2 &&
              <>
                <h2 class="modal-title">VOICEMAIL PLAN</h2>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row" class="hd_feat">One-Time Charge</th>
                      <td class="text-center bg-white">No Porting Fee ($0)</td>

                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Monthly Minutes*</th>
                      <td class="text-center bg-white">100 Minutes</td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Voicemail</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/tick-mark.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Call Forwarding</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Outbound Calling</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Text Message Forwarding</th>
                      <td class="text-center bg-white">Optional Addons</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
            {
              plan === 3 &&
              <>
                <h2 class="modal-title text-center">FORWARDING
                  PLAN</h2>
                <table class="table table-striped">
                  <tbody>
                    <tr>
                      <th scope="row" class="hd_feat">One-Time
                        Charge</th>
                      <td class="text-center bg-white">No Porting
                        Fee ($0)</td>

                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Monthly
                        Minutes*</th>
                      <td class="text-center bg-white">500 Minutes</td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Voicemail</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Call
                        Forwarding</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/tick-mark.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Outbound
                        Calling</th>
                      <td class="text-center bg-white"><img alt='' class="" src="/images/forms/close.png" /></td>
                    </tr>
                    <tr>
                      <th scope="row" class="hd_feat">Text Message
                        Forwarding</th>
                      <td class="text-center bg-white">Optional
                        Addons</td>
                    </tr>
                  </tbody>
                </table>
              </>
            }
          </div>
        </div>
      }
    </>
  )
}
export default PlanDetails
