import { Route, Switch } from "react-router-dom";
import routes from './RootRoutes';
import { HelmetProvider } from "react-helmet-async";
import Admin from "layout/Admin";
import { Store } from "redux/Store";
import { AuthProvider } from "contexts/AuthContext";
import { Provider } from "react-redux";
import AuthGuard from "auth/AuthGuard";
import AppContext from "contexts/AppContext";

import { Suspense } from "react";
import Loading from "components/Loader/Loading";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import authRoutes from "pages/authentication/AuthRoutes";
import formRoutes from "pages/forms/formRoutes";
import { SignupProvider } from "contexts/FormsContext";

const App = () => {

	return (
		<AppContext.Provider value={{ routes }}>
			<ToastContainer draggable limit={2} theme="colored" />
			<Provider store={Store}>
				<HelmetProvider>
					<AuthProvider>
						<SignupProvider>
							<Suspense fallback={<Loading />}>
								<Switch>
									{authRoutes.map((item, i) => (
										<Route
											key={i}
											path={item.path}
											component={item.component}
										/>
									))}
									{formRoutes.map((item, i) => (
										<Route
											key={i}
											path={item.path}
											component={item.component}
										/>
									))}
									<AuthGuard>
										{/* Admin layout */}
										<Admin />
									</AuthGuard>
									{/* </Route> */}
								</Switch>
							</Suspense>

						</SignupProvider>
					</AuthProvider>
				</HelmetProvider >
			</Provider >
		</AppContext.Provider>

	);
};

export default App;
