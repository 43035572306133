import RoundedButton from 'components/Buttons/RoundedButton'
import { ExistingNumberContext } from 'contexts/FormsContext';
import React, { useState } from 'react'
import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateOrderStepTracking, updatePaymentProfile } from 'redux/actions/OrderAction';
import '../assets/css/payment-profile.scoped.css'
import ValidationError from '../ValidationError';
import moment from 'moment';

const ExistingPaymentProfile = ({ setStep, activeStep, toggleStep = () => { } }) => {

	const dispatch = useDispatch()
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const paymentProfileList = useSelector(state => state.payment.creditCardList);
	const [loading, setLoading] = useState(false);

	const { formState: { errors }, handleSubmit, register } = useForm({
		defaultValues: {
			'select_card': ExistingNumberData?.select_card ? ExistingNumberData.select_card : "",
		}
	})

	const addNewClickHandler = (e) => {
		toggleStep("add-new-card")
	}

	const checkExpiry = (year, month) => {
		const ccDate = moment([year, month])
		const today = moment([moment().year(), moment().month()])
		return ccDate.diff(today, 'months') < 0;
	}

	const submitHandler = async (form) => {
		setLoading(true)
		const selectCardProfile = paymentProfileList?.result?.filter((row) => row.authorize_id == form.select_card)[0];
		const payload = {
			'order_id': ExistingNumberData.order_id,
			'payment_profile_id': form.select_card
		}

		await dispatch(updatePaymentProfile(payload, ExistingNumberData.userData.token)).then(async response => {
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			toast.success('Your Data has been saved', { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
			setStep(activeStep + 1)
			setExistingNumberData({
				...ExistingNumberData,
				'name_on_card': selectCardProfile?.card_name,
				'card_number': selectCardProfile?.last_digits,
				'expiry': selectCardProfile?.exp_month,
				'expiryyear': selectCardProfile.exp_year,
				'error': [],
				'ready': false,
				'customerProfileDetails': paymentProfileList?.customerProfileId,
				'paymentProfileIdDetails': form?.payment_profile_id
			})
		}).catch(err => {
			toast.error(err)
			console.log(err)
		})
		setLoading(false)
	}

	return (
		<>
			<ValidationError errors={errors} />
			<form onSubmit={handleSubmit(submitHandler)}>

				<div className="row">
					<div className="col-12">
						<h2 className="lg-heading mb-2">Select Payment Profile</h2>
					</div>

				</div>
				<div className="row">
					<div className="col-12">
						{/* <h4 className="heading my-2">Select a credit card below Or <span onClick={addNewClickHandler}> Add a New Card </span></h4> */}
						<div className='choose_btw_payment mt-4'>
							<div className='left button active'>
								<div className='only_text'>
									Use Existing Cards
								</div>
							</div>
							<div className='or_separator'></div>
							<div onClick={addNewClickHandler} className='right button'>
								<div className='only_text' style={{ marginLeft: "8px" }}>
									Add a New Card
								</div>
							</div>
						</div>
					</div>
				</div>
					<div className='saved_payment_cards'>
				<div className="row mt-3">
					{
						paymentProfileList?.result?.map((profile, index) => {

							const isExpire = checkExpiry(profile?.exp_year, profile?.exp_month)

							const imageURl = `/images/icon-${profile?.card_type?.toLowerCase()}.png`;
							return (
								<div className={`col-lg-6 col-md-6 col-sm-12 my-3 d-flex align-items-center cursor-pointer`} key={index}>
									<input id={"card" + index} type="radio" className='card_radio_btn' value={profile?.authorize_id} {...register('select_card', {
										disabled: isExpire,
										required: "Please select a card"
									})} />
									<label htmlFor={"card" + index} className="d-block justify-content-lg-between">
										<div className='radio_btn_details'>
											<img className='cc_img' src={imageURl} alt="card" width={100} />
											<p className='card_number_digits ml-2'>xxxx-xxxx-xxxx-{profile?.last_digits}<br /><span className='card_number_digits ml-2'>{profile?.exp_month + "/" + profile?.exp_year} <small className='text-danger'>{isExpire ? `(expired)` : ``}</small></span></p>
										</div>
									</label>
								</div>
							)
						})
					}
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						{/* From Footer */}
						<>
							<div className={"footer"} >
								{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
								<div className="d-flex flex-column align-items-center">
									<RoundedButton loading={loading} showLoader={false}>
										Proceed
									</RoundedButton>
									<div className="pe">
										Press Enter
										<img src="/images/forms/icon-enter.png" alt="" />
									</div>
								</div>

							</div>
							{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
							<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
								<img src="/images/forms/icon-prev.png" alt="" />
							</button>
							{/* <a className="next-step" onclick="form.steps('next')"> */}
							<button type="submit" data-title="Next" className="next-step elaboration right">
								<img src="/images/forms/icon-next.png" alt="" />
							</button>

						</>
						{/* From Footer end */}
					</div>
				</div>
			</form>
		</>
	)
}

export default ExistingPaymentProfile
