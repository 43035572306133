/* eslint-disable eqeqeq */
import React, { useContext } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";

const CarrierPill = ({ data, main }) => {

	let { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	const handleChange = (value, name) => {

		if (value != undefined) {
			setExistingNumberData({ ...ExistingNumberData, "carrier": value, "carrier_name": name, error: [], processing: false, ready: true })
		} else {
			setExistingNumberData({ ...ExistingNumberData, "carrier": value, "carrier_name": name, error: [], processing: true, ready: false })
		}
		// setExistingNumberData({ ...ExistingNumberData, "carrier": value, "carrier_name": name })
	}

	return (
		<>
			{
				main ?
					<div className="cat action carrier col-4">
						<label htmlFor={"carrier_option_" + data.id}>
							<input type="radio" className="social_check" value={data.id} name="carrier_option" id={"carrier_option_" + data.id} defaultChecked={data.id == ExistingNumberData.carrier} onChange={(e) => handleChange(e.target.value, data.name)} />
							<span>
								{
									data?.carrier_image ?
										<img width="32" height="32" src={`${process.env.REACT_APP_API_URL}/uploads/carrier-images/${data?.carrier_image}`} alt="Carrier" />
										:
										<img width="32" height="32" src="/images/forms/test-icon.png" alt="Carrier" />
								}
								{data.name}
							</span>
						</label>
					</div>
					:
					<div className="cat action carrier col-4">
						<label htmlFor={"carrier_option_" + data.id}>
							<input type="radio" className="social_check" value={data.id} name="carrier_option" id={"carrier_option_" + data.id} defaultChecked={data.id == ExistingNumberData.carrier} onChange={(e) => handleChange(e.target.value, data.name)} />
							{/* <span><img src="/images/forms/test-icon.png" alt="" />{data.name}</span> */}
							<span>
								{
									data?.carrier_image ?
										<img width="32" height="32" src={`${process.env.REACT_APP_API_URL}/uploads/carrier-images/${data?.carrier_image}`} alt="Carrier" />
										:
										<img width="32" height="32" src="/images/forms/test-icon.png" alt="Carrier" />
								}
								{data.name}
							</span>
						</label>
					</div>
			}
		</>
	)
}

export default CarrierPill
