import React from "react";
import { useState } from "react";

const Tip = ({ tipBody, tipQuestion, setStep, activeStep, stepKey }) => {


	const [tipEnable, setTipEnable] = useState(false)
	const iWillProvideLaterArray = ['upload_copy_of_latest_phone_bill', 'provide_the_port_out_pin_number', 'account_number_for_this_account_with_phone_business']
	return (
		<>
			{tipQuestion &&
				<span className="generic-help-link my-3" onClick={() => setTipEnable(true)}>
					<img src="/images/forms/about.png" alt="" width="40" height="40" />
					{tipQuestion}
				</span>
			}
			{
				tipBody !== "" && (
					tipEnable &&
					<div className="tipWrapper">
						<div className="tipModule">
							<div className="tipClose" onClick={() => setTipEnable(false)}>x</div>
							<div dangerouslySetInnerHTML={{ __html: tipBody }} >

							</div>
							{
								(iWillProvideLaterArray.includes(stepKey)) && (

									stepKey == 'upload_copy_of_latest_phone_bill' ?
										<>
											<button
												type="button"
												onClick={() => setStep(activeStep + 1)}
												name="provide_later_button"
												className="provide_later_button"
											>
												I will provide it later
											</button>
										</>
										:
										<>
											<button
												type="submit"
												name="provide_later_button"
												className="provide_later_button"
											>
												I will provide it later
											</button>
										</>
								)

							}
						</div>
					</div>
				)
			}
		</>
	)
}
export default Tip
