import axios from "../../axios"

export const GET_USER = 'GET_USER'
export const CREATE_USER = 'CREATE_USER'
export const DELETE_USER = 'DELETE_USER'
export const DELETE_ALL_USER = 'DELETE_ALL_USER'
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD'
export const CREATE_PAYMENT_PROFILE = 'CREATE_PAYMENT_PROFILE'
export const GET_PAYMENT_PROFILE = 'GET_PAYMENT_PROFILE'
export const REMOVE_PAYMENT_PROFILE = 'REMOVE_PAYMENT_PROFILE'
export const UPDATE_PAYMENT_PROFILE = 'UPDATE_PAYMENT_PROFILE'
export const GET_USER_KEYS = "GET_USER_KEYS"

export const getUsers = () => (dispatch) => {
  axios.post('user/all').then((res) => {
    dispatch({
      type: GET_USER,
      payload: res.data.result,
    })
  })
}

export const deleteUser = (id) => (dispatch) => {
  return axios.post('user/delete', { id }).then((res) => {
    dispatch({
      type: DELETE_USER,
      payload: id,
    })
    return res.data;

  })
}

export const deleteAllUser = () => (dispatch) => {
  axios.post('/api/user/delete-all').then((res) => {
    dispatch({
      type: DELETE_ALL_USER,
      payload: res.data,
    })
  })
}

export const createUser = (user) => (dispatch) => {
  return axios.post('/user/create-account', { user }).then((res) => {
    dispatch({
      type: CREATE_USER,
      payload: res.data,
    })
    return res;
  })
}

export const updateUser = (user, token = null) => async () => {
  if (token) {
    return axios.post('user/update', user, { headers: { 'Authorization': `Bearer ${token}` } }).then((res) => {
      return res.data;
    })
  } else {
    return axios.post('user/update', user).then((res) => {
      return res.data;
    })
  }
}

export const changePassword = (user) => async () => {
  return axios.post('update-password', user).then((res) => {
    return res.data;
  })
}

export const addPaymentProfile = (user) => (dispatch) => {
  return axios.post('user/customer-payment-profile', user).then((res) => {
    return res.data;
  })
}
export const removePaymentProfile = (user) => (dispatch) => {
  return axios.post('user/remove-customer-payment-profile', user).then((res) => {
    return res.data;
  })
}
export const updatePaymentProfile = (user) => (dispatch) => {
  return axios.post('user/update-customer-payment-profile', user).then((res) => {
    return res.data;
  })
}
export const setCardAsDefaultPaymentProfile = (id, user_id) => (dispatch) => {
  return axios.post('user/default-customer-payment-profile', { id: id, user_id: user_id }).then((res) => {
    return res.data;
  })
}
export const getPaymentProfile = (user) => (dispatch) => {
  return axios.get('user/get-customer-payment-profile', user).then((res) => {
    dispatch({
      type: GET_PAYMENT_PROFILE,
      customerProfileId: res.data.result.customerProfileId,
      futurePayload: res.data.result.futureResult,
      payload: res.data.result.result
    })
    return res.data;
  })
}

export const checkValidateViaAPI = (data) => async () => {
  return axios.post('user-validator', data).then((res) => {
    return res.data;
  })
}
export const accountCompletionStatus = (data) => async () => {
  return axios.get('user/account-completion').then((res) => {
    return res.data;
  })
}

export const getUserKeys = (data) => async (dispatch) => {
  return axios.get('user/get-user-keys').then((res) => {
    dispatch({
      type: GET_USER_KEYS,
      payload: res.data,
    })
    return res.data;
  })
}
export const genrateAccountPin = () => async (dispatch) => {
  return axios.get('user/generate-account-pin').then((res) => {
    // dispatch({
    //   payload: res.data,
    // })
    return res.data;
  })
}


