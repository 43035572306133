import React, { useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ExistingNumberContext } from "contexts/FormsContext";
import { formatPhone } from "general/FormatNumber";
import { useDispatch } from "react-redux";
import { getAddOns, sendOrderEmailAction, updateOrderStepTracking } from "redux/actions/OrderAction";
import { useState } from "react";
import { useEffect } from "react";
const OrderSummary = ({ setStep, activeStep }) => {

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const [addOns, setAddOns] = useState([])
	const { ExistingNumberData } = useContext(ExistingNumberContext);
	var price = 0;
	let mainPlan = ExistingNumberData?.plan_details;
	let _activeAddOns = ExistingNumberData?.active_add_ons?.map(addOnVal => {
		return {
			name: addOnVal,
			price: addOns.filter(addOnFromAPI => addOnFromAPI.title === addOnVal)[0]?.price
		}
	})
	const _activeAddOnsTotal = _activeAddOns.reduce((accumulator, object) => {
		return parseInt(accumulator) + parseInt(object.price)
	}, 0);

	let mainAdditionalPlan = {
		'Porting_price': parseInt(mainPlan?.porting_price),
		'Shipping_price': parseInt(mainPlan?.shipping_price),
		'Taxes_price': parseInt(mainPlan?.taxes_price),
		'Equipment_price': parseInt(mainPlan?.equipment_price),
		'Activation_price': parseInt(mainPlan?.activation_price),
	}

	price += parseInt(ExistingNumberData?.plan_details?.price) + _activeAddOnsTotal + Object.values(mainAdditionalPlan).reduce((a, b) => a + b)

	const sendOrderEmail = async () => {
		setLoading(true)
		await dispatch(sendOrderEmailAction(ExistingNumberData?.order_id, ExistingNumberData.userData.token)).then(async resp => {
			console.log(resp)
			await dispatch(updateOrderStepTracking({
				'order_id': ExistingNumberData.order_id,
				'step_id': activeStep,
				'state_body': ExistingNumberData,
				'order_completed': 1,
			}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
			setLoading(false)
			setStep(activeStep + 1)
		})

	}

	useEffect(() => {
		const fetchData = async () => {
			await dispatch(getAddOns()).then(response => {
				setAddOns(response.result)
			})
		}
		fetchData()
	}, [])
	return (
		<div className="step px-0">
			<h5 className="mb-2 sm-heading text-center">CONGRATULATIONS ON COMPLETING YOUR ORDER</h5>
			<h2 className="lg-heading text-center text-uppercase mb-3">Order Summary</h2>
			<div className="order_summary">
				<div className="row px-4">
					<div className="col-md-4 col-12 pt-3">
						<div className="order_summary_left">
							<div className="px-4">
								<div className="row">
									<div className="col-10">
										<h4><b>Account Details:</b></h4>
										<p className="fw-normal fs-5 m-0"><b>Name:</b> {ExistingNumberData?.authorized_first_name} {ExistingNumberData?.authorized_last_name}</p>
										<p className="fw-normal fs-5 mb-0"><b>Address:</b> {ExistingNumberData?.service_address_where_the_phone_physically_installed?.street_address ?? ''}</p>
										{
											ExistingNumberData?.account_number_for_this_account_with_phone_business ??
											<p className="fw-normal fs-5 mb-3">Account: {ExistingNumberData?.account_number_for_this_account_with_phone_business}</p>
										}
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change account Details" onClick={() => setStep(activeStep = 15)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col-10">
										<h4><b>Your Plan Details:</b></h4>
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change Plan Details" onClick={() => setStep(activeStep = 17)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<h4 className="orange">{ExistingNumberData?.plan_details.title}</h4>
								<p className="fw-normal fs-6 mb-0">Duration: <span className="blue">{ExistingNumberData?.plan_details?.period} Months</span>
									<i className="fa fa-check orange" aria-hidden="true"></i>&nbsp;
								</p>
								<p className="fw-normal fs-6 mb-0">AddOns: <span className="blue">{ExistingNumberData?.active_add_ons.join(", ")}</span>
									{/* <i className="fa fa-check orange" aria-hidden="true"></i>&nbsp; */}
								</p>
							</div>

							<div className="p-4">
								<hr />
								<div className="row">
									<div className="col-10">

										<h4><b>Payment Details:</b></h4>
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change Payment Details" onClick={() => setStep(activeStep = 21)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<div className="col-12">
									<p className="fw-normal fs-5 mb-0">Payment Method: Credit Card</p>
									<div className="d-flex my-2 align-items-center">
										<img src="/images/credit-cards/credit-card.png" className="img-fluid col-2" alt="" />&nbsp;
										<h4><span className="orange">**** **** **** {ExistingNumberData?.card_number?.slice(-4)}</span></h4>
									</div>
									{
										ExistingNumberData?.name_on_card &&
										<p className="fw-normal fs-6 mb-0">Name: <span className="blue">{ExistingNumberData?.name_on_card}</span>
										</p>
									}
									<p className="fw-normal fs-6 mb-0">
										Expiry:

										<span className="blue">{ExistingNumberData?.expiry}/{ExistingNumberData?.expiryyear?.toString().slice(-2)}</span>
										{/* <i className="fa fa-check orange" aria-hidden="true"></i>&nbsp; */}
									</p>
								</div>
							</div>

						</div>
					</div>
					<div className="col-md-8 col-12 aligns-items-center justify-content-center pt-3 pe-4">

						<h4 className="text-center"><b>Invoice</b></h4>
						<div className="order_summary_right">
							<div className="phone_number_summary">
								<Table striped>
									<thead>
										<tr>
											<th>Items</th>
											<th className="text-left">Duration</th>
											<th className="text-end">Price</th>
										</tr>
									</thead>
									<tbody >

										<tr>
											<td className="summary_number_details ">
												<div className="country-icon"><img alt="" src="/images/forms/usaicon.png" className="img-fluid" /> </div>
												<div className="ms-3">
													<div className="country-number ">{ExistingNumberData?.first_number?.phoneNumber} </div>
													<div className="micro package_name"><b>Plan </b>- {ExistingNumberData?.plan_details?.title}</div>
													{
														ExistingNumberData?.active_add_ons?.length ? (
															<div className="micro package_name"><b>Add ons </b>- {ExistingNumberData?.active_add_ons.join(", ")}</div>
														) : ""
													}
													<div className="micro package_name"><b>Additional Charges </b>- {Object.keys(mainAdditionalPlan).filter(ro => mainAdditionalPlan[ro] !== 0).map(row => row.replace("_", " ")).join(", ", "")}</div>

													{/* <div className="micro package_name">- {values.addOns.join(", ")}</div> */}
												</div>
											</td>
											<td className="text-left">
												<div className="text-left">{ExistingNumberData?.plan_details?.category_name}</div>
												<div className="extra_price">
													<div className="micro text-left">
														{ExistingNumberData?.plan_details?.period}
														{" "}
														{ExistingNumberData?.plan_details?.period_type}

													</div>
													{/* <div className="micro text-end">15 days</div> */}
												</div>
											</td>
											<td>
												<div className="text-end">${ExistingNumberData?.plan_details?.price}</div>
												{/* <div className="text-end">$10.00</div> */}
												<div className="extra_price">
													<br />
													{
														_activeAddOnsTotal > 0 ?
															<>
																<div className="micro text-end">${_activeAddOnsTotal}</div>
															</>
															:
															""

													}
													<div className="micro text-end">${Object.values(mainAdditionalPlan).reduce((a, b) => a + b)}</div>
												</div>
											</td>
										</tr>


										{
											ExistingNumberData?.more_numbers_plans ? ExistingNumberData?.more_numbers_plans?.map((values, index) => {
												let planRateRow = values.rateList.find(row => row.id == values.planRateId)

												let _activeAddOnsNN = values.addOns?.map(addOnVal => {
													return {
														name: addOnVal,
														price: addOns.filter(addOnFromAPI => addOnFromAPI.title === addOnVal)[0]?.price
													}
												})

												const _activeAddOnsNNTotal = _activeAddOnsNN.reduce((accumulator, object) => {
													return parseInt(accumulator) + parseInt(object.price)
												}, 0);
												const additionalCharges = {
													'Porting_price': parseInt(planRateRow?.porting_price),
													'Shipping_price': parseInt(planRateRow?.shipping_price),
													'Taxes_price': parseInt(planRateRow?.taxes_price),
													'Equipment_price': parseInt(planRateRow?.equipment_price),
													'Activation_price': parseInt(planRateRow?.activation_price),
												}
												price += parseInt(planRateRow?.price) + _activeAddOnsNNTotal + Object.values(additionalCharges).reduce((a, b) => a + b)

												return (
													<tr>
														<td className="summary_number_details ">
															<div className="country-icon"><img alt="" src="/images/forms/usaicon.png" className="img-fluid" /> </div>
															<div className="ms-3">
																<div className="country-number ">{formatPhone(values.number)} </div>
																<div className="micro package_name"><b>Plan</b>- {values.rateList.find(row => row.id == values.planRateId)?.title}</div>
																{/* <div className="micro package_name">- {values.planList.filter(row => row.id == values.planId)[0].plans_rates.filter(row => row.id == values.planRateId)[0].}</div> */}
																<div className="micro package_name"><b>Add Ons</b>- {values.addOns.join(", ")}</div>

																<div className="micro package_name"><b>Additional Charges</b> - {Object.keys(additionalCharges).filter(ro => additionalCharges[ro] !== 0).map(row => row.replace("_", " ")).join(", ", "")}</div>
															</div>
														</td>
														<td className="text-left">
															<div className="text-left">{values.rateList.find(row => row.id == values.planRateId)?.category_name}</div>
															<div className="extra_price">
																<div className="micro text-left">
																	{planRateRow?.period}
																	{" "}
																	{planRateRow?.period_type}
																</div>
																{/* <div className="micro text-end">15 days</div> */}
															</div>
														</td>
														<td>
															<div className="text-end">${planRateRow.price}</div>
															{/* <div className="text-end">$10.00</div> */}
															<div className="extra_price">
																<div className="micro text-end">${_activeAddOnsNNTotal}</div>
																<div className="micro text-end">${Object.values(additionalCharges).reduce((a, b) => a + b)}</div>
															</div>
														</td>
													</tr>
												)

											}) : ''

										}


									</tbody>
									<tfoot>

										<tr className="border">
											<td colspan="2">Subtotal</td>
											<td className="text-end">${price}</td>
										</tr>
										<tr className="border">
											<td colspan="2" className="fw-bold">Total</td>
											<td className="fw-bold orange text-end">${parseFloat(price).toFixed(2)}</td>
										</tr>
									</tfoot>
								</Table>
							</div>
							<div className="row">
								<div className="col">
									<button type="button" disabled={loading} className="order-summary-dashboard-btn btn-lg my-3 mx-auto" onClick={() => { sendOrderEmail() }}>
										{loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											"Process My Order"
										}


									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrderSummary
