import React from 'react'
import { useState } from 'react';
import { Carousel } from 'react-bootstrap';

const PlanCarousel = ({ items }) => {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect}>
            {items.map((value, index) => {
                return (
                    <Carousel.Item>
                        {value}
                    </Carousel.Item>
                )
            })}
        </Carousel>
    );
}

export default PlanCarousel