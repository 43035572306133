import React from 'react'
const servicesRoutes = [
    {
        path: '/customer/services',
        component: React.lazy(() => import('./Index')),
        auth: ['admin', 'User'],
    }
]

export default servicesRoutes
