import React from "react";
import FormFooter from "../widgets/FormFooter";

const Goals = ({ setStep, activeStep }) => {
  return (
    <div className="step">
      <h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
      <h2 className="lg-heading">Tell us what you are looking to acheive: <span className="text-secondary">(Optional)</span></h2>
      <div className="row mb-5">
        <div className="col">
          <div class="item">
            <input class="custom-cb" id="preference_one" type="checkbox" value="value1" />
              <label for="preference_one">I am traveling and want to keep my phone number.</label>
          </div>
        </div>
      </div>
      <div className="row mb-5">
        <div className="col">
          <div class="item">
            <input class="custom-cb" id="preference_two" type="checkbox" value="value1" />
              <label for="preference_two">I am moving to an area where my current provider cannot provide service.</label>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col">
          <div class="item">
            <input class="custom-cb" id="preference_three" type="checkbox" value="value1" />
              <label for="preference_three">My elderly parents are moving to a home and want to keep their phone number.</label>
          </div>
        </div>
      </div>
      <FormFooter activeStep={activeStep} setStep={setStep} final={true} />
    </div>
  )
}

export default Goals
