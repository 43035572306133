import React from 'react'
import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const TermsAndCondition = ({ show = false, setShow = () => { } }) => {

	return (
		<Modal
			show={show}
			size="xl"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header>
				<h2>
					Family Phone Terms and Conditions
				</h2>
			</Modal.Header>
			<Modal.Body>
				<p>
					BY SUBMITTING OUR SIGN UP FORM YOU CONFIRM THAT YOU HAVE READ AND AGREED TO THE TERMS AND CONDITIONS OF THE SERVICE.
				</p>
				<ul>
					<li>Your credit card account will be charged periodically, according to the service and payment terms you selected.</li>
					<li>Accounts with a monthly charge of less than $10, will be charged every 3 months.</li>

					<li>All monthly accounts will be prorated in case of cancellation.</li>
					<li>Discounted prepaid accounts for 3, 6 or 12 months are non refundable.</li>
					<li>Payment for a prepaid account will resume at the end of the pre paid term as a monthly service for the current monthly rate, unless you notify us that you want to change the plan.</li>
					<li>Taxes may be charged where applicable by law.</li>
					<li>Porting your number away is allowed <strong>only after 30 days</strong>. If you port your phone number away from us, before the end of the 30-day-period from the date of the actual porting of your number to us, you agree to compensate us for the penalty we will incur. This action is called 'snapback' and is not allowed. A high penalty may be imposed if this happens. There is no penalty when porting the number away after the 30-day-period.</li>

					<li>Porting in and porting out your number will take about 2-3 weeks on average, however the porting time frame is never guaranteed.</li>
					<li>You understand that you cannot disconnect your number or place orders for different services/disconnect request on the account during the time of the porting process. In this case our porting request will be rejected and the porting fee won't be refunded</li>
					<li>You agree that charges on your account will continue until you actually notify us that your number was ported away. You will keep on paying for your services, even if the number was actually ported away from us, until you notify us.</li>
					<li>You agree to pay a minimum, for the first 6 months of the service, even if you port the number away and notify us. The 6 months is a minimum payment for the service, in addition to porting or setup fee.</li>
				</ul>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={setShow}>Close</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default TermsAndCondition
