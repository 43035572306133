/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import SimplePhone from './order-components/SimplePhone'
import SimpleQuestion from './order-components/SimpleQuestion'
import Carrier from './order-components/Carrier'
import Verification from './order-components/Verification'
import SimpleNumber from './order-components/SimpleNumber'
import AddingNumbers from './order-components/AddingNumbers'
import React, { useContext } from 'react'
import { ExistingNumberContext } from 'contexts/FormsContext'
import Success from './order-components/Success'
import FileUpload from './order-components/FileUpload'
import PortTime from './order-components/PortTime'
import BusinessName from './order-components/BusinessName'
import ServiceAddress from './order-components/ServiceAddress'
import AddOns from './order-components/AddOns'
import AddonMoreNumber from './order-components/SelectNumberPackages'
import Signature from './order-components/Signature'
import './assets/css/order.css'
import './assets/css/general.css'
import OrderSummary from './order-components/OrderSummary'
import PaymentDetails from './order-components/PaymentDetails'
import PublicUserInfo from 'pages/order/order-components/PublicUserInfo'
import { useEffect } from 'react'
import PlanOptions from 'pages/order/order-components/PlanOptions'
import { useState } from 'react'
import FirstPhone from 'pages/order/order-components/FirstPhone'
import { useDispatch, useSelector } from 'react-redux'
import { getTooltips } from 'redux/actions/TooltipsAction'
import PlansCategory from './order-components/PlansCategory'

const ExistingNumber = ({ activeStep, setStep, prevStep, customInitialState }) => {
	const dispatch = useDispatch()
	const allToolTips = useSelector(state => state.tooltips);
	let { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [planOption, setPlanOption] = useState({ open: false, selectedPlan: {}, planList: [] });
	const titleCase = (s) => s?.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())

	const filterToolTipByStep = (key) => {
		const abcd = allToolTips.filter(row => {
			return row?.get_tooltip_step_id?.step_code == key
		})[0]
		return abcd?.tip_body ?? ""
	}

	useEffect(() => {
		if (customInitialState && Object.keys(customInitialState).length > 0) {
			setExistingNumberData(customInitialState)
		}
		if (allToolTips.length == 0) {
			dispatch(getTooltips())
		}
		if (ExistingNumberData.order_id != "") {

			const handler = event => {
				event.preventDefault();
				event.returnValue = '';
			};
			window.addEventListener('beforeunload', handler);
		}
		return () => {
			const handler = event => {
				event.preventDefault();
				event.returnValue = '';
			};
			window.removeEventListener("beforeunload", handler);
		};
	}, [ExistingNumberData, customInitialState])

	return (
		<>
			{!ExistingNumberData?.userData ? //will change after auth
				activeStep === 1 &&
				<PublicUserInfo activeStep={activeStep} setStep={setStep} />
				:
				activeStep === 1 &&
				<FirstPhone activeStep={activeStep} setStep={setStep}
					tipQuestion=""
					tipBody=""
				/>

			}
			{activeStep === 2 &&
				<SimpleQuestion activeStep={activeStep} setStep={setStep}
					heading="Is this a mobile number ?"
					stepKey="is_this_a_mobile_number"
					option1="YES"
					option2="NO"
				/>
			}
			{activeStep === 3 &&
				<Verification activeStep={activeStep} setStep={setStep} prevStep={prevStep} />
			}

			{activeStep === 4 &&
				<SimpleQuestion activeStep={activeStep} setStep={setStep} prevStep={prevStep}
					heading="Would you like to keep the number as a mobile phone number for quicker porting ?"
					stepKey="keep_the_number_as_a_mobile_phone_number"
					option1="YES"
					option2="NO"
					tipQuestion="How is this helpful ?"
					tipBody={filterToolTipByStep('keep_the_number_as_a_mobile_phone_number')}
				/>
			}
			{activeStep === 5 &&
				<SimpleQuestion activeStep={activeStep} setStep={setStep}
					heading="Is this number being used mainly as a fax number? Or do you plan on using it mainly to send or receive faxes?"
					stepKey="use_number_only_to_send_receive_faxes"
					option1="YES"
					option2="NO"
					tipQuestion="How is this helpful ?"
					// tipBody="You cannot keep fax number as a mobile number. So if you previously have selected 'Yes' in mobile number question kindly select 'No'."
					tipBody={filterToolTipByStep('use_number_only_to_send_receive_faxes')}
				/>
			}
			{activeStep === 6 &&
				<Carrier activeStep={activeStep} setStep={setStep} />
			}
			{activeStep === 7 &&
				<SimpleNumber activeStep={activeStep} setStep={setStep}
					heading={`What is the account number for this account with ${ExistingNumberData.carrier_name ? titleCase(ExistingNumberData.carrier_name) : ``} ?`}
					stepKey="account_number_for_this_account_with_phone_business"
					placeholder="12345 XYZ"
					tipQuestion="Where can i find the account number ?"
					tipBody={filterToolTipByStep('account_number_for_this_account_with_phone_business')}
				/>
			}
			{activeStep === 8 &&

				<SimpleQuestion activeStep={activeStep} setStep={setStep} prevStep={prevStep}
					heading={`Is ${ExistingNumberData?.first_number?.phoneNumber || "this"} the main number (aka BTN) on the  ${ExistingNumberData.carrier_name ? titleCase(ExistingNumberData.carrier_name) : ``} account ?`}
					stepKey="is_this_the_main_number_on_the_business_account"
					option1="YES"
					option2="NO"
					tipQuestion="Why is this needed ?"
					tipBody={filterToolTipByStep('is_this_the_main_number_on_the_business_account')}
				/>
			}
			{activeStep === 9 &&
				<SimplePhone activeStep={activeStep} setStep={setStep} prevStep={prevStep}
					heading="Please specify the main number (aka BTN) on the business account"
					stepKey="please_specify_the_main_number_on_the_business_account"
					placeholder="Enter Phone Number"
					tipQuestion="Where can i find the main number ?"
					tipBody={filterToolTipByStep('please_specify_the_main_number_on_the_business_account')}
				/>
			}
			{/* {activeStep === 10 &&
        <SimpleQuestion activeStep={activeStep} setStep={setStep}
          heading="Does this account belong to the company ?"
          stepKey="does_this_account_belong_to_the_company"
          option1="YES"
          option2="NO"
        />
      } */}
			{activeStep === 10 &&
				<SimpleQuestion activeStep={activeStep} setStep={setStep}
					heading={`Do you want to add more number? (for the same account with ${titleCase(ExistingNumberData.carrier_name)})`}
					stepKey="are_there_any_more_number_that_you_want_to_port"
					option1="YES"
					option2="NO"
				/>
			}
			{activeStep === 11 &&
				<AddingNumbers activeStep={activeStep} setStep={setStep} prevStep={prevStep}
					heading="Please enter the number(s) you want to port."
					stepKey="how_many_number_you_have_on_the_account"
					placeholder="Enter Phone Number"
				/>
			}
			{activeStep === 12 &&
				<SimpleNumber activeStep={activeStep} setStep={setStep}
					heading={`Please provide the port out pin for your account with ${titleCase(ExistingNumberData.carrier_name)}`}
					stepKey="provide_the_port_out_pin_number"
					placeholder="12345"
					tipQuestion="Your order is saved, you can safely take your time to locate the port our pin number and input it back here"
					tipBody={filterToolTipByStep('provide_the_port_out_pin_number')}
				/>
			}
			{activeStep === 13 &&
				<FileUpload activeStep={activeStep} setStep={setStep}
					tipQuestion="What format do we prefer ?"
					stepKey="upload_copy_of_latest_phone_bill"
					// tipBody="Although for ease we have enabled pdf and image formats (jpg, png, jpeg, etc). We prefer pdf format as it is future proof for scraping."
					tipBody={filterToolTipByStep('upload_copy_of_latest_phone_bill')}
				/>
			}
			{activeStep === 14 &&
				<PortTime activeStep={activeStep} setStep={setStep}
					stepKey="when_do_you_want_port_the_number"
				/>
			}
			{activeStep === 15 &&
				<BusinessName activeStep={activeStep} setStep={setStep}
					stepKey="your_business_name_on_the_account"
				/>
			}
			{activeStep === 16 &&
				<ServiceAddress activeStep={activeStep} setStep={setStep}
					tipQuestion={"Need Some Help ?"}
					stepKey="service_address_where_the_phone_physically_installed"
					// tipBody={'In "Service Street Address" field do not try to enter your PO BOX address. Only enter your physical address.'}
					tipBody={filterToolTipByStep('service_address_where_the_phone_physically_installed')}
				/>
			}
			{(activeStep === 17) ?
				(planOption.open) ?
					<PlanOptions
						heading="Choose an option for the plan"
						activeStep={activeStep} setStep={setStep} prevStep={prevStep}
						stepKey="add_more_packages"
						selectedPlan={planOption.selectedPlan}
						plans={planOption.planList}
						setPlanOpen={setPlanOption}
					/>
					:
					<PlansCategory activeStep={activeStep} setStep={setStep}
						stepKey="plan_existing_number" setPlanOption={setPlanOption}
					/>
				: <></>
			}
			{activeStep === 18 &&
				<AddonMoreNumber activeStep={activeStep} setStep={setStep} prevStep={prevStep}
					stepKey="add_more_packages"
				/>
			}
			{activeStep === 19 &&
				<AddOns activeStep={activeStep} setStep={setStep}
					stepKey="add_ons"
				/>
			}
			{activeStep === 20 &&
				<Signature activeStep={activeStep} setStep={setStep}
					stepKey="signature"
				/>
			}
			{/* {activeStep === 21 &&
        <TermsAndConditions activeStep={activeStep} setStep={setStep} />
      } */}
			{activeStep === 21 &&
				<PaymentDetails activeStep={activeStep} setStep={setStep}
					stepKey="provide_payment_details"
				/>
			}
			{activeStep === 22 &&
				<OrderSummary activeStep={activeStep} setStep={setStep}
					stepKey="order_summery"
				/>
			}
			{activeStep === 23 &&
				<Success stepKey="success_message" />
			}
			{ExistingNumberData.error.length != 0 &&
				<div className="crossmark-wrapper">
					<span className="message">
						{(ExistingNumberData.error[0] == "first_name") ? "cannot proceed without filling first name"
							: (ExistingNumberData.error[0] == "last_name") ? "cannot proceed without filling last name"
								: (ExistingNumberData.error[0] == "business_name") ? "cannot proceed without filling Business name"
									: (ExistingNumberData.error[0] == "service_state") ? "cannot proceed without selecting State"
										: (ExistingNumberData.error[0] == "service_address") ? "cannot proceed without filling Service Address"
											: (ExistingNumberData.error[0] == "service_city") ? "cannot proceed without filling City"
												: (ExistingNumberData.error[0] == "service_zip") ? "cannot proceed without filling Zip Code"
													: (ExistingNumberData.error[0] == "service_country_id") ? "cannot proceed without selecting Country"
														: (ExistingNumberData.error[0] == "confirm_signature") ? "cannot proceed without clicking check"
															: (ExistingNumberData.error[0] == "card_number") ? "card number is required minimum 12"
																: (ExistingNumberData.error[0] == "phone_number") ? "Phone number is required minimum 11"
																	: (ExistingNumberData.error[0] == "invalid_phone") ? "Phone number is Invalid"
																		: (ExistingNumberData.error[0] == "same_number") ? "this number is already added"
																			: "cannot proceed without filling up data"}

						{/* {ExistingNumberData.error.length} */}
						{/* {ExistingNumberData.error == "initial-fname" && "Fill the required fields"} */}
						{/* {ExistingNumberData.error == "initial-lname" && "Last Name is Required"}
            {ExistingNumberDa																	ta.error == "initial-email" && "Email is Required"} */}
					</span>
					<svg className="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
						<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
						<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
					</svg>
				</div>
			}
			{
				ExistingNumberData.ready &&
				// <></>
				<div className="checkmark-wrapper">
					<span className="message">Ready To Go</span>
					<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
						<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
					</svg>
				</div>
			}
			{
				ExistingNumberData.processing &&
				<></>
				// <div className="circlemark-wrapper">
				// 	<span className="message">Processing the form please wait</span>
				// 	<svg className="circlemark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
				// 		<circle className="circlemark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
				// 		<circle className="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3"></circle>
				// 	</svg>
				// </div>
			}
			{
				ExistingNumberData.planChange &&
				<div className={(ExistingNumberData?.ready || ExistingNumberData?.error[0]) ? "changePlan is_change_plan" : "changePlan"} onClick={() => setStep(activeStep = 17)} >
					<a title="Change Plan"><i className="fa fa-edit"></i>
						Plan: {ExistingNumberData?.plan_details ? ExistingNumberData?.plan_details?.title : ""}
					</a>
				</div>
			}
		</>
	)
}

export default ExistingNumber
