import { SignupContext } from 'contexts/FormsContext';
import { generatePassword } from 'general/GeneralHelper';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';


const SocialLogin = () => {

	const params = useParams();
	const history = useHistory();
	const { search } = useLocation();
	const { socialLoginCallback } = useAuth();
	const [loading, setLoading] = useState(false)
	const _action = localStorage.getItem('socialAction');
	const { signupData, setSignupData } = useContext(SignupContext)

	useEffect(() => {
		const handeSocialClick = async (provider, search) => {
			setLoading(true)
			try {
				await socialLoginCallback(provider, _action, search).then(response => {
					if (_action === "login") {
						history.push('/customer/dashboard')
					}

					if (response && response.bool) {

						if (provider == "azure") {
							const userData = response?.result;
							const name = userData?.name.split(" ");

							setSignupData({
								...signupData,
								'first_name': name[0],
								'last_name': name[1],
								'email': userData?.mail,
								'confirm_email': userData?.mail,
								'activeSocialFormat': provider,
								'password': generatePassword(8),
								"socialMedia": response?.result
							})

						} else {
							const userData = response?.result?.user
							const name = userData?.name.split(" ");
							setSignupData({
								...signupData,
								'first_name': name[0],
								'last_name': name[1],
								'email': userData?.email,
								'confirm_email': userData?.email,
								'activeSocialFormat': provider,
								'password': generatePassword(8),
								"socialMedia": response?.result
							})
						}

						history.push('/signup/phone-verification')
					} else {
						setLoading(false)
						history.replace('/login')
					}

				})
			} catch (e) {
				setLoading(false)
				toast.error(e.message)
				history.replace('/login')
			}
		}
		handeSocialClick(params.provider, search)
	}, [])

	// useEffect(() => {
	// 	const handeSocialClick = async (provider, search) => {
	// 		setLoading(true)
	// 		try {
	// 			await socialLoginCallback(provider, _action, search).then(response => {
	// 				console.log("sharique here");
	// 				localStorage.setItem('signupResponse', JSON.stringify(response))
	// 			})
	// 			if (_action === "login") {

	// 				history.push('/customer/dashboard')
	// 			}

	// 		} catch (e) {

	// 			setLoading(false)

	// 			toast.error(e.message)
	// 			history.push('/login')
	// 		}
	// 	}
	// 	handeSocialClick(params.provider, search)
	// 	return () => localStorage.removeItem('socialAction')
	// 	// eslint-disable-next-line
	// }, [])

	// console.log(useAuth())
	return (
		<Container className='min-vh-100'>
			<Row>
				<Col className='mt-5 pt-5'>
					<Card className="text-center p-5 shadow">
						<div className="d-flex justify-content-center">
							{loading &&
								<div className="spinner-grow text-primary mb-5 text-dark" role="status">
									<span className="visually-hidden">Loading...</span>
								</div>
							}

						</div>

						<h2 className='align-middle'>Please Wait</h2>
						<p>System will automatically redirect you...</p>
					</Card>
				</Col>
			</Row>
		</Container>
	)
}

export default SocialLogin
