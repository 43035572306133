import React from 'react'
import { Helmet } from 'react-helmet-async';

const Title = ({title = "Document" }) => {

    return (
        <div>

            <Helmet>
                <title>{title} | Park My Phone</title>
            </Helmet>

        </div>
    );
}

export default Title
