import axios from "axios";

export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';

export const getServices = (id, params = {}) => async (dispatch) => {

	let config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${process.env.REACT_APP_TELECOM_URL}/api/v1/cust_log/all/${id}`,
		params: params,
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
		}
	};

	axios.request(config)
		.then((response) => {
			dispatch({
				type: GET_SERVICES,
				payload: response.data?.result
				// payload: sampleResponse.result
			});
		})
		.catch((error) => {
			console.log(error);
		});
}
export const getServiceTypes = () => async (dispatch) => {

	let config = {
		method: 'get',
		maxBodyLength: Infinity,
		url: `${process.env.REACT_APP_API_URL}/api/my-services/get-types`,
		headers: {
			'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
		}
	};

	axios.request(config)
		.then((response) => {
			dispatch({
				type: GET_SERVICE_TYPES,
				payload: response.data.result
			});
		})
		.catch((error) => {
			console.log(error);
		});
}

export const softDeleteService = (id) => async () => {
	return axios.delete(`my-services/delete-log/${id}`).then(resp => {
		return resp.data;
	})
}
