import Echo from "laravel-echo";
import Pusher from "pusher-js";



window.Pusher = Pusher;

const echo = new Echo({
	broadcaster: 'pusher',
	key: '34f703af07054e52992a',
	cluster: 'us2',
	forceTLS: false
});

export default echo;
