/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";

const NumberSidebarData = ({ setStep, activeStep, activeFunnel }) => {
	let { ExistingNumberData } = useContext(ExistingNumberContext)
	const titleCase = (s) => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
	return (
		<>
			{activeFunnel === 0 ?
				<div className="sidebarData">
					<p>Preference: <b>Existing Number</b></p>
					{ExistingNumberData?.first_number?.phoneNumber &&
						<p>Phone Number: <b>{ExistingNumberData?.first_number?.phoneNumber}</b></p>

					}
					{ExistingNumberData?.verification_pref &&
						<p>Verification: <b>{titleCase(ExistingNumberData?.verification_pref)}</b></p>
					}
					{ExistingNumberData?.is_this_a_mobile_number &&
						<p>Mobile Number: <b>{ExistingNumberData?.is_this_a_mobile_number}</b></p>
					}
					{ExistingNumberData?.keep_the_number_as_a_mobile_phone_number &&
						<p>Keep as Mobile: <b>{ExistingNumberData?.keep_the_number_as_a_mobile_phone_number}</b></p>
					}
					{ExistingNumberData?.use_number_only_to_send_receive_faxes &&
						<p>Only Fax: <b>{ExistingNumberData?.use_number_only_to_send_receive_faxes}</b></p>
					}
					{ExistingNumberData?.carrier_name &&
						<p>Carrier: <b>{titleCase(ExistingNumberData?.carrier_name)}</b></p>
					}
					{ExistingNumberData?.account_number_for_this_account_with_phone_business &&
						<p>Account Number: <b>{ExistingNumberData?.account_number_for_this_account_with_phone_business}</b></p>
					}
					{ExistingNumberData?.is_this_the_main_number_on_the_business_account &&
						<p>Is this number BTN: <b>{ExistingNumberData?.is_this_the_main_number_on_the_business_account}</b></p>
					}
					{ExistingNumberData?.btn_number?.phoneNumber &&
						<p>BTN Number: <b>{ExistingNumberData?.btn_number?.phoneNumber}</b></p>
					}
					{/* <p>Business Account: <b>YES</b></p> */}
					{ExistingNumberData?.are_there_any_more_number_that_you_want_to_port &&
						<p>Port More Numbers: <b>{ExistingNumberData?.are_there_any_more_number_that_you_want_to_port}</b></p>
					}
					{ExistingNumberData?.how_many_number_you_have_on_the_account > 0 &&
						<p>More Numbers: <a href="#">VIEW</a></p>
					}
					{ExistingNumberData?.provide_the_port_out_pin_number &&
						<p>PORT OUT PIN: <b>{ExistingNumberData?.provide_the_port_out_pin_number}</b></p>
					}
					{ExistingNumberData?.upload_copy_of_latest_phone_bill &&
						<p>Phone Bill: <a href="#">VIEW</a></p>
					}
					{ExistingNumberData?.when_do_you_want_port_the_number &&
						<p>Porting Day: <b>{ExistingNumberData?.when_do_you_want_port_the_number.option === "as_soon_as_possible" ? "ASAP" : ExistingNumberData?.when_do_you_want_port_the_number.time}</b></p>
					}
					{ExistingNumberData?.port_number_is_agree &&
						<p>Date Request Agreement: <b>{ExistingNumberData?.port_number_is_agree ? "Agree" : "Disagree"}</b></p>
					}
					{/*Your business name on the account:  */}
					{ExistingNumberData?.is_business_account &&
						<p>Is Business Account: <b>{ExistingNumberData?.is_business_account ? "YES" : "NO"}</b></p>
					}
					{ExistingNumberData?.business_name &&
						<p>Business Account: <b>{ExistingNumberData?.business_name}</b></p>
					}
					{ExistingNumberData?.authorized_first_name &&
						<p>Customer: <b>{ExistingNumberData?.authorized_first_name + " " + ExistingNumberData?.authorized_last_name}</b></p>
					}
					{/* Service Address */}

					{ExistingNumberData?.service_address_where_the_phone_physically_installed > 0 &&
						<p>Service Address: <a href="#" onClick={() => setStep(17)}>VIEW</a></p>
					}
					{ExistingNumberData?.service_address_where_the_phone_physically_installed?.street_address &&
						<p>Street Address: <b>{ExistingNumberData?.service_address_where_the_phone_physically_installed?.street_address?.label}</b></p>
					}
					{ExistingNumberData?.service_address_where_the_phone_physically_installed?.city &&
						<p>City: <b>{ExistingNumberData?.service_address_where_the_phone_physically_installed?.city}</b></p>
					}
					{ExistingNumberData?.service_address_where_the_phone_physically_installed?.country &&
						<p>Country: <b>{ExistingNumberData?.service_address_where_the_phone_physically_installed?.country.label}</b></p>
					}
					{ExistingNumberData?.service_address_where_the_phone_physically_installed?.zip_code &&
						<p>Zip Code: <b>{ExistingNumberData?.service_address_where_the_phone_physically_installed?.zip_code}</b></p>
					}
					{ExistingNumberData?.service_address_where_the_phone_physically_installed?.state &&
						<p>State: <b>{ExistingNumberData?.service_address_where_the_phone_physically_installed?.state.label}</b></p>
					}
					{/* Our Plan */}
					{ExistingNumberData?.plan &&
						<p>Plan: <b>{ExistingNumberData?.plan_details?.title}</b></p>
					}
					{/* Add ON */}
					{ExistingNumberData?.active_add_ons.length > 0 &&
						<div>
							<p>Add Ons: <b>{ExistingNumberData?.active_add_ons.join(', ')}</b></p>
						</div>
					}
					{/* Signature */}
					{ExistingNumberData?.signature &&
						// <p>Signature: <a href="#" onClick={() => setStep(20)}>VIEW</a></p>
						<p className="d-flex align-items-center overflow-hidden">Signature: <img alt="signature" style={{ width: ExistingNumberData?.signature.startsWith('data:image/png') ? '60px' : '250px', marginLeft: "5px" }} src={ExistingNumberData?.signature} /></p>
					}
					{ExistingNumberData?.signature_font &&
						// <p>Signature Font: <a href="#" onClick={() => setStep(20)}>VIEW</a></p>
						<p>Signature Font: <b>{ExistingNumberData?.signature_font}</b></p>
					}
					{/* Payment Details */}
					{ExistingNumberData?.name_on_card &&
						<p>Payment Details: <a href="#" onClick={() => setStep(21)}>VIEW</a></p>
					}
					{ExistingNumberData?.name_on_card &&
						<p>Name on Card: <b>{ExistingNumberData?.name_on_card}</b></p>
					}
					{ExistingNumberData?.card_number &&
						<p>Card Number: <b> **** **** **** {ExistingNumberData?.card_number?.slice(-4)}</b></p>
					}
					{ExistingNumberData?.expiry &&
						<p>Expiry: <b>{ExistingNumberData?.expiry}/{ExistingNumberData?.expiryyear?.toString().slice(-2)}</b></p>
					}
					{ExistingNumberData?.cvv &&
						<p>CVV: <b>{ExistingNumberData?.cvv}</b></p>
					}
					{ExistingNumberData?.terms_condition &&
						<p>Terms & Conditions: <b> {ExistingNumberData?.terms_condition ? "Agree" : "Disagree"}</b></p>
					}
				</div>
				:
				<div className="sidebarData">
					<p>Preference: <b>New Number</b></p>
				</div>
			}
		</>
	)
}
export default NumberSidebarData
