// import axios from "../../axios"

import axios from "axios";

export const GET_TIMEZONES = 'GET_TIMEZONES'
export const GET_STREETADDRESS = 'GET_STREEADDRESS'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_DIVISIONS = 'GET_DIVISIONS'



export const getStreetAddress = (payload) => async () => {

	const config = {
		method: 'get',
		url: `https://nominatim.openstreetmap.org/search.php?street=${payload.street_address}&city=${payload.city}&state=&country=${payload.country.label}&postalcode=&polygon_geojson=1&format=jsonv2`,
		// url: 'https://nominatim.openstreetmap.org/search.php?q=clifton&polygon_geojson=1&limit=10&polygon_threshold=0&format=jsonv2',

	};
	return axios(config).then(response => response.data)
		.catch(function (error) {
			console.log(error);
		});

}

export const getCountries = () => (dispatch) => {
	return axios.get('countries/all').then((res) => {
		dispatch({
			type: GET_COUNTRIES,
			payload: res.data.result,
		})
		return res.data;
	})
}

export const getTimezones = () => (dispatch) => {
	return axios.get('timezones/all').then((res) => {
		dispatch({
			type: GET_TIMEZONES,
			payload: res.data.result,
		})
		return res.data;
	})
}

export const getDivisionsByCountry = (countryId) => (dispatch) => {
	return axios.get('countries/division/' + countryId).then((res) => {
		dispatch({
			type: GET_DIVISIONS,
			payload: res.data.result,
		})
		return res.data;
	})
}
