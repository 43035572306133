import axios from 'axios'
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/`;
// axios.defaults.baseURL = 'https://evening-dusk-79024.herokuapp.com/api/';
axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
// axios.defaults.headers.common = {
//   ...axios.defaults.headers.common,
//   'Access-Control-Allow-Origin': '*',
// };

const axiosInstance = axios.create()

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) =>
		Promise.reject(
			(error.response && error.response.data) || 'Something went wrong!'
		)
)

export default axiosInstance
