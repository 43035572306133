/* eslint-disable react-hooks/exhaustive-deps */

import { CoverageContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";
import { useEffect } from "react";
import { useContext, useState } from "react";
import FormFooter from "./FormFooter";

const Initial = ({ setStep, setError = () => { }, activeStep, setProcessing }) => {

	const { coverage, setCoverage } = useContext(CoverageContext)

	const { user } = useAuth();
	// const cachedForm = JSON.parse(localStorage.getItem('coverageData'));

	const [isAlive, setIsAlive] = useState(true);
	const [form, setForm] = useState({
		firstname: coverage?.firstname,
		lastname: coverage?.lastname,
		email: coverage?.email,
		password: coverage?.password
	});
	// const [form, setForm] = useState({
	// 	firstname: coverage.firstname ? coverage.firstname : cachedForm?.firstname,
	// 	lastname: coverage.lastname ? coverage.lastname : cachedForm?.lastname,
	// 	email: coverage.email ? coverage.email : cachedForm?.email,
	// 	password: coverage?.password
	// });

	const checkValidation = (e) => {
		setCoverage({ ...coverage, error: true })
		const { name, rule } = e.target.dataset

		if (rule === 'required') {

			if (!e.target.value) {
				setError(`${name} is required`)
			} else {
				setError("")
				setCoverage({ ...coverage, error: false })
			}
		}
		if (rule === 'email') {


			if (!e.target.value) {
				setError(`${name} requries a valid email`)
			} else {
				setError("")
				setCoverage({ ...coverage, error: false })
			}
		}
	}

	const handleChange = (e) => {
		setError("");
		setForm({ ...form, [e.target.name]: e.target.value })
		setCoverage({ ...coverage, [e.target.name]: e.target.value })
		// setting value to localstorage
	}

	useEffect(() => {


		if (isAlive) {

			const cachedForm = JSON.parse(localStorage.getItem('coverageData'));
			setCoverage({
				...coverage,
				'firstname': cachedForm?.firstname || user?.first_name,
				'lastname': cachedForm?.lastname || user?.last_name,
				'email': cachedForm?.email || user?.email
			})
			setForm({
				firstname: coverage?.firstname,
				lastname: coverage?.lastname,
				email: coverage?.email,
				password: coverage?.password
			})
		}
		return () => {
			setIsAlive(false)
		}

	}, [coverage, isAlive, setCoverage, setIsAlive])




	return (

		<div className="step">
			<h5 className="mb-2 sm-heading">PORTABILITY CHECK</h5>
			<h2 className="lg-heading mb-5">You're almost there! Let's start by telling us your name and email</h2>
			<div className="row mb-lg-4">
				<div className="col-lg-6 col-12">
					<div className="coverage-field">
						<div className="input-container">
							<i className="fa fa-user icon-c" aria-hidden="true"></i>
							<input name="firstname" className="input-field" id="first-name" type="text" placeholder="First Name" value={form.firstname} data-name={"First Name"} data-rule={['required']} onBlur={(e) => checkValidation(e)} onChange={handleChange} />
						</div>
					</div>
				</div>
				<div className="col-lg-6 col-12">
					<div className="coverage-field">
						<div className="input-container">
							<i className="fa fa-user icon-c" aria-hidden="true"></i>
							<input name="lastname" className="input-field" id="last-name" type="text" placeholder="Last Name" data-name={"Last Name"} data-rule={['required']} onBlur={(e) => checkValidation(e)} value={form.lastname} onChange={handleChange} />
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<div className="coverage-field">
						<div className="input-container">
							<i className="fa fa-at icon-c" aria-hidden="true"></i>
							<input name="email" className="input-field" id="email" type="text" placeholder="email@gmail.com" data-name={"Email"} data-rule={['required']} onBlur={(e) => checkValidation(e)} value={form.email} onChange={handleChange} />
						</div>
					</div>
				</div>
			</div>
			<FormFooter setProcessing={setProcessing} setError={(e) => setError(e)} action={"create"} activeStep={activeStep} setStep={setStep} />
		</div>

	)
}

export default Initial
