import axios from "../../axios"

export const CHECK_MY_NOTIFICATIONS = 'CHECK_MY_NOTIFICATIONS';
export const GET_MY_NOTIFICATIONS = 'GET_MY_NOTIFICATIONS';
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const MARK_AS_READ = 'MARK_AS_READ';
export const MARK_AS_OPEN = 'MARK_AS_OPEN';

export const checkMyNotifications = () => (dispatch) => {
	axios.get('notification/check-my-notifications');
}

export const getMyNotifications = () => async (dispatch) => {
	return axios.get('notification/get').then(resp => {
		dispatch({
			type: GET_MY_NOTIFICATIONS,
			payload: resp.data.result
		});
	})
}

export const createNotification = () => async (dispatch) => {
	return axios.get('notification/get').then(resp => {
		dispatch({
			type: CREATE_NOTIFICATION,
			payload: resp.data.result
		});
	})
}

