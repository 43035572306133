import { CoverageContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Success = () => {
	const { signupViaCoverage } = useAuth();
	const history = useHistory();
	const { coverage } = useContext(CoverageContext)

	const LoginASAP = async () => {
		try {
			await signupViaCoverage(coverage.userdata)

			history.push('/customer/dashboard')

		} catch (e) {
			console.log(e)
			toast.error(e.message)

		}
	}
	useEffect(() => {
		setTimeout(() => {
			LoginASAP()
		}, 2500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="step">
			<div className="completed-wrapper">
				<h1 className="success-heading">Success!</h1>
				<img src="/images/forms/check-mark.png" alt="" className="check-img" />
				<h2 className="text">
					Your request has been submitted.
					Thank you for filling out the registration form, you will be redirected momentarily.
					<div className="loader-success"></div>
				</h2>
			</div>
		</div>
	)
}

export default Success
