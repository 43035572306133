import { CHECK_MY_NOTIFICATIONS, CREATE_NOTIFICATION } from "redux/actions/NotificationAction"

const initialState = []

const NotificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case CHECK_MY_NOTIFICATIONS:
			return [
				...action.payload
			]
		case CREATE_NOTIFICATION:
			return [
				...state,
				...action.payload
			]
		default:
			return [
				...state
			]
	}
}

export default NotificationReducer;
