/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { NewNumberContext } from "contexts/FormsContext";
import { useContext } from "react";
import { toast } from 'react-toastify'

import { useDispatch } from "react-redux";
import { coverageSignUp } from "redux/actions/CoverageActions";

const FormFooter = ({ setStep, activeStep, centerProceed, final, login, dependency = true, cf, action, payloadForUpdate }) => {

	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false);
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)

	const createProfile = async () => {

		setLoading(true)
		const payload = {
			"first_name": NewNumberData.firstname,
			"last_name": NewNumberData.lastname,
			"email": NewNumberData.email,
			"password": "@TempPwd123",
		}
		//coverageSignUp perform the same thing that we are doing here.
		await dispatch(coverageSignUp(payload)).then(response => {
			if (response.bool) {
				setNewNumberData({ ...NewNumberData, 'userData': response.result, 'user_id': response?.result?.id })
				setLoading(false)
				// setStep(activeStep + 1)
				setStep(1) // will return the same step
			}
		}).catch(errr => {

			console.log(errr)
			toast.error(errr.message)
			setLoading(false)
		})


	}

	const createNewOrder = () => {

	}


	const existingNumberOrder = () => {
		if (action === 'create') {
			createNewOrder()
		} else if (action === 'create-profile') {
			createProfile()
		} else {
			setStep(activeStep + 1)
		}
	}


	return (
		<>
			<div className={centerProceed ? "footer justify-content-center" : "footer"} >
				{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
				{login &&
					<div className="d-flex flex-column align-items-center me-lg-3">
						<button type="button" onClick={() => setStep(activeStep + 1)} className="proceed-btn mb-2">
							Login&nbsp;<i className="fa fa-sign-in" aria-hidden="true"></i>
						</button>
						<div className="pe">
							&nbsp;
							{/* <img src="/images/forms/icon-enter.png" alt="" /> */}
						</div>
					</div>
				}
				<div className="d-flex flex-column align-items-center">
					{!dependency ?
						<button type="button" disabled={loading} onClick={() => cf()} className="proceed-btn mb-2">
							{loading ?
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								:
								final ? "Submit" : "Proceed"
							}

						</button>
						:
						<button type="button" disabled={loading} onClick={() => !NewNumberData.error ? existingNumberOrder() : ""} className="proceed-btn mb-2">
							{loading ?
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
								:
								final ? "Submit" : "Proceed"
							}
						</button>
					}
					{/* <p  "m-0 p-0">{checkAddress ? 'Invalid Address' : ''}</p>  */}
					<div className="pe">
						Press Enter
						<img src="/images/forms/icon-enter.png" alt="" />
					</div>
				</div>

			</div>
			{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
			<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
				<img src="/images/forms/icon-prev.png" alt="" />
			</button>
			{/* <a className="next-step" onclick="form.steps('next')"> */}
			<button type="button" data-title="Next" onClick={() => setStep(activeStep + 1)} className="next-step elaboration right">
				<img src="/images/forms/icon-next.png" alt="" />
			</button>
		</>
	)
}

export default FormFooter
