/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable eqeqeq */
import React, { useState, useContext } from "react";

import { NewNumberContext } from "contexts/FormsContext";
import FormFooter from "./FormFooter";
const SelectNumber = ({ setStep, activeStep }) => {
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)
	let [toggle_dropdown, set_toggle_dropdown] = useState(false)
	let [selected_country_code, set_selected_country_code] = useState(NewNumberData.country_code ? NewNumberData.country_code : "")
	let [selected_country_image, set_selected_country_image] = useState(NewNumberData.country_image ? NewNumberData.country_image : "")
	let [randomNumber, setRandomNumbers] = useState(NewNumberData.randomNumber ? NewNumberData.randomNumber : []);
	let [tipEnable, setTipEnable] = useState(false)
	let [numbersSelected, setNumbersSelected] = useState(0)
	let [isLoadingNumbers, setIsLoadingNumbers] = useState(false);
	const handleCountryChange = (e) => {
		const { value, country, image } = e.target.dataset
		setForm({ ...form, "country": country })
		set_selected_country_code(value)
		set_selected_country_image(image, setNewNumberData({ ...NewNumberData, "country": country, "country_image": image, "country_code": value }))
	}
	const generateRandomNumber = (e) => {
		setIsLoadingNumbers(true);
		setTipEnable(true)
		setTimeout(() => {
			setIsLoadingNumbers(false)
			setNumbersSelected(0)
			var x = document.getElementsByName("new_number");
			for (let i = 0; i < x.length; i++) {
				x[i].checked = false;
			}
		}, 1000);
		let min = Math.ceil(1000000);
		let max = Math.floor(9999999);
		let content = [];
		for (let i = 0; i < 12; i++) {
			const item = '(' + NewNumberData.area_code + ') ' + Math.floor(Math.random() * (max - min) + min);
			content.push([item]);
		}
		setRandomNumbers(content, setNewNumberData({ ...NewNumberData, randomNumber: content }));
	};

	const [form, setForm] = useState({
		'number_type': NewNumberData?.number_type || "",
		'country': NewNumberData?.country || "",
		'vanity': NewNumberData?.vanity || "",
		'text_position': NewNumberData?.text_position || "",
		'features': NewNumberData?.features || "",
		'area_code': NewNumberData?.area_code || "",
	})

	const handleChange = (e) => {
		setForm({ ...form, [e.target.name]: e.target.value })
		setNewNumberData({ ...NewNumberData, [e.target.name]: e.target.value })
	}

	console.log(NewNumberData)

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading mb-5">Get a new phone number for calls, texts or faxes</h2>
			<div className="row mb-3">
				<div className="col">
					<label>Number Type</label>
					<div className="main-toggle">
						<input type="radio" id="local" name="number_type" value="local" checked={form.number_type == "local"} onChange={handleChange} />
						<label htmlFor="local">Local</label>
						<input type="radio" id="tollfree" name="number_type" value="tollfree" checked={form.number_type == "tollfree"} onChange={handleChange} />
						<label htmlFor="tollfree">Toll Free</label>
						<input type="radio" id="mobile" name="number_type" value="mobile" checked={form.number_type == "mobile"} onChange={handleChange} />
						<label htmlFor="mobile">Mobile</label>
					</div>
				</div>
				{/* </div> */}
				{/* <div className="row"> */}
				<div className="col-lg-6 col-12 position-relative">
					<label>Country</label>
					<a className="nav-link country-select-new-number d-flex align-items-center ps-3" href="#" onClick={() => set_toggle_dropdown(toggle_dropdown = !toggle_dropdown)}>
						{NewNumberData.country ? NewNumberData.country : "Please select the country"}
					</a>
					{toggle_dropdown &&
						<ul className="dropdown-menu visible-solid" aria-labelledby="navbarDarkDropdownMenuLink" onClick={() => set_toggle_dropdown(toggle_dropdown = !toggle_dropdown)}>
							<li onClick={(e) => handleCountryChange(e)}><a data-value="+1" data-image="images/forms/usaicon.png" data-country="United States" className="dropdown-item" href="#"><img className="flag pe-3" src="/images/forms/united-statesSq.png" alt="" /> United States</a></li>
							<li onClick={(e) => handleCountryChange(e)}><a data-value="+1" data-country="Canada" data-image="images/forms/canadaicon.png" className="dropdown-item" href="#"><img className="flag pe-3" src="/images/forms/canadaSq.png" alt="" /> Canada</a></li>
							<li onClick={(e) => handleCountryChange(e)}><a data-value="+1" data-image="images/forms/puertoicon.png" data-country="Puerto Rico" className="dropdown-item" href="#"><img className="flag pe-3" src="/images/forms/puerto-rico.png" alt="" /> Puerto Rico</a></li>
							<li onClick={(e) => handleCountryChange(e)}><a data-value="+927" data-image="images/forms/israelicon.png" data-country="Israel" className="dropdown-item" href="#"><img className="flag pe-3" src="/images/forms/israel.png" alt="" /> Israel</a></li>
						</ul>
					}
					{/* </div>
            <div className="col-lg-6 col-12"> */}

				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label>Text in Number</label>
					<input type="text" maxLength="8" name="vanity" value={form?.vanity} onChange={handleChange} className="w-100 ps-3 area-code-number" placeholder="Type text you want as number" />
				</div>
				<div className="col">
					<label>Text Position</label>
					<div className="main-toggle">
						<input type="radio" id="preText" name="text_position" value="preText" checked={form.text_position == "preText"} onChange={handleChange} />
						<label htmlFor="preText">Pre</label>
						<input type="radio" id="postText" name="text_position" value="postText" checked={form.text_position == "postText"} onChange={handleChange} />
						<label htmlFor="postText">Post</label>
					</div>
				</div>

			</div>
			<div className="row mb-3">
				<div className="col">
					<label>Features</label>
					<div className="main-toggle">
						<input type="radio" id="call" name="features" value="call" checked={form.features == "call"} onChange={handleChange} />
						<label htmlFor="call"> <img alt="" src="/images/forms/phonecall.png" width="20px" /> Call</label>
						<input type="radio" id="sms" name="features" value="sms" checked={form.features == "sms"} onChange={handleChange} />
						<label htmlFor="sms"><img alt="" src="/images/forms/sms.png" width="20px" /> SMS</label>
						<input type="radio" id="fax" name="features" value="fax" checked={form.features == "fax"} onChange={handleChange} />
						<label htmlFor="fax"><img alt="" src="/images/forms/faxicon.png" width="20px" /> {form.number_type == "mobile" ? "DATA" : "FAX"}</label>
					</div>
				</div>
				<div className="col">
					<label>Area Code</label>
					<div className="d-flex">
						<input type="text" maxLength="3" onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} name="area_code" value={form.area_code} className="w-100 ps-3 area-code-number" placeholder="Type area code you are looking for" onChange={handleChange} />
						<button type="button" className="phoneMaskContinue dontRotate" id="code-button" disabled="" onClick={() => generateRandomNumber()}>
							<svg className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#fff"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4" /><circle cx="8" cy="8" r="7" /></g></svg>
						</button>
					</div>
				</div>
			</div>
			{
				tipEnable &&
				<div className="tipWrapper">
					<div className="tipModule">
						<div className="tipClose" onClick={() => setTipEnable(false)}>x</div>
						<div className="row">
							<div className="col">
								<h4>You can select number(s) from below.</h4>
								<h5 className="blue">{numbersSelected} Numbers Selected</h5>
							</div>
						</div>
						<div className="choose_number pt-3 position-relative">
							<div className="container w-100">
								<div className="row w-100">
									{randomNumber.map((number, index) => (
										<div className="col-lg-2 col-12 mb-4">
											<label className="card-addons" htmlFor={'number' + index}>
												<input id={'number' + index} className="card__input" defaultChecked={NewNumberData.new_number === selected_country_code + " " + number} type="checkbox" name="new_number" value={selected_country_code + " " + number} onClick={e => setNewNumberData({ ...NewNumberData, new_number: e.target.value })} onChange={e => { e.target.checked ? setNumbersSelected(numbersSelected + 1) : setNumbersSelected(numbersSelected - 1) }} />
												<div className="card__body">
													<div className="card__body-cover">
														<img alt="" className="select-num-image rounded-circle" src={selected_country_image} />
														<span className="card__body-cover-checkbox">
															<svg className="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
																<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
															</svg>
														</span>
													</div>
													<header className="card__body-header">
														<h2 className="card__body-header-title">{selected_country_code}{' '}{number}</h2>
														<p className="card__body-header-subtitle mb-2">Features:</p>
														<div className="row d-flex justify-content-center">
															<div className="col">
																<img alt="" src="/images/forms/sms.png" width="20px" />
															</div>
															<div className="col border border-2 border-top-0 border-bottom-0">
																<img alt="" src="/images/forms/phonecall.png" width="20px" />
															</div>
															<div className="col">
																<img alt="" src="/images/forms/faxicon.png" width="20px" />
															</div>
														</div>
													</header>
												</div>
											</label>
										</div>
									))}
								</div>
							</div>
							{isLoadingNumbers &&
								<div className="tinyLoaderWrapper">
									<div className="tiny-loader"></div>
								</div>
							}
						</div>
						<div className="row">
							<div className="col d-flex justify-content-start">
								<button type="button" className="load-more-button" onClick={() => generateRandomNumber()}>LOAD MORE</button>
							</div>
							<div className="col d-flex justify-content-end">
								<button type="button" onClick={() => setTipEnable(false)} className="done-button">DONE</button>
							</div>
						</div>
					</div>
				</div>
			}
			<div className="col d-flex justify-content-end" style={{ marginBottom: -40 }}>
				<button type="button" onClick={() => setTipEnable(false)} className="done-button">ADVANCE OPTIONS</button>
			</div>
			<FormFooter activeStep={activeStep} setStep={setStep} dependency={randomNumber.length > 0} cf={generateRandomNumber} />
		</div>
	)
}
export default SelectNumber
