import React from "react";
import { SignupContext } from "contexts/FormsContext";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import useAuth from "hooks/useAuth";
import { useState } from "react";
import MiniLoader from "components/Loader/MiniLoader";
import { useHistory } from "react-router-dom";

const Initial = ({ setErrors = () => { } }) => {
	const { signupData, setSignupData } = useContext(SignupContext)
	const [loading, setLoading] = useState(false);
	const history = useHistory();
	// let [activeFormat, setFormat] = useState(signupData.activeSocialFormat)
	const { register, watch } = useForm();
	const { socialLogin } = useAuth();

	const submitHandler = async (e) => {

		setLoading(true)
		if (!e.signup_method) {
			setErrors([
				{
					'bool': true,
					'message': "Please select valid option"
				}
			])
			return false;
		}

		// If selection is not email then redirect to respective social panel.
		if (e.signup_method !== 'email') {

			await socialLogin(e.signup_method, "signup")
		} else {
			// if selection is email continue
			setSignupData({ ...signupData, 'activeSocialFormat': e.signup_method })
			history.push('/signup/personal-data')
		}

	}

	return (
		<div className="step">
			<form>
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<h2 className="lg-heading mb-5">Sign Up With</h2>
				<div className="row">
					<div className="col">
						<div className="signupbutton_content">
							{
								loading ?
									<MiniLoader />
									:
									<>
										<div className="row">
											<div className="cat action col-6 p-0">
												<label>
													<input type="radio" disabled={loading} className="social_check" id="social_check_fb" {...register("signup_method")} onClick={(e) => { setLoading(true); submitHandler({ "signup_method": e.target.value }) }} value="azure" defaultChecked={signupData.activeSocialFormat === "azure"} />
													<span><img src="/images/forms/microsoft.ico" alt="" style={{height: "26px"}} />Microsoft</span>
												</label>
											</div>
											<div className="cat action col-6 p-0">
												<label>
													<input type="radio" disabled={loading} className="social_check" id="social_check_google" {...register("signup_method")} onClick={(e) => { setLoading(true); submitHandler({ "signup_method": e.target.value }) }} value="google" defaultChecked={signupData.activeSocialFormat === "google"} />
													<span><img src="/images/forms/icon-google.png" alt="" />Google</span>
												</label>
											</div>

										</div>
										<div className="email-signup text-center">
											<p className="text my-4 OR">OR</p>
											<div className="cat action">
												<label>
													<input type="radio" disabled={loading} className="social_check" id="social_check_apple" {...register("signup_method")} onClick={(e) => { setLoading(true); submitHandler({ "signup_method": e.target.value }) }} value="email" defaultChecked={signupData.activeSocialFormat === "email"} />
													<span><img className="black" src="/images/forms/email.png" alt="" />Signup with Email</span>
												</label>
											</div>
										</div>
									</>
							}

						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default Initial
