import React from 'react'
const orderHistoryRoutes = [
    {
        path: '/customer/my-orders',
        component: React.lazy(() => import('./Index')),
        exact: true,
        auth: ['admin', 'User'],
    },
    {
        path: '/customer/my-orders/details/:id',
        component: React.lazy(() => import('./OrderDetails')),
        exact: true,
        auth: ['admin', 'User'],
    }
]

export default orderHistoryRoutes
