import { NewNumberContext } from "contexts/FormsContext";
import React, { useContext } from "react";
import FormFooter from "./FormFooter";


const AddOns = ({ setStep, activeStep }) => {
	const { NewNumberData, setNewNumberData } = useContext(NewNumberContext)

	const handler = (e) => {
		if (e.target.checked) {

			let i = NewNumberData.active_add_ons.indexOf(e.target.value);
			if (i) {
				setNewNumberData({ ...NewNumberData, active_add_ons: [...NewNumberData.active_add_ons, e.target.value] })
			}
		} else {

			let i = NewNumberData.active_add_ons.indexOf(e.target.value);
			let arr = NewNumberData.active_add_ons;
			arr.splice(i, 1);
			setNewNumberData({ ...NewNumberData, active_add_ons: arr })
		}
	}

	return (

		<div className="step">
			<img className="bannerAddOn" src="/images/forms/banner-addon.jpg" alt="" />
			<div className="spacer-addon-banner"></div>
			<div className="mt-25 container1">
				<div class="col-lg-3 m-4">
					<label class="card-addons">
						<input class="card__input" type="checkbox" defaultChecked={NewNumberData.active_add_ons.includes("Text Messages")} value="Text Messages" onChange={handler} />
						<div class="card__body">
							<div class="card__body-cover">
								<img alt="" className="addon-pkg-image" src="/images/forms/xpressPorting.png" />
								<span class="card__body-cover-checkbox">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
										<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
									</svg>
								</span>
								<span class="card__body-cover-checkbox2">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
									</svg>
								</span>
							</div>
							<header class="card__body-header">
								<h2 class="card__body-header-title">Text Messages</h2>
								<p class="card__body-header-subtitle mb-0">Monthlty 3$</p>
							</header>
						</div>
					</label>
				</div>
				<div class="col-lg-3 m-4">
					<label class="card-addons">
						<input class="card__input" type="checkbox" defaultChecked={NewNumberData.active_add_ons.includes("Express Porting")} value="Express Porting" onChange={handler} />
						<div class="card__body">
							<div class="card__body-cover">
								<img alt="" className="addon-pkg-image" src="/images/forms/forward-message.png" />
								<span class="card__body-cover-checkbox">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
										<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
									</svg>
								</span>
								<span class="card__body-cover-checkbox2">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
									</svg>
								</span>
							</div>
							<header class="card__body-header">
								<h2 class="card__body-header-title">Express Porting</h2>
								<p class="card__body-header-subtitle mb-0">Monthlty 2$</p>
							</header>
						</div>
					</label>
				</div>
				<div class="col-lg-3 m-4">
					<label class="card-addons">
						<input class="card__input" type="checkbox" defaultChecked={NewNumberData.active_add_ons.includes("Data Package")} value="Data Package" onChange={handler} />
						<div class="card__body">
							<div class="card__body-cover">
								<img alt="" className="addon-pkg-image" src="/images/forms/dataporting.png" />
								<span class="card__body-cover-checkbox">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
										<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
									</svg>
								</span>
								<span class="card__body-cover-checkbox2">
									<svg class="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
									</svg>
								</span>
							</div>
							<header class="card__body-header">
								<h2 class="card__body-header-title">Data Package</h2>
								<p class="card__body-header-subtitle mb-0">Monthlty 5$</p>
							</header>
						</div>
					</label>
				</div>
				{/* <div className="radio-group row justify-content-evenly align-items-center px-3 text-center a">
          <div className={((addOns.express_porting) ? "selected" : "") + " col-auto ml-sm-2 mx-1 card-block text-center p-0 radio"} id="existing_num" onClick={() => setAddOn({...addOns,  "express_porting" : !addOns.express_porting })}>
            <div className="flex-row">
              <div className="col">
                <div className="pic">
                  <img className="irc_mut img-fluid" src="/images/forms/xpressPorting.png" width="80" alt='' height="80" />
                </div>
                <p>Express<br />Porting</p>
                <small style={{color: "#1a75bb"}}>One Time: <b>5$</b></small>
              </div>
            </div>
          </div>

          <div className={((addOns.message_forwarding) ? "selected" : "") + " col-auto ml-sm-2 mx-1 card-block text-center p-0 radio"} id="new_num"  onClick={() => setAddOn({...addOns,  "message_forwarding" : !addOns.message_forwarding }) }>
            <div className="flex-row">
              <div className="col">
                <div className="pic">
                  <img className="irc_mut img-fluid" src="/images/forms/forward-message.png" width="80" alt="" height="80" />
                </div>
                <p>Message<br />Forwarding</p>
                <small style={{color: "#1a75bb"}}>Monthly: <b>5$</b></small>
              </div>
            </div>
          </div>
        </div> */}
			</div>
			<FormFooter action={'main-add-on'} activeStep={activeStep} setStep={setStep} centerProceed={true} />
		</div>
	)
}

export default AddOns
