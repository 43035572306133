/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import { SignupContext, SignupProvider } from 'contexts/FormsContext'
import Title from 'general/Title'

import React, { useEffect, useState } from 'react'
import Initial from './signup-components/Initial'
import PersonalData from './signup-components/PersonalData'
import PhoneVerification from './signup-components/PhoneVerification'
import Goals from './signup-components/Goals'
import AccountFor from './signup-components/AccountFor'
import AddMorePeople from './signup-components/AddMorePeople'
import Success from './signup-components/Success'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import EmailVerification from './signup-components/EmailVerification'
import useAuth from 'hooks/useAuth'
import FacebookEmailConformation from './signup-components/FacebookEmailConformation'

const stepTitle = [
	{
		id: 1,
		title: "Sign Up With",
		path: "/signup"
	},
	{
		id: 2,
		title: "Name & Email",
		path: "/signup/personal-data"
	},
	{
		id: 3,
		title: "Email Address",
		path: "/signup/email-verification"
	},
	{
		id: 4,
		title: "Phone Number",
		path: "/signup/phone-verification"
	},
	{
		id: 5,
		title: "Account For",
		path: "/signup/account-for"
	},
	// {
	// 	id: 6,
	// 	title: "Add More People",
	// 	path: "/signup/add-more-people"
	// },
	{
		id: 6,
		title: "Goals",
		path: "/signup/goals"
	}
];

const Index = () => {

	const location = useLocation();
	const history = useHistory();
	const { isAuthenticated } = useAuth()
	const [activeStep, setActiveStep] = useState(0);
	const [maxStepReached, setMaxStepReached] = useState(0);
	const [error, setError] = useState({ bool: false, message: "" });
	const [audio, setAudio] = useState(true);

	const handleStepClick = (index) => {
		if (index <= maxStepReached) {
			setActiveStep(index);
			history.push(stepTitle[index - 1].path);
		}
	};

	const stepsCounterMarkup = stepTitle.map((step) => (
		<li
			key={step.id}
			data-title={step.title}
			onClick={() => handleStepClick(step.id)}
			className={
				"navigationItem elaboration bottom" +
				(step.id === activeStep ? " active" : "") +
				(step.id <= maxStepReached ? " accessable active" : "")
			}
		></li>
	));


	useEffect(() => {

		// Update the active step based on the current URL path
		const currentStep = stepTitle.find((step) => step.path === location.pathname);
		if (currentStep) {
			setActiveStep(currentStep.id);
			setMaxStepReached(Math.max(maxStepReached, currentStep.id));
		}
		if (isAuthenticated) {
			history.push('/')
		}
	}, [location.pathname, history, maxStepReached]);

	return (
		<>
			<Title title={"Signup Form"} />
			<Topbar />
			<div className="stepsnavigation">
				{stepsCounterMarkup}
			</div>
			<div className={(audio ? '' : 'disabled ') + 'audioToggle'} onClick={() => setAudio(!audio)} >
				<i className={audio ? 'fa fa-volume-up' : 'fa fa-volume-off'}></i>
			</div>
			<div className="signup_body">
				<div id="example-advanced-form" action="#" className="main wizard clearfix signup">
					{error[0]?.bool &&
						<div class="crossmark-wrapper">
							<span class="message">{error[0]?.message ?? "Please correct errors below"}</span>
							<svg class="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
								<circle class="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
								<path class="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
								<path class="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
							</svg>
						</div>
					}
					{/* {ready &&
							<div class="checkmark-wrapper">
								<span class="message">Ready To Go</span>
								<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
									<path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
								</svg>
							</div>
						} */}
					{/* {processing &&
							<div class="circlemark-wrapper">
								<span class="message">Processing the form please wait</span>
								<svg class="circlemark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle class="circlemark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
									<circle class="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3"></circle>
								</svg>
							</div>
						} */}

					<Switch>
						<Route exact path="/signup" component={() => <Initial setErrors={(e) => setError(e)} />} />
						<Route path="/signup/personal-data" component={() => <PersonalData />} />
						{/* for facebook only */}
						<Route path="/signup/confirm-email" component={() => <FacebookEmailConformation />} />
						<Route path="/signup/email-verification" component={() => <EmailVerification />} />
						<Route path="/signup/phone-verification" component={() => <PhoneVerification />} />
						<Route path="/signup/account-for" component={() => <AccountFor />} />
						{/* <Route path="/signup/add-more-people" component={() => <AddMorePeople />} /> */}
						<Route path="/signup/goals" component={() => <Goals />} />
						<Route path="/signup/success" component={() => <Success />} />
					</Switch>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default Index
