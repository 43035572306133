
import NotFound from 'pages/NotFound';
import ChangePassword from './ChangePassword';
import ForgotPassword from './ForgotPassword';
import Login from './Login'

import Index from './signup/Index';
import SocialLogin from './SocialLogin';
import SingleSignOn from './SingleSignOn';

const authRoutes = [
	{
		path: '/login',
		component: Login,
	},
	{
		path: '/forgot-password',
		component: ForgotPassword,
	},
	{
		path: '/change-password',
		component: ChangePassword,
	},
	{
		path: '/signup',
		// component: SignupVerify,
		// component: Signup,
		component: Index,
	},
	{
		path: '/api/authorize/:provider/callback',
		component: SocialLogin,
		exact: true
	},
	{
		path: '/sso',
		component: SingleSignOn,
		exact: true
	},
	{
		path: '/404',
		component: NotFound,
	},
];

export default authRoutes
