import { CoverageContext } from "contexts/FormsContext";
import React from "react";
import { useState } from "react";
import { useContext } from "react";
import FormFooter from "./FormFooter";



const SimpleQuestion = ({ setStep, activeStep, heading, stepKey, option1, option2, setProcessing }) => {
	// const [ExistingNumberData] = useContext(ExistingNumberContext)
	const { coverage, setCoverage } = useContext(CoverageContext)
	let [answer, setAnswer] = useState(coverage.addMorePPL)
	const handleChange = (e) => {
		setAnswer(e.target.value)
		setCoverage({ ...coverage, addMorePPL: e.target.value })
	}

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">{heading}</h2>
			<div className="row">
				<div className="col">
					<div className="QA_content carrier_check">
						<div className="radio field">
							<input id={stepKey + "_" + option1.toLowerCase()} name={stepKey} type="radio" value="YES" defaultChecked={answer === 'YES'} onChange={handleChange} />
							<label htmlFor={stepKey + "_" + option1.toLowerCase()} className="radio-label">YES</label>
						</div>
						<div className="radio field">
							<input id={stepKey + "_" + option2.toLowerCase()} name={stepKey} type="radio" value="NO" defaultChecked={answer === 'NO'} onChange={handleChange} />
							<label htmlFor={stepKey + "_" + option2.toLowerCase()} className="radio-label">NO</label>
						</div>
					</div>
				</div>
			</div>

			<FormFooter setProcessing={setProcessing} action={"signup-update"} activeStep={activeStep} setStep={setStep} />

		</div>
	)
}

export default SimpleQuestion
