import React from 'react'
const paymentsRoutes = [
    {
        path: '/customer/payments',
        component: React.lazy(() => import('./Payments')),
        auth: ['admin','User'],
    },
	{
        path: '/customer/duepayments/details/:id',
        component: React.lazy(() => import('./PaymentsDetails')),
        exact: true,
        auth: ['admin', 'User'],
    }
]

export default paymentsRoutes
