/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { ExistingNumberContext } from "contexts/FormsContext";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import Tip from "../../forms/sub-components/widgets/Tip";
import ValidationError from "../ValidationError";
import FormFooter from "./FormFooter";


const SimpleQuestion = ({ setStep, activeStep, heading, stepKey, option1, option2, tipBody, tipQuestion, prevStep }) => {
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	// const [tipEnable, setTipEnable] = useState(false)
	const [answer, setAnswer] = useState({});
	const [loading, setLoading] = useState(false);

	const { formState: { errors }, setError, clearErrors } = useForm();

	if (stepKey == 'keep_the_number_as_a_mobile_phone_number') {
		// if (ExistingNumberData.is_this_a_mobile_number === "NO") {
		if (prevStep === 3) {
			setStep(activeStep + 1)
		} else if (prevStep === 5) {
			setStep(activeStep - 1)
		}
		// }
	}
	const handleChange = (e) => {
		setLoading(false)
		clearErrors()
		if (stepKey === 'is_this_a_mobile_number') {
			const trueOptions = ["FIXED_LINE_OR_MOBILE", "MOBILE"]
			const numberTypeFromAPI = ExistingNumberData.first_number?.number_type;
			if ((e.target.value === "YES") && (!trueOptions.includes(numberTypeFromAPI))) {
				setLoading(true)
				setExistingNumberData({ ...ExistingNumberData, processing: false, ready: false })
				setError("is_mobile", { message: "Number is not a mobile" })
				return false;
			}
			setAnswer({ 'is_mobile': e.target.value });
			setExistingNumberData({ ...ExistingNumberData, is_this_a_mobile_number: e.target.value })
		}

		// if (stepKey == 'keep_the_number_as_a_mobile_phone_number') {
		// 	setAnswer({ 'quick_porting_for_mobile': e.target.value });
		// 	setExistingNumberData({ ...ExistingNumberData, keep_the_number_as_a_mobile_phone_number: e.target.value })
		// }

		if (stepKey === 'use_number_only_to_send_receive_faxes') {
			setExistingNumberData({ ...ExistingNumberData, use_number_only_to_send_receive_faxes: e.target.value })
			setAnswer({ 'is_fax': e.target.value });
		}

		if (stepKey === 'is_this_the_main_number_on_the_business_account') {
			setAnswer({ 'btn': e.target.value });
			setExistingNumberData({ ...ExistingNumberData, is_this_the_main_number_on_the_business_account: e.target.value })
		}

		if (stepKey === 'are_there_any_more_number_that_you_want_to_port') {
			setAnswer({ 'are_there_any_more_number_that_you_want_to_port': e.target.value });
			setExistingNumberData({ ...ExistingNumberData, are_there_any_more_number_that_you_want_to_port: e.target.value })
		}
		if (answer != undefined) {
			if (ExistingNumberData.keep_the_number_as_a_mobile_phone_number === "YES" && ExistingNumberData.is_this_a_mobile_number === "YES" && e.target.value === "YES" && answer.is_fax) {
				setExistingNumberData({ ...ExistingNumberData, [e.target.name]: e.target.value, error: ["fax"], processing: false, ready: false })
			} else {
				setExistingNumberData({ ...ExistingNumberData, [e.target.name]: e.target.value, error: [], processing: false, ready: true })
			}
		} else {
			setExistingNumberData({ ...ExistingNumberData, [e.target.name]: e.target.value, error: [], processing: false, ready: false })
		}
	}

	useEffect(() => {

		setExistingNumberData({ ...ExistingNumberData, error: [], "ready": false })
		if (stepKey === 'is_this_a_mobile_number') {
			if (ExistingNumberData.is_this_a_mobile_number != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
			setAnswer({ 'is_mobile': ExistingNumberData.is_this_a_mobile_number });

		}
		if (stepKey === 'are_there_any_more_number_that_you_want_to_port') {
			if (ExistingNumberData.are_there_any_more_number_that_you_want_to_port != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
			setAnswer({ 'are_there_any_more_number_that_you_want_to_port': ExistingNumberData.are_there_any_more_number_that_you_want_to_port });
		}
		if (stepKey === 'keep_the_number_as_a_mobile_phone_number') {

			if (ExistingNumberData.keep_the_number_as_a_mobile_phone_number != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
			setAnswer({ 'quick_porting_for_mobile': ExistingNumberData.keep_the_number_as_a_mobile_phone_number });
		}
		if (stepKey === 'use_number_only_to_send_receive_faxes') {
			if (ExistingNumberData.use_number_only_to_send_receive_faxes != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
			setAnswer({ 'is_fax': ExistingNumberData.use_number_only_to_send_receive_faxes });
		}
		if (stepKey === 'is_this_the_main_number_on_the_business_account') {

			if (ExistingNumberData.first_number.number_type === "TOLL_FREE") {
				console.log(prevStep)
				if (prevStep === 7) {
					setStep(activeStep + 2)
				}
				else if (prevStep === 9) {
					console.log("Here", "This is what i do")
					// console.log(activeStep)
					setStep(activeStep - 2)
				}
				// setStep(activeStep + 1)
				// setStep(activeStep + 1)
			}
			if (ExistingNumberData.is_this_the_main_number_on_the_business_account != "") {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			}
			setAnswer({ 'btn': ExistingNumberData.is_this_the_main_number_on_the_business_account });
		}
	}, [])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<h2 className="lg-heading">{heading}</h2>
				<div className="row">
					<div className="col">
						{
							ExistingNumberData?.error?.includes('fax') ?
								<p className="text-danger">
									Fax number cannot be saved as a mobile number. Please go back and select to save the number as a land line.
								</p>
								:
								""
						}
					</div>
				</div>
				<div className="row">
					<div className="col">
						<div className="QA_content">
							<div className="radio field">
								<input id={stepKey + "_" + option1.toLowerCase()} name={stepKey} type="radio" value="YES" defaultChecked={ExistingNumberData[stepKey] === 'YES'} onChange={handleChange} />
								<label htmlFor={stepKey + "_" + option1.toLowerCase()} className={(ExistingNumberData.error.includes("question") ? "error" : "") + " radio-label"} >YES</label>
							</div>
							<div className="radio field">
								<input id={stepKey + "_" + option2.toLowerCase()} name={stepKey} type="radio" value="NO" defaultChecked={ExistingNumberData[stepKey] === 'NO'} onChange={handleChange} />
								<label htmlFor={stepKey + "_" + option2.toLowerCase()} className="radio-label">{stepKey === 'keep_the_number_as_a_mobile_phone_number' ? "No. Save this number as a landline." : "No"}</label>
							</div>
						</div>
					</div>
				</div>
				<Tip tipBody={tipBody} tipQuestion={tipQuestion} />
				{/* <FormFooter action={'update'} payloadForUpdate={answer} activeStep={activeStep} setStep={setStep} /> */}
				{
					loading ?
						<></>
						:
						<>
							<FormFooter disabledViaForm={loading} action={stepKey === 'are_there_any_more_number_that_you_want_to_port' ? "questions-for-more-number" : "did-update"} payloadForUpdate={answer} activeStep={activeStep} setStep={setStep} />
						</>
				}
			</div>
		</>
	)
}

export default SimpleQuestion
