import { GET_COUNTRIES, GET_DIVISIONS, GET_TIMEZONES } from "redux/actions/StreetMapAction"

const initialState = {
  "countries": [],
  "divisions": [],
}


const StreetMapReducer = function (state = initialState, action) {
  switch (action.type) {

    case GET_COUNTRIES: {
      return {
        ...state,
        countries: [...action.payload]
      }
    }
    case GET_TIMEZONES: {
      return {
        ...state,
        timezones: [...action.payload]
      }
    }
    case GET_DIVISIONS: {
      return {
        ...state,
        divisions: [...action.payload]
      }
    }
    default: {
      return { ...state }
    }
  }
}

export default StreetMapReducer
