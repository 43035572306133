import { toast } from "react-toastify"
import axios from "../../axios"

export const GET_CARRIERS = 'GET_CARRIERS'
export const CREATE_CARRIERS = 'CREATE_CARRIERS'


export const getCarriers = () => (dispatch) => {
	axios.get('carrier/all-approved').then((res) => {
		dispatch({
			type: GET_CARRIERS,
			payload: res.data.result,
		})
	})
}

export const createCarriers = (data) => async () => {

	return axios.post('carrier/add-carrier-request', data).then(({data}) => {
		if (data.result.bool) {
			toast.success("Success! Carrier will be added after approval")
		} else {
			toast.error("Sorry! your request cant be completed")
		}
		return data;

	})

}
