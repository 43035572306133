import { ExistingNumberContext } from "contexts/FormsContext";
import { formatPhone } from "general/FormatNumber";
import { capitalize } from "general/GeneralHelper";
import React, { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { Button, Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAddOns } from "redux/actions/OrderAction";
import FormFooter from "./FormFooter";
import PlanRatesMoreNumber from "./PlanRatesMoreNumber";
import MiniLoader from "components/Loader/MiniLoader";
import "pages/order/assets/css/plans.scoped.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const AddingNumbers = ({
	setStep,
	activeStep,
	setActiveSteps,
	heading,
	placeholder,
	prevStep
}) => {
	// const [loading, setLoading] = useState(true);
	const [carouselIndex, setCarouselIndex] = useState(0);
	const handleSelect = (selectedIndex, e) => {
		setCarouselIndex(selectedIndex);
	};
	const dispatch = useDispatch();
	const categoryList = useSelector(state => state?.orders?.planCategoryList);
	let { ExistingNumberData, setExistingNumberData } = useContext(
		ExistingNumberContext
	);
	const [numberPlans, setNumberPlans] = useState(
		ExistingNumberData?.more_numbers_plans
	);
	const [open, setOpen] = useState();
	const [addOns, setAddOns] = useState([]);
	const openHandler = e => {
		setOpen(e);
	};

	if (ExistingNumberData.more_numbers_plans === undefined) {
		// setStep(activeStep + 1);
		// 	if (prevStep === 17) {
		// 		setStep(activeStep - 1);
		// 	}
		if (prevStep === 17) {
			setStep(activeStep + 1);
		} else if (activeStep === 18) {
			setStep(activeStep - 1);
		}
	}

	const addOnChangeHanlder = (bool, index, e) => {
		const tempArray = [...numberPlans];
		if (bool) {
			tempArray[index]?.addOns?.push(e.target.value);
		} else {
			const indexOfArray = tempArray[index].addOns.indexOf(e.target.value);
			tempArray[index].addOns.splice(indexOfArray, 1);
		}
		setNumberPlans(tempArray);
		setExistingNumberData({
			...ExistingNumberData,
			more_numbers_plans: numberPlans
		});
	};

	const handleSelectCategory = (id, rowIndex) => {
		const tempArray = [...numberPlans];
		tempArray[rowIndex].planCategoryId = id;
		// let rateListByCategory = tempArray[rowIndex].rateList.filter(
		// 	rate => rate.category == id
		// );
		// console.log(r);
		setNumberPlans(tempArray)
	};

	const handleChange = ({ target: { dataset, value } }) => {
		console.log(dataset, value);
		const tempArray = [...numberPlans];

		tempArray[dataset.index].planId = dataset.planId;
		tempArray[dataset.index].planRateId = value;
		// // // const planRateId = tempArray[index]['planList'].filter(value => value.id === id)[0]?.plans_rates[0]?.id
		// tempArray[index].planRateId = planRateId
		setNumberPlans(tempArray);
		setExistingNumberData({
			...ExistingNumberData,
			more_numbers_plans: tempArray
		});
	};

	const responsive = {
		superLargeDesktop: {
			// the naming can be any, depends on you.
			breakpoint: { max: 4000, min: 3000 },
			items: 5
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 3
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 2
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1
		}
	};

	// console.log(numberPlans, " set number plans");

	useEffect(() => {
		setExistingNumberData({ ...ExistingNumberData, ready: false, error: [] });
		ExistingNumberData.more_numbers_plans?.forEach(element => {
			if (element.planId) {
				if (element.planId && element.planRateId) {
					setExistingNumberData({
						...ExistingNumberData,
						ready: true,
						error: []
					});
				} else {
					setExistingNumberData({
						...ExistingNumberData,
						ready: false,
						error: ["Select plan"]
					});
				}
			}
		});

		const fetchData = async () => {
			await dispatch(getAddOns()).then(response => {
				setAddOns(response.result);
			});
		};
		fetchData();
	}, [ExistingNumberData.more_numbers_plans]);

	return (
		<>
			{
				// <PlanRatesMoreNumber show={modal.open} radioIndex={modal.radioIndex} data={modal.data} setShow={() => setModal({ open: false, data: [] })} />
			}
			<div className="step select_number_packages">
				<h5 className="mb-2 sm-heading">
					WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS
				</h5>
				<h2 className="lg-heading">
					Select Packages and Addons for Your Additional Numbers
				</h2>
				<div className="">
					{!numberPlans ? (
						<MiniLoader />
					) : (
						numberPlans?.map((value, index) => {
							// return value?.rateList?.filter(
							// 	value => value.rateList.length > 0
							// ) ? (
							return (
								<>
									<Button
										className={
											open === index
												? "more_number_button px-0 button_collapse"
												: "more_number_button px-0"
										}
										onClick={() => openHandler(index)}
										aria-controls="example-collapse-text1"
									>
										<h3 className="changeLinknumber1 m-0">
											<span>{index + 1}</span>
											<img
												alt=""
												src="/images/forms/united-states.png"
												className="mx-2 number_count_icon"
											/>
											{formatPhone(value.number)}
											<img
												alt=""
												src="/images/forms/collapse-arrow.png"
												className="collapse_arrow"
											/>
										</h3>
									</Button>
									<Collapse in={open === index}>
										<div className="more_number_parent_div">
											<div id="example-collapse-text1">
												<h3>
													Select A Category to view the available Plan options:
												</h3>
												<div className="row new_plans_box px-3">
													{categoryList?.length > 0 ? (
														categoryList?.map(categoryRow => {
															return (
																<>
																	{categoryList.length === 0 ? (
																		<></>
																	) : (
																		<div
																			className="col cstm_width"
																			onClick={() =>
																				handleSelectCategory(
																					categoryRow?.id,
																					index
																				)
																			}
																		>
																			<div
																				className={
																					"card mb-4 rounded-4 shadow-sm plans_card" +
																					(numberPlans[index]
																						?.planCategoryId === categoryRow.id
																						? " active"
																						: "")
																				}
																				onClick={() =>
																					handleSelectCategory(
																						categoryRow?.id,
																						index
																					)
																				}
																			>
																				<i
																					className="fa fa-check selected-check"
																					aria-hidden="true"
																				/>
																				<div
																					className={"card-header text-center"}
																				>
																					
																						<img
																							className="category-icon mt-3 mb-4"
																							src="/images/phone.png"
																							alt=""
																							height={34}
																						/>
																					<h4 className="my-0 fw-bold font-18 mb-2 text-uppercase">
																						{categoryRow?.name}
																					</h4>
																				</div>
																				<div className="card-body">
																					<p className="text-center">
																						Starting from
																					</p>
																					<h1 className="card-title pricing-card-title fw-bold font-30 d-flex justify-content-center  align-items-md-start">
																						${categoryRow?.least_price}
																						<small className="power-text fw-bold text-uppercase ">
																							/mo
																						</small>
																					</h1>
																					{/* <label className={"text-uppercase btn select_pln" + (selectedPlan === categoryRow.id ? " checked " : "")}>View Plans</label> */}
																				</div>
																				<input
																					type="radio"
																					className="btn-check"
																					id="btn-check-1"
																					name="plans_select"
																					onClick={() =>
																						handleSelectCategory(
																							categoryRow?.id,
																							index
																						)
																					}
																				/>
																			</div>
																		</div>
																	)}
																</>
															);
														})
													) : (
														<div className="half-screen-loader">
															<MiniLoader />
														</div>
													)}
												</div>

												<>
													{value?.rateList?.length > 0 ? (
														<h3 className="">
															Please Select a Plan Below For The Selected
															Category:
														</h3>
													) : (
														<></>
													)}
												</>

												<Carousel
													responsive={responsive}
													activeIndex={carouselIndex}
													onSelect={handleSelect}
												>
													{console.log(value, ">>row")}
													{value?.rateList?.length > 0 ? (
														value?.rateList
															.filter(f => f.category == value.planCategoryId)
															.map((row, i) => {
																
																let selectedRate = value?.planRateId;
																return (
																	<>
																		<div className="col p- mb-4 selecotr-item">
																			<input
																				value={row.id} // plan_rate_id
																				onChange={handleChange}
																				type="radio"
																				id={"radio" + i}
																				name="plan_price"
																				className="selector-item_radio"
																				data-plan-id={row.plan_id} // planid
																				data-index={index}
																				checked={selectedRate == row.id}
																			/>
																			<label
																				htmlFor={"radio" + i}
																				className={
																					selectedRate == row.id
																						? "selector-item_label checked"
																						: "selector-item_label"
																				}
																			>
																				<i
																					className="fa fa-check selected-check"
																					aria-hidden="true"
																				/>
																				<div className="option-box">
																					<h2 className="p-3 mb-0">
																						Option {i + 1}
																					</h2>
																					<div className="p-4 pt-0 pb-0 box-content">
																						<div className="row-col align-items-center d-flex justify-content-between">
																							<strong className="text-uppercase">
																								Porting fee
																							</strong>
																							<span>
																								{parseInt(row?.porting_price) >
																									0
																									? "$" + row?.porting_price
																									: "None"}
																							</span>
																						</div>
																						{
																							row?.minutes > 0 &&
																							<div className="box-description">
																								<strong className='text-uppercase d-flex justify-content-between'><span>Minutes:</span> <span>{row?.minutes}</span></strong>
																							</div>
																						}
																						<div className="box-description">
																							<strong className="text-uppercase">
																								Description
																							</strong>
																							<div className="white-bg-text">
																								{row?.description}{" "}
																							</div>
																						</div>
																						<div className=" p-2 price-div d-flex justify-content-center align-items-start">
																							<strong className="plan-price">
																								${row?.price}{" "}
																							</strong>
																							<span className="plan-duration">
																								{" "}
																								per{" "}
																								{row?.period !== 1 &&
																									row?.period}{" "}
																								{row?.period_type}
																							</span>
																						</div>
																						<div
																							className="text-danger"
																							style={{ fontSize: 10 }}
																						>
																							Including all Taxes & Fees.
																						</div>
																					</div>
																				</div>
																			</label>
																		</div>
																	</>
																);
															})
													) : (
														<></>
													)}
												</Carousel>
												<div className="col">
													{/* {
														rates.length > 0 &&
														// <MultiCarousel items={rates} />
													} */}
												</div>
												<h3>Optional AddOns:</h3>
												<div className="row pb-3 order-addons-card-more">
													{addOns?.map((addOn, index) => {
														return (
															<div className="col-lg-3 my-3 text-center">
																<label className="card-addons">
																	<input
																		className="card__input"
																		type="checkbox"
																		defaultChecked={value?.addOns?.includes(
																			addOn?.title
																		)}
																		value={addOn?.title}
																		onChange={e =>
																			addOnChangeHanlder(
																				!value?.addOns?.includes(addOn?.title),
																				index,
																				e
																			)
																		}
																	/>
																	<div className="card__body">
																		<div className="card__body-cover">
																			<img
																				alt=""
																				className="addon-pkg-image"
																				src="/images/forms/xpressPorting.png"
																			/>
																			<span className="card__body-cover-checkbox">
																				<svg
																					className="card__body-cover-checkbox--svg"
																					viewBox="0 0 12 10"
																				>
																					<polyline points="1.5 6 4.5 9 10.5 1" />
																				</svg>
																			</span>
																			<span className="card__body-cover-checkbox2">
																				<svg
																					className="card__body-cover-checkbox--svg"
																					viewBox="0 0 12 10"
																				/>
																			</span>
																		</div>
																		<header className="card__body-header">
																			<h2 className="card__body-header-title">
																				{addOn?.title}
																			</h2>
																			<p className="card__body-header-subtitle mb-0">
																				{addOn?.price}$
																				{addOn?.period_type !== "onetime" &&
																					` / ${capitalize(
																						addOn?.period_type
																					)}`}
																			</p>
																		</header>
																	</div>
																</label>
															</div>
														);
													})}
												</div>
												<div className="row pb-3 justify-content-centerrow text-center mb-3 justify-content-center">
													<a
														href="#"
														className="proceed_collapse_button"
														aria-controls="example-collapse-text1"
														aria-expanded={open}
														onClick={() => openHandler(index + 1)}
													>
														Next
													</a>
												</div>
											</div>
										</div>
									</Collapse>
								</>
							);
							// ) : (
							// 	<></>
						})
					)}
				</div>
				<FormFooter
					action={"save-plan-bulk"}
					payloadForUpdate={numberPlans}
					activeStep={activeStep}
					setStep={setStep}
					addNumber={true}
				/>
			</div>
		</>
	);
};

export default AddingNumbers;
