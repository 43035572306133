import React, { createContext, useEffect, useReducer } from 'react'
import axios from '../axios'
import Loading from 'components/Loader/Loading'
import { toast } from 'react-toastify'
import history from '../history'
import moment from 'moment'

const initialState = {
	isAuthenticated: false,
	isInitialised: false,
	fetchUserData: false,
	user: null,
}

const setSession = (accessToken) => {
	if (accessToken) {
		localStorage.setItem('accessToken', accessToken)
		axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
	} else {
		localStorage.removeItem('accessToken')
		delete axios.defaults.headers.common.Authorization
	}
}

const setSessionId = (id) => {
	if (id) {
		localStorage.setItem('id', id);
	} else {
		localStorage.removeItem('id')
	}
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'INIT': {
			const { isAuthenticated, user } = action.payload

			return {
				...state,
				isAuthenticated,
				isInitialised: true,
				user,
			}
		}
		case 'LOGIN': {
			const { user } = action.payload

			return {
				...state,
				isAuthenticated: true,
				user,
			}
		}
		case 'LOGOUT': {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			}
		}
		case 'USER_VERIFICATION': {
			return {
				...state,
				user: { ...state.user, email_verified_at: moment().format("YYYY-MM-DD HH:mm:ss") },
			}
		}
		case 'USER_FETCH_DATA': {
			return {
				...state,
				fetchUserData: !state.fetchUserData
			}
		}
		case 'USER_VERIFICATION_PHONE': {
			return {
				...state,
				user: { ...state.user, phone_verified_at: moment().format("YYYY-MM-DD HH:mm:ss") },
			}
		}
		default: {
			return { ...state }
		}
	}
}

const AuthContext = createContext({
	...initialState,
	method: 'JWT',
	login: () => Promise.resolve(),
	signup: () => Promise.resolve(),
	signupViaCoverage: () => Promise.resolve(),
	socialLogin: () => Promise.resolve(),
	socialLoginCallback: () => Promise.resolve(),
	forgotPassword: () => Promise.resolve(),
	verifyCode: () => Promise.resolve(),
	changePassword: () => Promise.resolve(),
	verifyUser: () => Promise.resolve(),
	checkUserVerification: () => Promise.resolve(),
	refreshUser: () => Promise.resolve(),
	logout: () => { },

})

export const AuthProvider = ({ children }) => {

	const [state, dispatch] = useReducer(reducer, initialState)

	const socialLogin = async (provider, action) => {
		const { data } = await axios.get(`authorize/${provider}/${action}/redirect`);
		localStorage.setItem('socialAction', data.action);
		window.location.href = data.url;
	}

	const socialLoginCallback = async (provider, action, params) => {
		return await axios.get(`authorize/${provider}/${action}/callback${params}`).then(response => {
			if (action === "signup") {
				return response.data;
			} else {

				const userResult = response.data.result;
				const accessToken = userResult.token;
				if (userResult.avatar == null) {
					userResult.avatar = '/assets/images/face-7.jpg'
				}
				const user = {
					id: userResult.id,
					avatar: userResult.avatar,
					email: userResult.email,
					first_name: userResult.first_name,
					last_name: userResult.last_name,
					name: userResult.name,
					role: userResult.role,
					activeSocialFormat: provider,
					token: accessToken,
					email_verified_at: userResult.email_verified_at,
					phone_verified_at: userResult.phone_verified_at,
					permissions: userResult.permissions,
					user_keys: userResult.user_keys,
					payment_profiles: userResult.payment_profiles
				};

				setSession(accessToken)
				setSessionId(userResult.id);
				dispatch({
					type: 'LOGIN',
					payload: {
						user,
					},
				})
				history.push('/');
				// toast.success("Welcome!");
			}
		}).catch(err => {
			toast.error(err.message)
			// history.push('/login')
		});

	}

	const login = async (email, password, isEncrypted) => {
		
		let response = null;

		if(isEncrypted) {
			response = await axios.post('login-customer', {
				email,
				password,
				isEncrypted
			});
		} else {
			response = await axios.post('login-customer', {
				email,
				password
			});
		}

		const userResult = response.data.result;
		const accessToken = userResult.token;

		if (userResult.avatar == null) {
			userResult.avatar = '/assets/images/face-7.jpg'
		}

		const user = {
			id: userResult.id,
			avatar: userResult.avatar,
			email: userResult.email,
			first_name: userResult.first_name,
			last_name: userResult.last_name,
			name: userResult.name,
			role: userResult.role,
			email_verified_at: userResult.email_verified_at,
			phone_verified_at: userResult.phone_verified_at,
			token: accessToken,
			permissions: userResult.permissions,
			user_keys: userResult.user_keys,
			payment_profiles: userResult.payment_profiles
		};

		setSession(accessToken)
		setSessionId(userResult.id);
		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		})
		toast.success("Welcome!");
	}

	const signup = async (data) => {

		const response = await axios.post('signup', data);
		const userResult = response.data.result
		const accessToken = userResult.token;

		if (userResult.avatar == null) {

			userResult.avatar = '/assets/images/face-7.jpg'
		}

		const user = {
			id: userResult.id,
			avatar: userResult.avatar,
			email: userResult.email,
			first_name: userResult.first_name,
			last_name: userResult.last_name,
			name: userResult.name,
			role: userResult.role,
			token: accessToken,
			email_verified_at: userResult.email_verified_at,
			phone_verified_at: userResult.phone_verified_at,
			permissions: userResult.permissions,
			user_keys: userResult.user_keys,
			payment_profiles: userResult.payment_profiles
		};

		setSession(accessToken)
		setSessionId(userResult.id);

		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		})
		toast.success("Account Created! Welcome to our System!");
		return true;
	}
	const signupViaCoverage = async (response) => {

		const userResult = response
		const accessToken = userResult.token;

		if (userResult.avatar == null) {

			userResult.avatar = '/assets/images/face-7.jpg'
		}

		const user = {
			id: userResult.id,
			avatar: userResult.avatar,
			email: userResult.email,
			first_name: userResult.first_name,
			last_name: userResult.last_name,
			name: userResult.name,
			role: userResult.role,
			token: accessToken,
			email_verified_at: userResult.email_verified_at,
			phone_verified_at: userResult.phone_verified_at,
			permissions: userResult.permissions,
			user_keys: userResult.user_keys,
			payment_profiles: userResult.payment_profiles
		};

		setSession(accessToken)
		setSessionId(userResult.id);

		dispatch({
			type: 'LOGIN',
			payload: {
				user,
			},
		})

		history.push('/customer/dashboard')
	}

	const logout = async () => {

		const config = {
			headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` }
		};

		await axios.post('logout', {}, config).then((resp) => console.log(String.fromCodePoint(9996) + ' BYE!')).catch((resp) => console.log(resp));
		setSessionId(null);
		setSession(null)
		// const userResult = response.data.result
		// console.log(userResult);
		dispatch({ type: 'LOGOUT' })
		toast.success("Logged out");
	}

	const forgotPassword = async (data) => {
		const response = await axios.post('forgot-password', data)
		const userResult = response.data.result;
		return userResult;
	}

	const changePassword = async (data) => {
		const response = await axios.post('update-password-by-email', data)
		const userResult = response.data;
		return userResult;
	}

	const verifyCode = async (data) => {
		const result = await axios.post('verify-password-otp', data)
		const userResult = result.data;
		return userResult;
		// return userResult;
	}

	const verifyUser = async (data) => {

		const result = await axios.post('verify-user', data)
		const userResult = result.data;
		return userResult;
	}

	const checkUserVerification = async (data) => {

		if (data.type === 'email') {

			const result = await axios.post('check-verification', { code: data.code, email: data.email })
			const userResult = result.data;

			if (userResult.bool) {

				dispatch({ type: 'USER_VERIFICATION' })
			}

			return userResult;

		} else if (data.type === 'phone') {

			const result = await axios.post('check-verification', { code: data.code, phone: data.phone })
			const userResult = result.data;

			dispatch({ type: 'USER_VERIFICATION_PHONE' })

			return userResult;

		} else {
			return "Select type first"
		}

	}

	const refreshUser = () => {
		dispatch({ type: 'USER_FETCH_DATA' })
	}

	useEffect(() => {
		; (async () => {
			try {
				const accessToken = window.localStorage.getItem('accessToken')
				const id = window.localStorage.getItem('id')

				if (accessToken) { // && isValidToken(accessToken)
					setSession(accessToken)

					const response = await axios.post('auth-user', { 'id': id })
					let user = response.data.result;
					user['token'] = accessToken;
					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: true,
							user,
						},
					})
				} else {
					dispatch({
						type: 'INIT',
						payload: {
							isAuthenticated: false,
							user: null,
						},
					})
				}
			} catch (err) {
				console.error(err)
				dispatch({
					type: 'INIT',
					payload: {
						isAuthenticated: false,
						user: null,
					},
				})
			}
		})()
	}, [state.fetchUserData])

	if (!state.isInitialised) {
		return <Loading />
	}

	return (
		<AuthContext.Provider
			value={{
				...state,
				method: 'JWT',
				socialLogin,
				login,
				signup,
				signupViaCoverage,
				logout,
				socialLoginCallback,
				verifyCode,
				changePassword,
				forgotPassword,
				verifyUser,
				checkUserVerification,
				refreshUser
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}

export default AuthContext
