/* eslint-disable no-unused-vars */
import { SignupContext } from "contexts/FormsContext";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import ValidationError from "./ValidationError";
import { useHistory } from "react-router-dom";
// import FormFooter from "./FormFooter";

// import { OrderContext } from "contexts/OrderContext";

const AccountFor = () => {
	//   let [activeFormat, setFormat] = useState('')
	const { signupData, setSignupData } = useContext(SignupContext)
	const history = useHistory()
	const { register, handleSubmit, watch, formState: { errors, isValid, isDirty } } = useForm({
		defaultValues: {
			"accountFor": signupData?.accountFor || "",
		}
	});

	const submitHandler = (e) => {
		setSignupData({ ...signupData, ...e })
		// history.push('/signup/add-more-people')
		history.push('/signup/goals')
	}

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">PORTABILITY CHECK</h5>
					<h2 className="lg-heading mb-5">To complete your registration please<br />let us know if this account is for</h2>
					<div className="row mb-4">
						<div className="col">
							<select name="accountFor" className="orderDropdown" {...register('accountFor', { required: "Please Select a valid option" })}>
								<option value="">Select</option>
								<option value="ParkMyPhone">Mobile Number</option>
								{/* <option selected={form.accountFor === 'ParkMyPhone'} value="ParkMyPhone">Mobile Number</option> */}
								<option value="ParkMyCellPhone">Landline</option>
								{/* <option selected={form.accountFor === 'ParkMyCellPhone'} value="ParkMyCellPhone">Landline</option> */}
								<option value="ParkMyFax">Fax</option>
								{/* <option selected={form.accountFor === 'ParkMyFax'} value="ParkMyFax">Fax</option> */}
								<option value="ParkMy800">Toll Free</option>
								{/* <option selected={form.accountFor === 'ParkMy800'} value="ParkMy800">Toll Free</option> */}
							</select>
						</div>
					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<input type="submit" className="proceed-btn mb-2" value="Proceed" />
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => history.goBack()} className={"prev-step elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}

				</form>
			</div >
		</>
	)
}

export default AccountFor
