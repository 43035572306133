import {
    GET_CARRIERS,
    CREATE_CARRIERS
} from '../actions/CarrierAction'

const initialState = []


const CarriersReducer = function (state = initialState, action) {
    switch (action.type) {
        case GET_CARRIERS: {
            return [...action.payload]
        }
    case CREATE_CARRIERS: {
            return [...action.payload]
        }    
        default: {
            return [...state]
        }
    }
}

export default CarriersReducer