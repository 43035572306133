import useAuth from 'hooks/useAuth';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify'

const SingleSignOn = () => {

  const { login } = useAuth();

  const params = new URLSearchParams(window.location.search)
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const token = params.get('token')

  const submitHandler = async (form) => {
    setLoading(true)
    try {
      await login(form.email, form.pass, true);

      history.push('/customer/dashboard')

    } catch (e) {
      toast.error(e.message)
      console.log(e);
      setLoading(false)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (token) {
      try {
        // Attempt to decode the token
        const decodedToken = atob(token);
        const data = decodedToken.split(':');

        if (data.length !== 2) {
          throw new Error('Invalid token format');
        }

        const [email, pass] = data;
        submitHandler({ email, pass });
      } catch (e) {
        if (e instanceof DOMException) {
          toast.error('Failed to decode token: Invalid encoding');
        } else {
          toast.error('Invalid token format');
        }
        console.error('Token decoding failed:', e);

        history.push('/')
      }
    }
  }, [token]);


  return (
    <div>
      {
        loading ?
          <Container className='min-vh-100'>
            <Row>
              <Col className='mt-5 pt-5'>
                <Card className="text-center p-5 shadow">
                  <div className="d-flex justify-content-center">
                    {loading &&
                      <div className="spinner-grow text-primary mb-5 text-dark" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    }
                  </div>
                  <h2 className='align-middle'>Please Wait</h2>
                  <p>System will automatically redirect you...</p>
                </Card>
              </Col>
            </Row>
          </Container>
          :
          <></>
      }
    </div>
  )
}

export default SingleSignOn