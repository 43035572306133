import React, { useState } from "react";

import { CoverageContext } from "contexts/FormsContext";
import { useContext } from "react";
import FormFooter from "./FormFooter";

const SignupChoices = ({ setStep, activeStep, prevStep }) => {
  let { coverage } = useContext(CoverageContext)
  let [activeFormat, setFormat] = useState(coverage.activeSocialFormat)

  const setActiveSocialFormat = (e) => {

    coverage.activeSocialFormat = e;
    setFormat(() => activeFormat = e)
  }
  return (
    <div className="step">
      <h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
      <h2 className="lg-heading mb-5">Sign Up With</h2>
      <div className="row">
        <div className="col">
          <div className="signupbutton_content">
            <div className="row-f">
              <div className="cat col-4 action">
                <label>
                  <input type="radio" className="social_check" id="social_check_fb" value="azure" name="signup_method" onChange={(e) => setActiveSocialFormat(e.target.value)} defaultChecked={activeFormat === "azure"} />
                  <span><img src="/images/forms/microsoft.ico" alt="" />Microsoft</span>
                </label>
              </div>
              <div className="cat col-4 action">
                <label>
                  <input type="radio" className="social_check" id="social_check_google" value="google" name="signup_method" onChange={(e) => setActiveSocialFormat(e.target.value)} defaultChecked={activeFormat === "google"} />
                  <span><img src="/images/forms/icon-google.png" alt="" />Google</span>
                </label>
              </div>
              <div className="cat col-4 action">
                <label>
                  <input type="radio" className="social_check" id="social_check_apple" value="apple" name="signup_method" onChange={(e) => setActiveSocialFormat(e.target.value)} defaultChecked={activeFormat === "apple"} />
                  <span><img className="black" src="/images/forms/icon-apple.png" alt="" />Apple</span>
                </label>
              </div>
            </div>
            <div className="email-signup text-center">
              <p className="text my-4 OR">OR</p>
              <div className="cat action">
                <label>
                  <input type="radio" className="social_check" id="social_check_apple" value="email" name="signup_method" onChange={(e) => setActiveSocialFormat(e.target.value)} defaultChecked={activeFormat === "email"} />
                  <span><img className="black" src="/images/forms/email.png" alt="" />Signup with Email</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FormFooter activeStep={activeStep} setStep={setStep} />
    </div>
  )
}

export default SignupChoices
