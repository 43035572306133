import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import Initial from './sub-components/coverage-components/Initial'
import Success from './sub-components/signup-components/Success'
import './assets/css/order.css'
import './assets/css/general.css'
import './assets/css/responsive.css'
import React, { useState, useRef, useEffect } from 'react'
import { CoverageProvider } from 'contexts/FormsContext'
import AddingNumbers from './sub-components/coverage-components/AddingNumbers'
import CoverageFound from './sub-components/coverage-components/CoverageFound'
import SignupChoices from './sub-components/coverage-components/SignupChoices'
import AccountInformation from './sub-components/coverage-components/AccountInformation'
import PhoneVerification from './sub-components/coverage-components/PhoneVerification'
import Dropdown from './sub-components/coverage-components/Dropdown'
import SimpleQuestion from './sub-components/coverage-components/SimpleQuestion'
import Goals from './sub-components/coverage-components/Goals'

const Coverage = () => {


	const [activeStep, setActiveStep] = useState(0)
	const [error, setError] = useState()
	const [processing, setProcessing] = useState(false)
	const [ready, setReady] = useState(false)
	const [maxStepReached, setMaxStepReached] = useState(0)
	const [audio, setAudio] = useState(true)
	const prevStep = useRef();

	const setStep = (e) => {
		if (e >= 0) {
			setMaxStepReached(e)
			setActiveStep(e);
			// setMaxStepReached = e > maxStepReached ? setMaxStepReached(e) : maxStepReached
			// console.log("previous step count " + prevStep.current)
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setStep(activeStep + 1)
		}
	}

	const stepTitle = [
		"Info",
		"Numbers",
		"Status",
		"Signup Choices",
		"Account Details",
		"Verification",
		"Preference",
		"Add More People",
		"Goals",
	]

	const markupForStepsCounter = () => {
		const maxSteps = 9;
		let stepsCounterMarkup = [];

		for (let index = 0; index < maxSteps; index++) {
			stepsCounterMarkup.push(
				<li
					data-title={stepTitle[index]}
					key={index}
					className={(index === activeStep ? "active " : index < activeStep ? "accessable active " : maxStepReached >= index ? "accessable " : "") + "navigationItem elaboration bottom"}
					onClick={() => index === activeStep ? setStep(index) : index < activeStep ? setStep(index) : maxStepReached >= index ? setStep(index) : ""}
				>
				</li>
			);
		}
		return stepsCounterMarkup;
	};

	useEffect(() => {
		//assign the ref's current value to the count Hook
		prevStep.current = activeStep;
		setReady(false)
	}, [activeStep]);



	return (
		<>
			<Title title={"Coverage Form"} />
			<Topbar />
			<img src="./images/forms/girl-with-teeth.png" alt="" className="girl-phone" />
			<div className="stepsnavigation">
				{markupForStepsCounter()}
			</div>
			<div className={(audio ? '' : 'disabled ') + 'audioToggle'} onClick={() => setAudio(!audio)} >
				<i className={audio ? 'fa fa-volume-up' : 'fa fa-volume-off'}></i>
			</div>
			<div className="coverage_body">
				<form id="example-advanced-form" className="main wizard clearfix signup" onKeyDown={handleKeyDown}>
					<CoverageProvider>
						{error &&
							<div className="crossmark-wrapper">
								<span className="message">{error}</span>
								<svg className="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
									<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
									<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
								</svg>
							</div>
							// <div className="crossmark-wrapper">
							// 	<span className="message">Please correct errors below</span>
							// 	<svg className="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
							// 		<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
							// 		<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
							// 		<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
							// 	</svg>
							// </div>
						}
						{ready &&
							<div className="checkmark-wrapper">
								<span className="message">Ready To Go</span>
								<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
									<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
								</svg>
							</div>
						}
						{processing &&
							<div className="circlemark-wrapper">
								<span className="message">Processing the form please wait</span>
								<svg className="circlemark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle className="circlemark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
									<circle className="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3"></circle>
								</svg>
							</div>
						}

						{activeStep === 0 &&
							<Initial setError={setError} setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 1 &&
							<AddingNumbers setError={setError} setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 2 &&
							<CoverageFound setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 3 &&
							<SignupChoices setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 4 &&
							<AccountInformation  setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} />
						}
						{activeStep === 5 &&
							<PhoneVerification  setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} />
						}
						{activeStep === 6 &&
							<Dropdown setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 7 &&
							<SimpleQuestion setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} heading={"Would you like to add more people to your account"} option1={"YES"} option2={"NO"} stepKey={"add-more-people-to-account"} />
						}
						{activeStep === 8 &&
							<Goals setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
						{activeStep === 9 &&
							<Success setProcessing={setProcessing} activeStep={activeStep} setStep={setStep} />
						}
					</CoverageProvider>

				</form>
			</div>
			<Footer />
		</>
	)
}

export default Coverage
