import React, { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { accountCompletionStatus } from 'redux/actions/UserAction'

const Sidebar = ({ toggle = true }) => {
  const { pathname } = useLocation()
  const [openSettings, setOpenSettings] = useState(pathname === "/customer/make-changes");
  const [isAlive, setIsAlive] = useState(true)
  let [status, setStatus] = useState(false);
	const dispatch = useDispatch();

  const settingToggle = (e) => {
    e.stopPropagation();
    setOpenSettings(!openSettings)
  }
  const fetchStatus = async () => {
    await dispatch(accountCompletionStatus()).then(response => {
      if (response < 100) {
        setStatus(true)
      }
    })
  };
  useEffect(() => {
    if (isAlive) {
      fetchStatus()
      setIsAlive(false)
    }
    setOpenSettings(pathname === "/customer/make-changes")
  }, [pathname])

  return (
    <div className={toggle ? "sidebar sidebar-dark sidebar-fixed show" : "sidebar sidebar-dark sidebar-fixed hide"} id="sidebar">
      <div className="sidebar-brand d-none d-lg-flex">
        <img className="sidebar-brand-full" src="/admin/images/logo-lg.png" alt="" />
        <img className="sidebar-brand-narrow" src="/admin/images/logo-sm.png" alt="" />
      </div>
      <ul className="sidebar-nav" data-coreui="navigation">
        <li className="nav-item place-order-container">
          <Link className="place-order-btn" to="/order">
            <img className="icon-white" src="/admin/images/icons/icon-place-order.png" alt="" />
            <img className="icon-orange" src="/admin/images/icons/icon-place-order-orange.png" alt="" />
            Place New Order
          </Link>
        </li>
        <li className="nav-item">
          <Link className={pathname === "/customer/dashboard" || pathname === "/" ? "nav-link active" : "nav-link"} to="/">Dashboard</Link>
        </li>
        <li className="nav-item">
          <Link className={pathname === "/customer/services" ? "nav-link active" : "nav-link"} to="/customer/services">My Services</Link>
        </li>
        <li className={status == true ? "nav-item blink_me" : "nav-item"}>
          <Link className={pathname === "/customer/profile" ? "nav-link active" : "nav-link"} to="/customer/profile">My Account</Link>
        </li>
        <li className="nav-item">
          <Link className={pathname === "/customer/my-orders" ? "nav-link active" : "nav-link"} to="/customer/my-orders">My Order</Link>
        </li>
        <li className="nav-item">
          <Link className={pathname === "/customer/payments" ? "nav-link active" : "nav-link"} to="/customer/payments">Payments</Link>
        </li>
        <li className="nav-item">
          <Link className={pathname === "/coverage" ? "nav-link active" : "nav-link"} to="/coverage">Coverage</Link>
        </li>

        {/* <li className={openPaymentDetails ? "nav-group show" : "nav-group"} onClick={paymentToggle}>
					<Link className="nav-link nav-group-toggle" to="/">
						Payment Details
					</Link>
					<ul className="nav-group-items">
						<li onClick={(e) => e.stopPropagation()} className="nav-item"><Link className="nav-link" to="/">Item</Link></li>
						<li onClick={(e) => e.stopPropagation()} className="nav-item"><Link className="nav-link" to="/">Item</Link></li>
						<li onClick={(e) => e.stopPropagation()} className="nav-item"><Link className="nav-link" to="/">Item</Link></li>
					</ul>
				</li> */}
        <li className="nav-item">
          <a className="nav-link" rel={'noreferrer'} target={"_blank"} href="https://myaccount.parkmyphone.com/index.php?page=contact_us">Help & Support</a>
        </li>
        <li className={openSettings ? "nav-group show" : "nav-group"} onClick={settingToggle}>
          <Link className="nav-link nav-group-toggle" to="#">
            Settings
          </Link>
          <ul className="nav-group-items">
            <li onClick={(e) => e.stopPropagation()} className="nav-item">
              <Link className={pathname === "/customer/payments" ? "nav-link active" : "nav-link"} to="/customer/make-changes">Make Changes</Link>
            </li>
          </ul>
        </li>
      </ul>
      {/* <button className="sidebar-toggler" type="button" data-coreui-toggle="unfoldable"></button> */}
    </div>
  )
}

export default Sidebar
