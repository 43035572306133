import RoundedButton from 'components/Buttons/RoundedButton'
import { ExistingNumberContext } from 'contexts/FormsContext'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { coverageSignUp } from 'redux/actions/CoverageActions'
import ValidationError from '../ValidationError'
import { Link } from 'react-router-dom'

const PublicUserInfo = ({ setStep, activeStep }) => {

	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const dispatch = useDispatch()
	const [duplicateEmailError, setDuplicateEmailError] = useState(false)
	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, formState: { errors, isValid, }, setError, clearErrors } = useForm({
		defaultValues: {
			'firstname': ExistingNumberData?.firstname || "",
			'lastname': ExistingNumberData?.lastname || "",
			'email': ExistingNumberData?.email || "",
		}
	});

	const submitHandler = async (e) => {
		setLoading(true)
		setExistingNumberData({
			...ExistingNumberData,
			'firstname': e.firstname,
			'lastname': e.lastname,
			'email': e.email,
			'error': [],
			'ready': true
		})

		const payload = {
			"first_name": e.firstname,
			"last_name": e.lastname,
			"email": e.email,
			"password": "@TempPwd123",
		}
		await dispatch(coverageSignUp(payload)).then(response => {
			if (response.bool) {
				setExistingNumberData({
					...ExistingNumberData,
					'userData': response.result,
					'user_id': response?.result?.id,
					processing: false,
					ready: false,
					'firstname': e.firstname,
					'lastname': e.lastname,
					'email': e.email
				})
				setLoading(false)
				setStep(1) // will return the same step
			}
		}).catch(errr => {
			if (errr.message.includes('email has already been taken')) {
				setDuplicateEmailError(true)
				setExistingNumberData({ ...ExistingNumberData, processing: false, ready: false })
				setError('email', {
					message: 'This Email has already been taken'
				})
			} else {
				setExistingNumberData({ ...ExistingNumberData, error: [errr.message], processing: false, ready: false })
				toast.error(errr.message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
			}
			setLoading(false)
		})
	}

	useEffect(() => {
		if (isValid && Object.keys(errors).length !== 0) {
			clearErrors();
			setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: true })
		}
	}, [isValid, errors])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>

					<h5 className="mb-2 sm-heading">ORDER</h5>
					<h2 className="lg-heading mb-5">You're almost there! Let's start by telling us your name and email</h2>
					<div className="row mb-lg-4">
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										className={errors.firstname ? "error input-field" : " input-field"}
										placeholder="First Name"
										{...register("firstname", { 'required': 'First name is requried', 'maxLength': { value: 12, message: "Max length is 12" }, pattern: { message: "Invalid input", value: /^[a-zA-Z ]+$/ } })}
									/>
								</div>
							</div>
						</div>
						<div className="col-lg-6 col-12">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-user icon-c" aria-hidden="true"></i>
									<input
										className={errors.lastname ? "error input-field" : " input-field"}
										placeholder="Last Name"
										{...register("lastname", { 'required': 'Last name is requried', 'maxLength': { value: 12, message: "Max length is 12" }, pattern: { message: "Invalid input", value: /^[a-zA-Z ]+$/ } })}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="coverage-field">
								<div className="input-container">
									<i className="fa fa-at icon-c" aria-hidden="true"></i>
									<input
										type={"email"}
										className={errors.email ? "error input-field" : " input-field"}
										placeholder="Email"
										{...register("email", {
											'required': 'Email is requried',
											'pattern': {
												value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
												message: "Email is not valid"
											}
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col">
							<div className="text-danger">
								{
									duplicateEmailError && <>
										The email address you entered already exists in our system, please <Link to='/login?destination=order' className='link-primary'>click here</Link> to login if you are an existing customer.
									</>
								}
							</div>
						</div>
					</div>
					{/* From Footer */}
					<>
						<div className={"footer"} >
							{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} showLoader={false}>
									Proceed
								</RoundedButton>
								<div className="pe">
									Press Enter
									<img src="/images/forms/icon-enter.png" alt="" />
								</div>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default PublicUserInfo
