import Coverage from './Coverage';
import Order from '../order/Order';
import SignupVerify from './SignupVerify';


const formRoutes = [
    {
        path: '/order',
        component: Order,
    },
    {
        path: '/coverage',
        component: Coverage,
    },
    {
        path: '/signup-verify',
        component: SignupVerify,
    },
];

export default formRoutes
