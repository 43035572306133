import axios from "../../axios"
export const GET_MY_ORDERS = 'GET_MY_ORDERS'
export const GET_ORDER_STATUS = 'GET_ORDER_STATUS'
export const GET_CATEGORIES_PLAN_RATES = 'GET_CATEGORIES_PLAN_RATES'
export const GET_PLAN_CATEGORIES = 'GET_PLAN_CATEGORIES'


export const orderCreate = (data, token) => (dispatch) => {
	return axios.post('orders/create', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const checkPortability = (data, token) => async () => {
	return axios.post('vendors/check-portability-vendor', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const orderDIDCreate = (data, token) => (dispatch) => {
	return axios.post('orders-did/create', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const orderUpdate = (data, token) => (dispatch) => {
	return axios.put('orders/update', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const verifyDid = (data, token) => (dispatch) => {
	return axios.put('orders-did/verify', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const orderDidUpdate = (data, token) => (dispatch) => {
	return axios.put('orders-did/update-plans', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const orderDidAddOn = (data, token) => (dispatch) => {
	return axios.put('orders-did/update-add-ons', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}
export const orderSignature = (data, token) => (dispatch) => {
	return axios.put('orders/order-attachment', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const orderPhoneBill = (data, token) => (dispatch) => {
	return axios.put('orders/order-attachment', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const getPlansForNewNumber = (data, token) => (dispatch) => {
	return axios.post('plans/for-new-number', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}
export const bulkPlanForDId = (data, token) => (dispatch) => {
	return axios.post('orders-did/plans-bulk-update', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const getPlansByCategory = (data) => async (dispatch) => {
	await axios.post('plans/by-category', data).then(resp => {
		dispatch({
			type: GET_PLAN_CATEGORIES,
			payload: resp.data.result
		})
	})
}

export const getPlansByCategoryWithRates = (data) => (dispatch) => {
	axios.post('plans/category/with-plan-rates', data).then(resp => {
		dispatch({
			type: GET_CATEGORIES_PLAN_RATES,
			payload: resp.data.result
		})
	})
}

export const savePaymentProfile = (data, token) => (dispatch) => {
	return axios.post('user/customer-payment-profile', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const updatePaymentProfile = (data, token) => async (dispatch) => {
	return axios.post('orders/order-payment-profile', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}
export const phoneNumberVerification = (data, token) => (dispatch) => {
	return axios.post('orders-did/send-verification', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data.phone;
	})
}

export const phoneNumberVerificationViaCall = (data, token) => (dispatch) => {
	return axios.post('orders-did/send-verification-via-call', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const checkVerificationAction = (data, token) => (dispatch) => {
	return axios.post('orders-did/check-verification', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const sendOrderEmailAction = (id, token) => (dispatch) => {
	return axios.post('orders/send-email/' + id, {}, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const getOrderHistory = (page) => async (dispatch) => {
	return axios.get(`orders/my-orders`).then(resp => {
		dispatch({
			type: GET_MY_ORDERS,
			payload: resp.data.result
		})
	})
}

export const getOrderStatus = (page) => async (dispatch) => {
	return axios.get(`order-status/all`).then(resp => {
		dispatch({
			type: GET_ORDER_STATUS,
			payload: resp.data.result
		})
	})
}

export const getAddOns = () => async (dispatch) => {
	return axios.get(`plans/add-ons-customer`).then(resp => {
		return resp.data
	})
}

export const saveTextMessageEmail = (data, token) => (dispatch) => {
	return axios.post('did/text-mesage', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const saveVoiceMail = (data, token) => (dispatch) => {
	return axios.post('did/voice-email', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}
export const saveForwardNumber = (data, token) => (dispatch) => {
	return axios.post('did/forward-number', data, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const deleteDidService = ({ service, id }, token) => async () => {
	// return axios.delete('did/delete-service/:service/:id', { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
	return axios.delete(`did/delete-service/${service}/${id}`, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const createOrderStepTracking = (payload, token) => (dispatch) => {
	return axios.post(`order-step-tracking`, payload, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const updateOrderStepTracking = (payload, id, token) => (dispatch) => {
	return axios.put(`order-step-tracking/${id}`, payload, { headers: { 'Authorization': `Bearer ${token}` } }).then(resp => {
		return resp.data;
	})
}

export const OrderStepDataById = (id, token) => async (dispatch) => {
	return axios.get(`order-step-tracking/${id}`).then(resp => {
		return resp.data;
	})
}
