import { GET_SERVICE_LIST } from "redux/actions/MakeChangesAction"


const initialState = {
	serviceList: [],
}

const MakeChangesReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_SERVICE_LIST:
			return {
				...state,
				serviceList: [...action.payload]
			}
		default:
			return { ...state }

	}
}

export default MakeChangesReducer
