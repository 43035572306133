import React, { useContext, useState, useEffect } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FormFooter from "./FormFooter";

const PortTime = ({ setStep, activeStep }) => {
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext);
	const twoDaysFromNow = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)
	const [startDate, setStartDate] = useState(twoDaysFromNow);
	const [minDate, setMinDate] = useState(twoDaysFromNow);
	const [schedule, setSchedule] = useState(ExistingNumberData.when_do_you_want_port_the_number?.option || "as_soon_as_possible");
	const [portDate, setPortDate] = useState(moment().add(1, "day").format("YYYY-MM-DD"));
	const [isAgree, setAgree] = useState(ExistingNumberData.port_number_is_agree || false);

	const isWeekday = (date) => {
		const day = date.getDay();
		return day !== 0 && day !== 6;
	};

	const handleTermsChange = (e) => {
		const { checked } = e.target;
		ExistingNumberData.port_number_is_agree = checked;
		setAgree(checked);
	};

	const addDays = (date, days) => {
		const newDate = new Date(date);
		newDate.setDate(newDate.getDate() + days);
		return newDate;
	};

	console.log(ExistingNumberData?.when_do_you_want_port_the_number)
	useEffect(() => {
		setExistingNumberData({ ...ExistingNumberData, error: [], ready: false });
		if (ExistingNumberData.when_do_you_want_port_the_number !== "") {
			setExistingNumberData({ ...ExistingNumberData, error: [], ready: true });
		}

		if (isWeekday) {

			const curr = new Date(startDate);
			if (curr.getDay() == 0) {
				curr.setDate(curr.getDate() + 1)
			}
			if (curr.getDay() == 6) {
				curr.setDate(curr.getDate() + 2)
			}

			setStartDate(curr)

			// Pdate = curr.toISOString().substring(0, 10);
		} else {
			setStartDate(twoDaysFromNow);
		}


		if (schedule === "as_soon_as_possible") {
			setAgree(false);
		}
		setExistingNumberData({
			...ExistingNumberData, 'when_do_you_want_port_the_number': {
				option: schedule,
				time: moment(startDate).format("Y-MM-DD")
			}
		})
	}, [schedule]);

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">When do you want us to port the number?</h2>
			<div className="QA_content carrier_check d-block">
				<div className="radio field">
					<input
						id="radio-2"
						name="schedule"
						type="radio"
						checked={schedule === "as_soon_as_possible"}
						onChange={() => {
							setSchedule("as_soon_as_possible");
							setPortDate(null);
						}}
					/>
					<label htmlFor="radio-2" className="radio-label">
						As soon as possible
					</label>
				</div>
				<div className="radio field order-field d-flex">
					<input
						id="radio-comp"
						name="schedule"
						type="radio"
						checked={schedule === "schedule"}
						onChange={() => setSchedule("schedule")}
					/>
					<label htmlFor="radio-comp" className="radio-label col-6 col-md-4">
						Not Before
					</label>
					<DatePicker
						className="outlne-0 col-12 col-md-4 w-100"
						disabled={schedule === "as_soon_as_possible"}
						name="schedule_port"
						id="schedule-port"
						selected={startDate}
						onChange={(date) => setStartDate(date)}
						filterDate={isWeekday}
						minDate={minDate}
						maxDate={addDays(new Date(), 60)}
						peekNextMonth={1}
						dateFormat="MM/dd/yyyy"
					/>
				</div>
			</div>
			<div className="item mt-4">
				<input
					className="custom-cb"
					id="cb-1"
					type="checkbox"
					disabled={schedule === "as_soon_as_possible"}
					checked={schedule === "schedule"}
					onChange={handleTermsChange}
				/>
				<label htmlFor="cb-1" style={{color:"#8B8B8B"}}>
					I understand that this is done on a best effort basis only and that Park My Phone cannot guarantee any specific date request
				</label>
			</div>
			<FormFooter
				action="did-update"
				payloadForUpdate={{ port_date: ExistingNumberData.when_do_you_want_port_the_number }}
				activeStep={activeStep}
				setStep={setStep}
			/>
		</div>
	);
};

export default PortTime;
