import { ExistingNumberContext } from 'contexts/FormsContext';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { getCarriers } from 'redux/actions/CarrierAction';
import Select from 'react-select';
import { parsePhoneNumber } from 'libphonenumber-js';
import PhoneInput from 'react-phone-input-2';

const PlanRatesMoreNumber = ({ show = false, data, radioIndex, setShow = () => { } }) => {

	const dispatch = useDispatch()
	const carrierImageURL = `${process.env.REACT_APP_API_URL}/uploads/carrier-images/`;
	const defaultCarrierImg = `/images/forms/test-icon.png`;
	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const carrierList = useSelector(state => state.carrier.map(carrierRow => {
		return {
			label: carrierRow?.name,
			value: carrierRow?.id,
			image: carrierRow?.carrier_image ? carrierImageURL + carrierRow?.carrier_image : defaultCarrierImg
		}
	}))
	let { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)

	const customStyles = {
		control: () => ({
			// none of react-select's styles are passed to <Control />
			height: 41,
			width: "100%",
			background: "#fff",
			padding: "10px 15px",
			display: "flex",
			alignItems: "center",
			border: "1px solid #dbdbd9",
			borderRadius: "4px"
		}),
	}
	const customStyle = {
		carrierImage: {
			marginRight: "10px",
			borderRadius: "50%",
			height: "35px",
			width: "35px",
			objectFit: "cover",
			objectPosition: "center"
		}
	}
	const setPlanRateId = (e) => {
		const tempArray = [...ExistingNumberData.more_numbers_plans];
		const a = tempArray[radioIndex].planRateId = e.id
		setExistingNumberData({ ...ExistingNumberData, "more_numbers_plans": tempArray })
	}
	const selectChangeHandler = (e) => {
		const tempArray = [...ExistingNumberData.more_numbers_plans];
		tempArray[radioIndex].serviceDetails = {
			...tempArray[radioIndex].serviceDetails,
			"plan_forward_number_carrier": e
		}
		setExistingNumberData({ ...ExistingNumberData, "more_numbers_plans": tempArray })
	}
	const inputChangeHandler = ({ target }) => {
		const tempArray = [...ExistingNumberData.more_numbers_plans];
		tempArray[radioIndex].serviceDetails = {
			...tempArray[radioIndex].serviceDetails,
			[target.name]: target.value
		}

		setExistingNumberData({ ...ExistingNumberData, "more_numbers_plans": tempArray })
	}
	const handleOnChange = (e, value) => {
		// validate phone via library
		if (e.length > 1) {
			setExistingNumberData({ ...ExistingNumberData, error: [] })
			const phoneNumber = parsePhoneNumber(e, value.countryCode.toUpperCase())
			// checking if number is valid or not via libphonenumber-js
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, error: ['invalid_phone'], ready: false })
				return false
			}

			if (phoneNumber.isValid() && phoneNumber.getType()) {
				const tempArray = [...ExistingNumberData.more_numbers_plans];
				tempArray[radioIndex].serviceDetails = {
					...tempArray[radioIndex].serviceDetails,
					'plan_forward_number': e,
					'plan_forward_number_country_id': countryList.filter(row => row.code === value.countryCode)[0]?.value,
				}
				setExistingNumberData({
					...ExistingNumberData,
					more_numbers_plans: tempArray,
					error: [],
					processing: false
				})
			}
		}
	}

	useEffect(() => {
		dispatch(getCarriers())
	}, [data])

	return (
		<Modal
			show={show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Body>
				<>
					<div className="row more-plans-option">
						<div className="col-12">
						<table className="table table-sm table-bordered table-striped">
								<thead>
									<tr>
										<th></th>
										<th>Price</th>
										<th>Fees</th>
										<th>Minimal</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									{
										data?.plans_rates?.map((row, index) => {
											return (
												<tr key={index}>
													<td className='align-middle'>
														<input
															type="radio"
															name={'plan_price' + radioIndex}
															defaultChecked={ExistingNumberData.more_numbers_plans[radioIndex].planRateId == row.id}
															//defaultChecked={ExistingNumberData.more_numbers_plans.filter(a => a.planId === row.plan_id)[0]?.planRateId == row.id}
															onChange={() => setPlanRateId(row)}
															value={row.id}
														/>
													</td>
													<td>${row?.price} per {row?.period !== 1 && row?.period} {row?.period_type}</td>
													<td>
														{parseInt(row?.porting_price) > 0 && <>Porting fee:{row?.porting_price} <br /></>}
														{parseInt(row?.shipping_price) > 0 && <>Shipping fee:{row?.shipping_price} <br /></>}
														{parseInt(row?.taxes_price) > 0 && <>Taxes fee:{row?.taxes_price} <br /></>}
														{parseInt(row?.equipment_price) > 0 && <>Equipment fee:{row?.equipment_price} <br /></>}
														{parseInt(row?.activation_price) > 0 && <>Activation fee:{row?.activation_price} <br /></>}
														{
															(row?.porting_price == 0
																&& row?.shipping_price == 0
																&& row?.taxes_price == 0
																&& row?.equipment_price == 0
																&& row?.activation_price == 0)
															&& "--"

														}
													</td>
													<td>{`${row?.minimal_period} ${row?.minimal_period_type}(s)`}</td>
													<td>{row?.description}</td>
												</tr>
											)
										}
										)
									}
								</tbody>
							</table>
							{
								data?.text_message && (
									<>
									<h4 className='py-2 sm-heading text-uppercase'>Please provide destination email for the select plan options:</h4>
									<div className="col-12">
										<h5 className='label sm-heading'>Email <span>*</span></h5>
										<input
											type="text"
											placeholder='Email for text message'
											name="plan_text_email"
											className='form-control py-2 mb-3'
											value={ExistingNumberData?.more_numbers_plans[radioIndex]?.serviceDetails?.plan_text_email}
											onChange={inputChangeHandler}
										/>
									</div>
									</>
								)
							}
							{
								data?.forwarding && (
									<>
									<h4 className='py-2 sm-heading text-uppercase'>Please provide destination phone number and carrier for the select plan options:</h4>
										<div className="col-12 mb-3">
											<div className="d-flex ">
												<div class="form-group w-50 px-1">
													<h5 className='label sm-heading'>Number <span>*</span></h5>
													<PhoneInput
														country={'us'}
														inputClass={"default"}
														onlyCountries={['us', 'ca']}
														value={ExistingNumberData?.more_numbers_plans[radioIndex]?.serviceDetails?.plan_forward_number}
														countryCodeEditable={false}
														onChange={(e, value) => handleOnChange(e, value)}
													/>
												</div>
												<div class="form-group w-50 px-1">
													<h5 className="mb-2 label sm-heading text-uppercase">Carrier <span>*</span> </h5>
													<Select
														onChange={(e) => selectChangeHandler(e)}
														value={ExistingNumberData?.more_numbers_plans[radioIndex]?.serviceDetails?.plan_forward_number_carrier}
														//value={ExistingNumberData?.plan_forward_number_carrier}
														className='w-100'
														//name='plan_forward_number_carrier_id'
														options={carrierList}
														styles={customStyles}
														formatOptionLabel={carrier => (
															<div className="carrier-option">
																<img alt="carrier" style={customStyle.carrierImage} width={40} className={"mr-1"} src={carrier.image} />
																<span>{carrier.label}</span>
															</div>
														)}
													/>
												</div>
											</div>
										</div>
									</>
								)
							}
							{
								data.voicemail && (
									<>
									<h4 className='py-2 sm-heading text-uppercase'>Please provide destination email for the select plan options:</h4>
									<div className="col-12">
										<h5 className='label sm-heading'>Email <span>*</span></h5>
										<input
											type="text"
											placeholder='Enter email'
											name="plan_voice_email"
											className='form-control py-2 mb-3'
											value={ExistingNumberData?.more_numbers_plans[radioIndex]?.serviceDetails?.plan_voice_email}
											onChange={inputChangeHandler}
										/>
									</div>
									</>
								)
							}

							
						</div>
					</div>
				</>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={setShow}>Close</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default PlanRatesMoreNumber
