import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import useAuth from 'hooks/useAuth'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
	const { isAuthenticated, isInitialised, socialLogin, forgotPassword, verifyCode } = useAuth();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [showOTPField, setShowOTPField] = useState(false);

	const { register, handleSubmit, formState: { errors } } = useForm();

	const submitHandlerOTP = async (form) => {
		setLoading(true)
		await verifyCode(form).then(response => {

			if (response.bool) {
				toast.success('Success!')
				history.push({
					pathname: '/change-password',
					state: { email: form.email }
				})
			}

		}).catch(err => {
			toast.success(err)
			console.log(err)
		})
		setLoading(false)
	}

	const submitHandler = async (form) => {
		setLoading(true)
		try {

			await forgotPassword(form).then(({ original }) => {
				if (original.bool) {
					setShowOTPField(true);
					toast.info("Please check your email " + form.email + " \n for the verification code")
				}
			});

			// history.push('/customer/dashboard')

		} catch (e) {

			toast.error(e.message)
			console.log(e);
			setLoading(false)

		}

		setLoading(false)
	}

	useEffect(() => {
		if (!isInitialised) {
			setLoading(true)
		}
		if (isAuthenticated) {

			history.push('/')
		}
		setLoading(false)
	}, [isAuthenticated, history, isInitialised])


	return (
		<>
			<Title title={"Forgot Password"} />
			<Topbar />
			<section className="login_section">
				<div className="container">
					<div className="row justify-content-end">
						<div className="col-xl-6 col-md-8 col-12">
							<div className="limiter">
								<div className="container-login100">
									<div className="wrap-login100">
										{
											showOTPField ?
												<form className="login100-form validate-form" onSubmit={handleSubmit(submitHandlerOTP)} autoComplete="off">
													<span className="login100-form-title slide-right">
														Enter Code
													</span>

													<div className="wrap-input100">

														<input
															defaultValue={''}
															className={errors.code ? "input100 border border-danger" : "input100"}
															type="password"
															placeholder="Enter Code"
															{...register("code", { required: true })}
														/>

														{errors.code && <span className='text-danger text-small'>This field is required</span>}
													</div>
													<div className="mt-4 text-center">
														<div className="wrap-login100-form-btn">
															<button type='submit' className="login100-form-btn" disabled={loading}>
																{
																	loading ?
																		<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																		:
																		"Continue"
																}
															</button>
														</div>
													</div>

													<div className="txt1 text-center">
														<span>
															Or Login with
														</span>
													</div>
													<div className="flex-c-m social-login-buttons">
														<div className="login-social">
															<button type="button" onClick={() => { socialLogin("azure", 'login') }} disabled={loading} className="social-facebook"><img alt='google' src="/images/microsoft.png"
																width="24px" height="24px" />
																Microsoft
															</button>
														</div>
														<div className="login-social">
															<button type="button" onClick={() => { socialLogin("google", 'login') }} disabled={loading} className="social-google"><img alt='google' src="/images/google.png"
																width="24px" height="24px" />
																Google
															</button>
														</div>
													</div>

													<div className="flex-col-c signup-link">
														<span className="txt2">
															Not a user?
														</span>
														<Link className="text-decoration-none txt2" to="/signup"> {" "}
															Signup now
														</Link>
													</div>
												</form>
												:
												<form className="login100-form validate-form" onSubmit={handleSubmit(submitHandler)} autoComplete="off">
													<img src="/images/forgot-password.png" className="login-icon" alt="logo" />
													<span className="login100-form-title slide-right">
														Reset Password
													</span>
													<p className="login100-form-subtitle">
														Please enter your email address.
													</p>

													<div className="wrap-input100">
														<p>
															You will receive an email with instructions on how to reset your password.
														</p>

														<input
															defaultValue={''}
															className={errors.email ? "input100 border border-danger" : "input100"}
															type="email"
															placeholder="Enter your email"
															{...register("email", { required: true })}
														/>

														{errors.email && <span className='text-danger text-small input100-valdation'>Email field is required</span>}
													</div>
													<div className="mt-4 text-center">
														<div className="wrap-login100-form-btn">
															<button type='submit' className="login100-form-btn" disabled={loading}>
																{
																	loading ?
																		<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																		:
																		"Continue"
																}
															</button>
														</div>
													</div>

													<div className="txt1 text-center">
														<span>
															Or Login with
														</span>
													</div>
													<div className="flex-c-m social-login-buttons">
														<div className="login-social">
															<button type="button" onClick={() => { socialLogin("azure", 'login'); setLoading(true) }} disabled={loading} className="social-facebook"><img alt='google' src="/images/microsoft.png"
																width="24px" height="24px" />
																Microsoft
															</button>
														</div>
														<div className="login-social">
															<button type="button" onClick={() => { socialLogin("google", 'login'); setLoading(true) }} disabled={loading} className="social-google"><img alt='google' src="/images/google.png"
																width="24px" height="24px" />
																Google
															</button>
														</div>
													</div>

													<div className="flex-col-c signup-link">
														<span className="txt2">
															Not a user?
														</span>
														<Link className="text-decoration-none txt2" to="/signup"> {" "}
															Signup now
														</Link>
													</div>
												</form>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<Footer />
		</>
	)
}

export default ForgotPassword
