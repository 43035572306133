import { parsePhoneNumber } from "libphonenumber-js";

export const formatPhone = (number) => {
	if (!number) {
		return
	}
	const countryCode = number.substring(0, 1);
	const npa = number.substring(1, 4);
	const nxx = number.substring(4, 7);
	const suffix = number.substring(7);
	return `+${countryCode} (${npa}) ${nxx}-${suffix}`
}

export const unformatedPhone = (number) => {
	return number.replace(/[^0-9]/g, '')
}

export const validatePhone = (number, countryCode = "US") => {
	if (!number) {
		return
	}
	var numbers = parsePhoneNumber(number, countryCode.toUpperCase())
	return (numbers.isValid()) && (numbers.country == countryCode);
}
