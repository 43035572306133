/* eslint-disable react-hooks/exhaustive-deps */
import { ExistingNumberContext } from "contexts/FormsContext";
import useAuth from "hooks/useAuth";
import React, { useState, useContext, useEffect } from "react";

import FormFooter from "./FormFooter";

const BusinessName = ({ setStep, activeStep }) => {

	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [isBussniess, setIsBussniess] = useState(ExistingNumberData.is_business_account);
	const [copyLoggedIn, setCopyLoggedIn] = useState(ExistingNumberData.logged_in_copy_toggle);
	const { isAuthenticated } = useAuth();

	const [authorizeForm, setAuthorizeForm] = useState({
		'business_name': ExistingNumberData.business_name || "",
		'authorized_first_name': ExistingNumberData.authorized_first_name,
		'authorized_last_name': ExistingNumberData.authorized_last_name
	})
	//console.log(ExistingNumberData?.is_business_account)
	const handleChange = (e, type) => {
		//console.log('this is error')
		//console.log(e.target.value, type)

		if (type === "bussniessCheck") {
			setExistingNumberData({ ...ExistingNumberData, 'is_business_account': !isBussniess, error: [], processing: false, ready: false })
			setIsBussniess(!isBussniess)
		}

		if (type === "copyLoginCheck") {
			setCopyLoggedIn(!copyLoggedIn)
			setExistingNumberData({ ...ExistingNumberData, "logged_in_copy_toggle": !copyLoggedIn, error: [], processing: false, ready: false })
		}
		if (type === "formData") {
			setAuthorizeForm({ ...authorizeForm, [e.target.name]: e.target.value })
			setExistingNumberData({ ...ExistingNumberData, [e.target.name]: e.target.value, error: [], processing: false, ready: false })
		}

	}
	useEffect(() => {

		setExistingNumberData({ ...ExistingNumberData, error: [], ready: true })
		if (copyLoggedIn) {
			setAuthorizeForm({
				...authorizeForm,
				'authorized_first_name': ExistingNumberData?.userData?.first_name,
				'authorized_last_name': ExistingNumberData?.userData?.last_name
			})
			setExistingNumberData({
				...ExistingNumberData,
				'logged_in_copy_toggle': true,
				'authorized_first_name': ExistingNumberData?.userData?.first_name,
				'authorized_last_name': ExistingNumberData?.userData?.last_name,
				'error': [],
				'ready': true
			})
		} else if (!isBussniess && ExistingNumberData.authorized_first_name !== "" && ExistingNumberData.authorized_last_name !== "") {
			setExistingNumberData({
				...ExistingNumberData,
				'authorized_first_name': ExistingNumberData.authorized_first_name,
				'authorized_last_name': ExistingNumberData.authorized_last_name,
				'ready': true,
				'error': []
			})
		} else if (isBussniess && ExistingNumberData.authorized_first_name !== "" && ExistingNumberData.authorized_last_name !== "" && ExistingNumberData.business_name) {
			setExistingNumberData({
				...ExistingNumberData,
				'business_name': ExistingNumberData.business_name,
				'authorized_first_name': ExistingNumberData.authorized_first_name,
				'authorized_last_name': ExistingNumberData.authorized_last_name,
				'ready': true,
				'error': []
			})
		}
		else{
			setExistingNumberData({
				...ExistingNumberData,
				'ready': false,
				'error': []
			})
		}



		// setExistingNumberData({ ...ExistingNumberData, 'authorized_first_name': "", 'authorized_last_name': "" })
	}, [copyLoggedIn, authorizeForm.business_name,ExistingNumberData.authorized_first_name,ExistingNumberData.authorized_last_name],[isBussniess]);
	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Enter name on the account:</h2>
			<div className="row">
				<div className="col">
					<div className="item my-2">
						<input
							className="custom-cb"
							id="account-on-company-name"
							type="checkbox"
							value={isBussniess}
							onChange={(e) => handleChange(e, "bussniessCheck")}
							defaultChecked={isBussniess}
						/>
						<label for="account-on-company-name"> Is this a business account</label>
					</div>
				</div>
			</div>
			{isAuthenticated &&
				<div className="row">
					<div className="col">
						<div className="item my-2">
							<input
								className="custom-cb"
								id="copydata-from-logged-in-user"
								type="checkbox"
								value={copyLoggedIn}
								onChange={(e) => handleChange(e, "copyLoginCheck")}
								defaultChecked={copyLoggedIn}
							/>

							<label for="copydata-from-logged-in-user"> Copy data from the logged in user</label>
						</div>
					</div>
				</div>
			}
			{isBussniess &&
				<div className="row">
					<div className="col signupbutton_content">
						<div className="email field">
							<img className="icon user" src="/images/forms/user.png" alt="" />
							<label for="business_name">Business Name</label>
							<input name="business_name" id="business_name" type="text" placeholder="Business Name" value={authorizeForm.business_name} onChange={(e) => handleChange(e, "formData")} />
						</div>
					</div>
				</div>
			}
			{/* {company_name_toggle && */}
			<div className="row mt-3">
				<div className="col-lg-6 col-12 signupbutton_content">
					<div className="email field">
						<img className="icon user" src="/images/forms/user.png" alt="" />
						<label for="company_name">Authorized/Customer Person on the account </label>
						<input name="authorized_first_name" readOnly={copyLoggedIn} id="authorized_first_name" type="text" placeholder="First Name" value={authorizeForm.authorized_first_name} onChange={(e) => handleChange(e, "formData")} />
					</div>
				</div>
				<div className="col-lg-6 col-12 signupbutton_content">
					<div className="email field">
						<img className="icon user" src="/images/forms/user.png" alt="" />
						<label for="company_name">&nbsp;</label>
						<input name="authorized_last_name" readOnly={copyLoggedIn} id="authorized_last_name" type="text" placeholder="Last Name" value={authorizeForm.authorized_last_name} onChange={(e) => handleChange(e, "formData")} />
					</div>
				</div>
			</div>
			{/* } */}

			<FormFooter action={"bussniess-account"} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default BusinessName
