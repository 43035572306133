import React from 'react'
import "./css/button.scoped.css"
const RoundedButton = (
	{
		className = "",
		showLoader = true,
		children,
		type = "submit",
		onClick,
		loading = false,
		loadingText = "Loading",
		size = "medium"
	}) => {
	return (
		<button className={"round-button " + className} onClick={onClick} disabled={loading} type={type} data-size={size}>

			{loading ?
				<>
					{loadingText} {showLoader && <>&nbsp; <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> </>}
				</>
				:
				children
			}
		</button>
	)
}
export default RoundedButton
