import { ExistingNumberContext } from "contexts/FormsContext";
import { capitalize } from "general/GeneralHelper";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAddOns } from "redux/actions/OrderAction";
import FormFooter from "./FormFooter";
import MiniLoader from "components/Loader/MiniLoader";


const AddOns = ({ setStep, activeStep }) => {
	const dispatch = useDispatch()
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [addOns, setAddOns] = useState([])
	const [loading, setLoading] = useState(false)

	const handler = (e) => {
		if (e.target.checked) {

			let i = ExistingNumberData.active_add_ons.indexOf(e.target.value);
			if (i) {
				setExistingNumberData({ ...ExistingNumberData, active_add_ons: [...ExistingNumberData.active_add_ons, e.target.value] })
			}
		} else {

			let i = ExistingNumberData.active_add_ons.indexOf(e.target.value);
			let arr = ExistingNumberData.active_add_ons;
			arr.splice(i, 1);
			setExistingNumberData({ ...ExistingNumberData, active_add_ons: arr })
		}
	}

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			await dispatch(getAddOns()).then(response => {
				setLoading(false)
				setAddOns(response.result)
			})
		}
		fetchData()
		setExistingNumberData({ ...ExistingNumberData, ready: true, error: [] })
	}, [])

	return (

		<div className="step">
			<img className="bannerAddOn" src="/images/forms/banner-addon.jpg" alt="" />
			<div className="spacer-addon-banner"></div>
			<div className="mt-25 container">
				<h4 className="mt-4 text-center">Discover Add-ons for <b>{ExistingNumberData?.first_number?.phoneNumber}</b> and Enhance Your Experience!</h4>
				<div className="row">
					{
						loading ?
							<div className="p-5">
								<MiniLoader />
							</div>
							:
							addOns?.map((addOn, index) => {
								return (
									<div className="col text-center my-2">
										<label className="card-addons">
											<input className="card__input" type="checkbox" defaultChecked={ExistingNumberData.active_add_ons.includes(addOn?.title)} value={addOn?.title} onChange={handler} />
											<div className="card__body">
												<div className="card__body-cover">
													<img alt="" className="addon-pkg-image" src="/images/forms/forward-message.png" />
													<span className="card__body-cover-checkbox">
														<svg className="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
															<polyline points="1.5 6 4.5 9 10.5 1"></polyline>
														</svg>
													</span>
													<span className="card__body-cover-checkbox2">
														<svg className="card__body-cover-checkbox--svg" viewBox="0 0 12 10">
														</svg>
													</span>
												</div>
												<header className="card__body-header">
													<h2 className="card__body-header-title">{addOn?.title}</h2>
													<p className="card__body-header-subtitle mb-0">
														${addOn?.price}
														{addOn?.period_type != "onetime" && "/"}
														{addOn.period_type === "onetime" ? "" : capitalize(addOn?.period_type)}
													</p>
												</header>
											</div>
										</label>
									</div>
								)
							})
					}
				</div>

			</div>
			{
				!loading &&
				<FormFooter action={'main-add-on'} activeStep={activeStep} setStep={setStep} centerProceed={true} />
			}
		</div>
	)
}

export default AddOns
