import React, { useState, useContext } from 'react'
import ValidationError from './ValidationError'
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import RoundedButton from 'components/Buttons/RoundedButton';
import { SignupContext } from 'contexts/FormsContext';
import { useDispatch } from 'react-redux';
import { checkValidateViaAPI } from 'redux/actions/UserAction';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import useAuth from 'hooks/useAuth';

const FacebookEmailConformation = () => {

	const history = useHistory();
	const dispatch = useDispatch()
	const { verifyUser } = useAuth();
	const [loading, setLoading] = useState(false)
	const [changeEmail, setChangeEmail] = useState(false);
	const { signupData, setSignupData } = useContext(SignupContext)
	const { register, handleSubmit, watch, setError, formState: { errors }, clearErrors } = useForm({
		defaultValues: {
			email: ""
		}
	});

	const changeEmailClickHandler = (e) => {
		setChangeEmail(!changeEmail)
		clearErrors('email')
	}

	const formSubmitHandler = async (e) => {
		setLoading(true)
		// If user didnt change email just move him to next step
		if (!watch('email')) {
			await verifyUser({ 'email': signupData?.email, 'name': signupData?.first_name }).then((resp) => {
				if (resp?.bool) {
					toast.success(resp.message);
					history.push('/signup/email-verification')
				}
				setLoading(false)
			})
			return;
		}

		// If user changed email it will this code will execute.
		await dispatch(checkValidateViaAPI({ "email": e.email })).then(async (response) => {
			if (response.bool) {
				setSignupData({ ...signupData, 'email': e.email })
				await verifyUser({ 'email': e.email, 'name': signupData?.first_name }).then((resp) => {
					if (resp?.bool) {
						toast.success(resp.message);
						setLoading(false)
						history.push('/signup/email-verification')
					}
				})
			}
		}).catch(err => {
			setError("email", { message: err.message })
			setLoading(false)
		})
	}

	useEffect(() => {
		if (signupData?.activeSocialFormat !== "facebook") {
			alert("This step is only for facebook. Redirecting you back")
			history.goBack();
		}
		return () => {
			// reseting values at component unmount
			setChangeEmail(false);
			setLoading(false);
		}

	}, [])


	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(formSubmitHandler)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Use this email?</h2>
					<div className="row mb-3">
						<div className="col">
							<div className="coverage-field">
								<label className="mb-3">This is your email address that you use on Facebook, would you like to keep the same email or change it?</label>
								{
									changeEmail ?
										<div className="input-container">
											<i className="fa fa-at icon-c" aria-hidden="true"></i>
											<input
												name="email"
												className={errors.email ? "error input-field" : "input-field"}
												id="email"
												type="email"
												placeholder="someone@example.com"
												{...register("email", {
													required: "Email is required", pattern: {
														value: /\S+@\S+\.\S+/,
														message: "Entered value does not match email format"
													}
												})} />
										</div> :
										<h4>{signupData?.email || "No email found"}</h4>
								}
								<div className="d-flex justify-content-center pt-4">
									<RoundedButton className={changeEmail ? "mx-1 bg-success shadow-none" : "mx-1"} loading={loading}>{changeEmail ? "Save" : "Use This Email"}</RoundedButton>
									<RoundedButton className='mx-1 bg-secondary shadow-none' type='button' onClick={changeEmailClickHandler} loading={loading} loadingText={changeEmail ? "Cancel" : "Change"} showLoader={false}>{changeEmail ? "Cancel" : "Change"}</RoundedButton>
								</div>
							</div>
						</div>
					</div>
					{/* From Footer */}
					<>
						<button type="button" onClick={() => history.goBack()} data-title="Previous" className="prev-step elaboration right">
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default FacebookEmailConformation
