import React, { useState } from "react";

import { useContext } from "react";
import { CoverageContext } from "contexts/FormsContext";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import FormFooter from "./FormFooter";
import { parsePhoneNumber } from "libphonenumber-js";


const AddingNumbers = ({ setStep, setProcessing, activeStep, heading, placeholder, prevStep, setError }) => {

	const { coverage, setCoverage } = useContext(CoverageContext)

	const [disabledBtn, setDisabledBtn] = useState(false);
	const countryData = {
		countryCode: "us",
		dialCode: "1",
		format: "+. (...) ...-....",
		name: "United States",
	}

	const [portList, setPortList] = useState(coverage.port);

	const disabled = false;
	const containerClass = "PhoneInput";
	const currentCountryCode = "us";

	const handleOnChange = (e, key, subIndex) => {

		setError("")
		let tempArray = [...portList];
		tempArray[key][subIndex] = e;

		if (subIndex == 'type') {

			setPortList(tempArray);

		} else {
			if ((tempArray[key]['countryCode'] != "") && (e.length > 1)) {

				const phoneNumber = parsePhoneNumber(e, tempArray[key]['countryCode']?.toUpperCase())
				if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
					if (e.length >= 10) {
						console.log(phoneNumber.getType())
						setDisabledBtn(true)
						setError("Number is invalid")
					}
				} else {
					setDisabledBtn(false)
					// tempArray[key]['phoneType'] = phoneNumber.getType();
					setPortList(tempArray);
				}
			}
		}


	}

	const setCountryHandler = (e, key, subIndex) => {
		let tempArray = [...portList];
		tempArray[key][subIndex] = e.countryCode;
		setPortList(tempArray);

	}


	const removeRow = (e) => {
		let tempArray = [...portList];
		tempArray.splice(e, 1);

		setPortList(tempArray);
	}
	useEffect(() => {
		setCoverage({ ...coverage, 'port': portList });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [portList])


	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading mb-5">Use the form below to see if your number is eligible for Park My Phone phone number storage.</h2>
			<h2 className="lg-heading">{heading}</h2>
			<div className="row">
				{portList?.map((number, index) => {

					return (

						<div className="row position-relative" key={index}>

							<div className="col mt-4">
								<PhoneInput
									inputClass="default"
									disabled={disabled}
									containerClass={containerClass}
									countryCode={currentCountryCode}
									country={'us'}
									countryCodeEditable={false}
									onChange={(phone, details) => {
										handleOnChange(phone, index, "number");
										setCountryHandler(details, index, "countryCode")
									}}
									placeholder={placeholder}
									countryData={countryData}
									value={portList[index].number}
								/>
							</div>
							<div className="col d-flex flex-column mb-2">
								<label className="form-label select-label mb-0">Number Is A:</label>
								<div className="number_type">
									<div className="three_main_phone_options">
										<div className="cat action" onClick={(e) => {
											handleOnChange('landline', index, "type")
										}}>
											<label htmlFor="carrier_option_verizon_wireless" className="cat action">
												<input
													name={`type[${index}]`}
													type="radio"
													className="social_check"
													value="landline"
													id="carrier_option_landline"
													checked={portList[index].type === 'landline'}
													onChange={() => handleOnChange('landline', index, "type")}
												/>
												<span><img width="22" height="22" src="/images/forms/call.png" className="number_options" alt="" />Landline</span>
											</label>
										</div>
										<div className="cat action" onClick={(e) => {
											handleOnChange('mobile', index, "type")
										}}>
											<label htmlFor="carrier_option_at_and_t_wireless">
												<input
													name={`type[${index}]`}
													type="radio"
													className="social_check"
													value="mobile"
													onClick={(e) => {
														handleOnChange('mobile', index, "type")
													}}
													checked={portList[index].type === 'mobile'}
													onChange={() => handleOnChange('mobile', index, "type")}
													id="carrier_option_mobile"
												/>
												<span><img width="22" height="22" src="/images/forms/conversation.png" className="number_options" alt="" />Mobile</span>
											</label>
										</div>
										<div className="cat action" onClick={(e) => {
											handleOnChange('fax', index, "type")
										}}>
											<label htmlFor="carrier_option_t_mobile">
												<input
													name={`type[${index}]`}
													type="radio"
													className="social_check"
													value="fax"
													onClick={(e) => {
														handleOnChange('fax', index, "type")
													}}
													id="carrier_option_fax"
													checked={portList[index].type === 'fax'}
													onChange={() => handleOnChange('fax', index, "type")}
												/>
												<span><img width="22" height="22" src="/images/forms/fax.png" className="number_options" alt="" />Fax</span>
											</label>
										</div>
									</div>
								</div>

							</div>
							{
								index !== 0 && (
									<div onClick={() => removeRow(index)} className="numberRemove mt-2"><i className="fa fa-trash"></i></div>
								)
							}

						</div>

					)
				})}
			</div>

			<button
				type="button"
				className="add-number-btn"
				onClick={() => {
					setPortList([...portList, {
						number: "",
						type: "",
						countryCode: ""
					}])
				}}>+ Add Number</button>

			<FormFooter
				setProcessing={setProcessing}
				setError={setError}
				action={"update-with-check"}
				activeStep={activeStep}
				setStep={setStep}
				disabled={disabledBtn}
				addNumber={true}
			/>
			{/* <FormFooter setProcessing={setProcessing} action={"update"} activeStep={activeStep} setStep={setStep} addNumber={true} /> */}
		</div>
	)
}

export default AddingNumbers
