import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    <footer>
		<div className="footer_upper_bar">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-6 col-12">
						<div className="first_column">
							<ul>
								<h4 className='text-white'>Getting Started</h4>
								<li><Link className="text-decoration-none" to="#">HOME</Link></li>
								<li><Link className="text-decoration-none" to="#">PRICING</Link></li>
								<li><Link className="text-decoration-none" to="#">FAQS</Link></li>
								<li><Link className="text-decoration-none" to="#">ABOUT</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						<div className="second_column">
							<ul>
								<h4 className='text-white'>Services</h4>
								<li><Link className="text-decoration-none" to="#">PARK MY PHONE</Link></li>
								<li><Link className="text-decoration-none" to="#">PARK MY FAX</Link></li>
								<li><Link className="text-decoration-none" to="#">PARK MY 800</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						<div className="third_column">
							<ul>
								<h4 className='text-white'>Policies</h4>
								<li><Link className="text-decoration-none" to="#">TERMS OF SERVICE</Link></li>
								<li><Link className="text-decoration-none" to="#">SITEMAP</Link></li>
								<li><Link className="text-decoration-none" to="#">CONTACT US</Link></li>
							</ul>
						</div>
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						<div className="fourth_column">
							<ul>
								<h4 className='text-white'>Social</h4>
								<li><Link className="text-decoration-none" to="#"><i className="fa-brands fa fa-linkedin"></i> LINKEDIN</Link></li>
								<li><Link className="text-decoration-none" to="#"><i className="fa-brands fa fa-facebook-square"></i> FACEBOOK</Link></li>
								<li><Link className="text-decoration-none" to="#"><i className="fa-brands fa fa-twitter"></i> TWITTER</Link></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div className="footer_bottom_bar">
			<div className="container">
				<div className="row">
					<div className="copyright_bottom">
						<p>© 2022 All Right Reserved. ParkMyPhone | ParkMyPhone.com is a service of Family-Phone.com</p>
					</div>
				</div>
			</div>
		</div>
	</footer>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.0/jquery.min.js"></script>
	<script src="https://maxcdn.bootstrapcdn.com/bootstrap/5.1.3/js/bootstrap.min.js"></script>
    </>
  )
}

export default Footer
