import { toast } from "react-toastify"
import axios from "../../axios"

export const GET_ACTIVITY_LOG = 'GET_ACTIVITY_LOG'


// export const getActivityLogs = (user) => (dispatch) => {


//   return axios.get(`log/user/${user}`).then((res) => {
// console.log(res.data);
//     dispatch({
//       type: GET_ACTIVITY_LOG,
//       payload: res.data,
//     })

//     return res.data;
//   })
// }


export const getActivityLogs = (user) => (dispatch) => {
	axios.get(`log/user/${user}`).then(({ data }) => {
	 if (data.bool) {
		dispatch({
		 type: GET_ACTIVITY_LOG,
		 payload: data.result
		})
	 }

	})
 }
