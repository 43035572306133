import { ExistingNumberContext } from "contexts/FormsContext";
import { unformatedPhone } from "general/FormatNumber";
import React, { useState, useRef, useContext } from "react";
import { useEffect } from "react";
import PinField from "react-pin-field"
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { checkVerificationAction, phoneNumberVerification, phoneNumberVerificationViaCall } from "redux/actions/OrderAction";
import FormFooter from "./FormFooter";

const Verification = ({ setStep, activeStep, prevStep }) => {

	// setStep(activeStep + 1)

	let { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [loading, setLoading] = useState(false);
	const [codeSubmitCount, setCodeSubmitCount] = useState(0);
	const dispatch = useDispatch();
	const [verification_pref, setVerificationPreference] = useState(ExistingNumberData.verification_pref ? ExistingNumberData.verification_pref : "text_message");
	const [sendCode, setSendCode] = useState(false);
	const [sendCall, setSendCall] = useState(false);
	const [textMessagePin, setTextMessagePin] = useState(false);
	const Ref = useRef(null);
	const inputRef = useRef(null);
	// The state for our timer
	const [timer, setTimer] = useState('00:00');

	const setTextPinCode = e => {

		if (textMessagePin) {
			setExistingNumberData({ ...ExistingNumberData, 'pinText': e })
		}
	}

	const setVerificationPref = e => {
		setVerificationPreference(e)
		ExistingNumberData.verification_pref = e
	}
	// const [completed, setCompleted] = useState(false);

	const getTimeRemaining = (e) => {
		const total = Date.parse(e) - Date.parse(new Date());
		const seconds = Math.floor((total / 1000) % 60);
		const minutes = Math.floor((total / 1000 / 60) % 60);
		const hours = Math.floor((total / 1000 / 60 / 60) % 24);
		return {
			total, hours, minutes, seconds
		};
	}


	const startTimer = (e) => {
		let { total, minutes, seconds } = getTimeRemaining(e);
		if (total >= 0) {
			// update the timer
			// check if less than 10 then we need to
			// add '0' at the beginning of the variable
			setTimer(
				(minutes > 9 ? minutes : '0' + minutes) + ':'
				+ (seconds > 9 ? seconds : '0' + seconds)
			)
		}
	}

	const clearTimer = (e) => {
		// If you adjust it you should also need to
		// adjust the Endtime formula we are about
		// to code next
		setTimer('01:00');

		// If you try to remove this line the
		// updating of timer Variable will be
		// after 1000ms or 1sec
		if (Ref.current) clearInterval(Ref.current);

		const id = setInterval(() => {
			startTimer(e);
		}, 1000)

		Ref.current = id;
	}

	const getDeadTime = () => {

		let deadline = new Date();
		// This is where you need to adjust if
		// you entend to add more time
		deadline.setSeconds(deadline.getSeconds() + 60);
		return deadline;
	}

	const onClickReset = () => {
		clearTimer(getDeadTime());
	}

	const sendVerificationCodeAPI = async () => {
		setCodeSubmitCount(0)
		inputRef.current?.map(row => row.value = "") //reset old value if exist
		setExistingNumberData({ ...ExistingNumberData, "did_verified": false })
		setTextMessagePin(false)

		setLoading(true);
		const payload = {
			'phone': unformatedPhone(ExistingNumberData?.first_number?.phoneNumber)
		}
		await dispatch(phoneNumberVerification(payload, ExistingNumberData?.userData?.token)).then(({ original: { result } }) => {
			console.log(result)
			if (result.bool) {
				setSendCode(true)
				toast.info("Kindly check the your phone for verfication code", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
				setLoading(false);

			} else {
				toast.error("Failed to send OTP", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000, theme: "light" })
				setLoading(false);
			}
		}
		).catch(err => {
			toast.error("Failed to send OTP", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 3000, theme: "light" })
			setLoading(false);
		})
	}

	const callForVerificationCode = async () => {

		setCodeSubmitCount(0)
		inputRef.current?.map(row => row.value = "") //reset old value if exist
		setExistingNumberData({ ...ExistingNumberData, "did_verified": false })
		setTextMessagePin(false)
		setLoading(true);
		const payload = {
			'phone': unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
			'name': "Customer" // Or auth user name
		}
		await dispatch(phoneNumberVerificationViaCall(payload, ExistingNumberData?.userData?.token)).then(response => {
			console.log("Here")
			if (response.bool) {
				setSendCall(true)
				toast.info("Kindly check the your phone, you will receive a call shortly.", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
				setLoading(false);

			} else {
				toast.error(response?.message)
				setLoading(false);
			}
		}
		).catch(err => {
			toast.error("Unable to initiate the call.")
			setLoading(false);
		})
	}

	const checkVerification = async (e) => {

		setLoading(true)
		setCodeSubmitCount(codeSubmitCount + 1)
		const payload = {
			'order_id': ExistingNumberData?.order_id,
			'phone': unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
			'code': e
		}

		await dispatch(checkVerificationAction(payload, ExistingNumberData?.userData?.token)).then(response => {
			setTextMessagePin(true)
			if (response?.result.phone) {
				if (response?.result?.phone?.result) {

					setExistingNumberData({ ...ExistingNumberData, 'did_verified': true, "error": [], "ready": true })
					setCodeSubmitCount(5)

				} else {
					setExistingNumberData({ ...ExistingNumberData, 'did_verified': false, "error": ["Invalid Code"] })
					toast.error("Invalid Code!", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
				}
			} else {
				setExistingNumberData({ ...ExistingNumberData, 'did_verified': false })
				toast.error("Invalid Code!", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" });
			}
			setLoading(false)
		}).catch(errors => toast.error(errors.message))

	}

	useEffect(() => {
		if (ExistingNumberData?.did_verified) {
			if (prevStep === 4) {
				setStep(activeStep - 1)

			} else {
				setStep(activeStep + 1)
			}
		}
		setExistingNumberData({ ...ExistingNumberData, "pinText": "", "ready": ExistingNumberData?.did_verified ?? false })

	}, [])

	return (
		<div className="step">
			<div className="d-flex justify-content-between">

				<h5 className="sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
				<a rel={'noreferrer'} target={"_blank"} href="https://myaccount.parkmyphone.com/index.php?page=contact_us">Having issues? contact us</a>
			</div>
			<h2 className="lg-heading">Get phone verification via:</h2>
			<div className="code_verification">
				<div className="row-f" >
					<div className="cat action col-4">
						<label>
							<input type="radio" className="code_check" name="verification_pref" value="text_message" checked={verification_pref === "text_message"} onChange={e => setVerificationPref(e.target.value)} />
							<span><img className="icon-verify" width="32" height="32" src="/images/forms/conversation.png" alt="" />Text Message</span>
						</label>
					</div>
					<div className="cat action col-4">
						<label>
							<input type="radio" className="code_check" name="verification_pref" value="call" checked={verification_pref === "call" || verification_pref === "schedule"} onChange={e => setVerificationPref(e.target.value)} />
							<span><img className="icon-verify" width="32" height="32" id="code-button" src="/images/forms/call.png" alt="" />Call</span>
						</label>
					</div>
				</div>
			</div>
			{verification_pref === "text_message" &&
				<div className="mt-3" id="showOne" >
					{!sendCode &&
						<div className="code flex-column">
							<p className="sm-text">You will be sent a code via text for verification.</p>
							{
								loading ?
									<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									:
									<div className="resend-code" onClick={sendVerificationCodeAPI}>
										Send the code <i className="fa fa-arrow-right blue"></i>
									</div>
							}
						</div>}
					{sendCode &&
						<div className="wrapperPin" id="verify-code">
							<p className="sm-text text-center">Enter 4 digit verification code sent to above number to verify your identity.</p>
							<div className="code justify-content-center">
								<div className="digits">
									<PinField
										length={4}
										validate={/^[0-9]$/}
										ref={inputRef}
										disabled={codeSubmitCount >= 5 || loading}
										// defaultValue={ExistingNumberData.pinText}
										className={
											textMessagePin ?
												(ExistingNumberData?.did_verified ? "complete  pin-field" : "alert-danger pin-field")
												:
												" pin-field"
										}
										onComplete={(e) => { setVerificationPref("text_message"); checkVerification(e) }}
										onChange={e => { setTextPinCode(e); setVerificationPref("text_message"); }} autoFocus />
								</div>
								{timer !== "00:00" ?
									<div id="timer">{timer}</div>
									:
									<div className="resend-code"
										onClick={() => { onClickReset(); sendVerificationCodeAPI() }}
									>
										<img src="/images/forms/icon-resend-code-blue.png" alt="" />
										Resend the code
									</div>
								}
							</div>
						</div>}
				</div>
			}
			{verification_pref === "call" &&
				<div className="mt-3" id="showTwo">
					{!sendCall &&
						<div className="code flex-column">
							<p>You will get a phone call with the verification code to type.</p>
							<div className="d-flex">
								<div className="">
									{
										loading ?
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
											:
											<div className="resend-code" onClick={callForVerificationCode}>
												Call Now <i className="fa fa-phone blue"></i>
											</div>
									}
								</div>
								{/* <div className="resend-code" onClick={e => setVerificationPref(verification_pref = "schedule")}>
                  Schedule a callback time <i className="fa fa-calendar blue"></i>
                </div> */}
							</div>
						</div>
					}
					{sendCall &&
						<div className="wrapperPin2" id="verify-code">
							<p className="sm-text mb-0 text-center"><b>Please make sure you are near the phone as you will be getting call from our
								system.</b></p>
							<p className="sm-text mt-2 text-center">Enter 4 digit verification code given on call to verify your identity.</p>
							<div className="code justify-content-center">
								<div className="digits">
									<PinField
										length={4}
										validate={/^[0-9]$/}
										ref={inputRef}
										disabled={codeSubmitCount >= 5 || loading}
										// defaultValue={ExistingNumberData.pinText}
										className={
											textMessagePin ?
												(ExistingNumberData?.did_verified ? "complete  pin-field" : "alert-danger pin-field")
												:
												" pin-field"
										}
										onComplete={(e) => { setVerificationPref("call"); checkVerification(e) }}
										onChange={e => { setTextPinCode(e); setVerificationPref("call"); }} autoFocus />
								</div>
								{timer !== "00:00" ?
									<div id="timer">{timer}</div>
									:
									<div className="resend-code"
										onClick={() => { onClickReset(); callForVerificationCode() }}
									>
										<img src="/images/forms/icon-resend-code-blue.png" alt="" />
										Resend the code
									</div>
								}
							</div>
						</div>
					}
				</div>
			}
			{verification_pref === "schedule" &&
				<div className="mt-3" id="showThree">
					<p className="sm-text mb-0 text-center">Please select time and date when you will be available to complete verification</p>
					<div className="d-flex justify-content-center">
						<div className="schedule-field me-2">
							<input type="date" id="scheduleInput" name="scheduleInput" />
						</div>
						<div className="schedule-field">
							<input type="time" id="scheduleInput" name="scheduleInput" />
						</div>
					</div>
				</div>
			}
			<FormFooter action={'did-verification'} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default Verification
