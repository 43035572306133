import React, { useContext } from "react";
import Table from 'react-bootstrap/Table';
import { ExistingNumberContext } from "contexts/FormsContext";
import { formatPhone } from "general/FormatNumber";
const OrderSummary = ({ setStep, activeStep }) => {
	let price = 0;
	const { ExistingNumberData } = useContext(ExistingNumberContext);
	console.log(ExistingNumberData, ExistingNumberData.more_numbers_plans)

	return (
		<div className="step px-0">
			<h5 className="mb-2 sm-heading text-center">CONGRATULATIONS ON COMPLETING YOUR ORDER</h5>
			<h2 className="lg-heading text-center text-uppercase mb-3">Order Summary</h2>
			<div className="order_summary">
				<div className="row px-4">
					<div className="col-md-4 col-12 pt-3">
						<div className="order_summary_left">
							<div className="px-4">
								<div className="row">
									<div className="col-10">
										<h4><b>Account Details:</b></h4>
										<p className="fw-normal fs-5 m-0">Name: {ExistingNumberData.authorized_first_name} {ExistingNumberData.authorized_last_name}</p>
										<p className="fw-normal fs-5 mb-0">Address: {ExistingNumberData.service_address_where_the_phone_physically_installed.street_address ? ExistingNumberData.service_address_where_the_phone_physically_installed.street_address.label : ''}</p>
										{
											ExistingNumberData.account_number_for_this_account_with_phone_business ??
											<p className="fw-normal fs-5 mb-3">Account: {ExistingNumberData.account_number_for_this_account_with_phone_business}</p>
										}
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change account Details" onClick={() => setStep(activeStep = 15)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<hr />
								<div className="row">
									<div className="col-10">
										<h4><b>Your Plan Details:</b></h4>
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change Plan Details" onClick={() => setStep(activeStep = 17)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<h4 className="orange">{ExistingNumberData.plan_details.title}</h4>
								<p className="fw-normal fs-6 mb-0">Duration: <span className="blue">{ExistingNumberData.plan_details.plans_rates[0].period} Months</span>
									<i className="fa fa-check orange" aria-hidden="true"></i>&nbsp;
								</p>
								<p className="fw-normal fs-6 mb-0">AddOns: <span className="blue">{ExistingNumberData.active_add_ons.join(", ")}</span>
									{/* <i className="fa fa-check orange" aria-hidden="true"></i>&nbsp; */}
								</p>
							</div>

							<div className="p-4">
								<hr />
								<div className="row">
									<div className="col-10">

										<h4><b>Payment Details:</b></h4>
									</div>
									<div className="col-2">
										<div className="changeLink text-center elaboration left" data-title="Change Payment Details" onClick={() => setStep(activeStep = 20)}><i className="fa fa-pencil"></i></div>
									</div>
								</div>
								<div className="col-12">
									<p className="fw-normal fs-5 mb-0">Payment Method: Credit Card</p>
									<div className="d-flex my-2 align-items-center">
										<img src="/images/forms/visa-icon.png" className="img-fluid col-2" alt="" />&nbsp;
										<h4><span className="orange">**** **** **** {ExistingNumberData.card_number.substr(14, 5)}</span></h4>
									</div>
									<p className="fw-normal fs-6 mb-0">Name: <span className="blue">{ExistingNumberData.name_on_card}</span>
										{/* <i className="fa fa-check orange" aria-hidden="true"></i>&nbsp; */}
									</p>
									<p className="fw-normal fs-6 mb-0">Expiry: <span className="blue">{ExistingNumberData.expiry}/{ExistingNumberData.expiryyear.substr(2, 2)}</span>
										{/* <i className="fa fa-check orange" aria-hidden="true"></i>&nbsp; */}
									</p>
								</div>
							</div>

							{/* <div className="changePaymentDetails">
                <img src="/images/forms/visa-icon.png" className="visa-image mb-4" />
                <h5><b>Name on Card: </b><span className="blue">Oren Yehezkely</span></h5>
                <h5 className="mb-4"><b>Card Number: </b><span className="blue">XXXX XXXX XXXX 9876</span></h5>
                <div className="changeLink" onClick={() => setStep(activeStep = 20)}>Change Payment Details <i className="fa fa-credit-card"></i></div>
              </div> */}

							{/* <hr className="mb-0" /> */}
							{/* <img src="/images/forms/order-delivery.png" width="150" height="150" alt="" />
              <h4 className="my-4">Order Confirmed</h4> */}

						</div>
					</div>
					<div className="col-md-8 col-12 aligns-items-center justify-content-center pt-3 pe-4">
						{/* <div className="row mb-3">
						<div className="col">
							<div><i className="fa fa-calendar blue"></i> 02/09/2022</div>
							<div><i className="fa fa-plus blue"></i> 3 Extra Numbers</div>
						</div>
						<div className="col d-flex justify-content-end">
							<div className="changeLink text-center elaboration right" data-title="Change Account Details" onClick={() => setStep(activeStep = 16)}><i className="fa fa-pencil"></i></div>
						</div>
						</div> */}
						<h4 className="text-center"><b>Invoice</b></h4>
						<div className="order_summary_right">
							<Table striped>
								<thead>
									<tr>
										<th>Items</th>
										<th className="text-center">Duration</th>
										<th className="text-end">Price</th>
									</tr>
								</thead>
								<tbody>
									{/* {ExistingNumberData.active_add_ons.includes("Data Package") &&
                    <tr>
                      <td>Primary Number</td>
                      <td className="text-center">Voicemail</td>
                      <td className="text-end">$10.00</td>
                    </tr>
                  }
                  {ExistingNumberData.active_add_ons.includes("Express Porting") &&
                    <tr>
                      <td>Express Porting</td>
                      <td className="text-center">30 Days</td>
                      <td className="text-end">$5.27</td>
                    </tr>
                  }
                  {ExistingNumberData.active_add_ons.includes("Text Messages") &&
                    <tr>
                      <td>Text Messages</td>
                      <td className="text-center">30 Days</td>
                      <td className="text-end">$3.27</td>
                    </tr>
                  }
                  {ExistingNumberData.active_add_ons.includes("Data Package") &&
                    <tr>
                      <td>Data Package</td>
                      <td className="text-center">30 Days</td>
                      <td className="text-end">$2.27</td>
                    </tr>
                  } */}
									{ExistingNumberData?.more_numbers_plans?.map((values, index) => {
										let planRateRow = values.planList.filter(row => row.id === values.planId)[0].plans_rates.filter(row => row.id === values.planRateId)[0]
										price += parseInt(planRateRow.price) + 5.75

										return (
											<tr>
												<td class="summary_number_details ">
													<div className="country-icon"><img alt="" src="/images/forms/usaicon.png" className="img-fluid" /> </div>
													<div className="ms-3">
														<div class="country-number ">{formatPhone(values.number)} </div>
														<div className="micro package_name">- {values.planList.filter(row => row.id === values.planId)[0].title}</div>
														{/* <div className="micro package_name">- {values.planList.filter(row => row.id === values.planId)[0].plans_rates.filter(row => row.id === values.planRateId)[0].}</div> */}
														<div className="micro package_name">- {values.addOns.join(", ")}</div>
													</div>
												</td>
												<td className="text-center">
													<div className="text-end">{values.planList.filter(row => row.id === values.planId)[0].plans_category.name}</div>
													<div className="extra_price">
														<div className="micro text-end">
															{planRateRow.period}
															{" "}
															{planRateRow.period_type}
														</div>
														{/* <div className="micro text-end">15 days</div> */}
													</div>
												</td>
												<td>
													<div className="text-end">${planRateRow.price}</div>
													{/* <div className="text-end">$10.00</div> */}
													<div className="extra_price">
														<div className="micro text-end">$5.75</div>
														{/* <div className="micro text-end">$5.75</div> */}
													</div>
												</td>
											</tr>
										)

									})}

									{/* <tr>
										<td class="summary_number_details ">
											<div className="country-icon"><img alt="" src="/images/forms/usaicon.png" className="img-fluid" /> </div>
											<div className="ms-3">
												<div class="country-number ">+1 (512) 835 213 </div>
												<div className="micro">- {ExistingNumberData.plan}</div>
												<div className="micro">- {ExistingNumberData.active_add_ons.join(", ")}</div>
											</div>
										</td>
										<td className="text-center">
											<div className="text-end">Voicemail</div>
											<div className="extra_price">
												<div className="micro text-end">2 Months</div>
												<div className="micro text-end">15 days</div>
											</div>
										</td>
										<td>
											<div className="text-end">$10.00</div>
											<div className="micro text-end">$5.75</div>
											<div className="micro text-end">$5.75</div>
										</td>
									</tr> */}
									<br />
									<tr className="border">
										<td colspan="2">Subtotal</td>
										<td className="text-end">${price}</td>
									</tr>
									<tr className="border">
										<td colspan="2" className="align-middle">Porting Fee <span className="micro">(NON REFUNDABLE)</span></td>
										<td className="text-end">$5.27</td>
									</tr>
									<tr className="border">
										<td colspan="2" className="fw-bold">Total</td>
										<td className="fw-bold orange text-end">${parseFloat(price + 5.27).toFixed(2)}</td>
									</tr>
								</tbody>
							</Table>
							<div className="row">
								<div className="col">
									<button type="button" className="order-summary-dashboard-btn btn-lg my-3 mx-auto" onClick={() => setStep(activeStep + 1)}>Process My Order</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OrderSummary
