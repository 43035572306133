/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";
import SignatureCanvas from 'react-signature-canvas'
import { useState } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import { useRef } from "react";
import html2canvas from "html2canvas";
import trimCanvas from 'trim-canvas'
import FormFooter from "./FormFooter";
import { toast } from "react-toastify";

import Modal from 'react-modal';

const Signature = ({ setStep, activeStep }) => {
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext);
	const [trimmedDataURL, setTrimmedDataURL] = useState(ExistingNumberData.signature)
	const [loading, setLoading] = useState(false)
	let [padEnable, setPadEnable] = useState(ExistingNumberData.type_or_sign)
	// let [signFont, setSignFont] = useState(1);
	let [dropdown, setDropDown] = useState(false);
	let [typedSignature, setTypedSignature] = useState(ExistingNumberData.signature_text);
	// const handleFontChange = (e) => {
	// 	if (e == 1) {
	// 		ExistingNumberData.signature_font = "AntroVectra"
	// 	} else if (e == 2) {
	// 		ExistingNumberData.signature_font = "ArtySignature"
	// 	} else if (e == 3) {
	// 		ExistingNumberData.signature_font = "Creattion"
	// 	} else if (e == 4) {
	// 		ExistingNumberData.signature_font = "Southam"
	// 	}
	// 	setSignFont(e);
	// }

	let sigPad = {}
	const padHandler = (e) => {
		setExistingNumberData({ ...ExistingNumberData, ready: false })
		ExistingNumberData.signature = ''
		setPadEnable(e)
		ExistingNumberData.type_or_sign = e
	}

	const clear = () => {
		sigPad.clear()
		setExistingNumberData({ ...ExistingNumberData, ready: false })
	}
	const clearFields = () => {
		setTypedSignature("")
		setDropDown(false)
		// setSignFont('')
		setExistingNumberData({ ...ExistingNumberData, 'signature_font': "Creattion" })
	}
	const trim = () => {
		let image = sigPad.getTrimmedCanvas().toDataURL('image/png');
		setTrimmedDataURL(image)
		ExistingNumberData.signature = image;

		if (ExistingNumberData.signature != '') {
			setExistingNumberData({ ...ExistingNumberData, ready: true })
		}
	}

	const div = useRef()

	const exportAsImage = async (el, imageFileName) => {
		setLoading(true)
		const canvas = await html2canvas(el);
		const image = trimCanvas(canvas).toDataURL("image/png");
		setTrimmedDataURL(image)
		ExistingNumberData.signature = image;
		ExistingNumberData.signature_text = typedSignature;
		setExistingNumberData({ ...ExistingNumberData, error: [], ready: true })
		toast.success("Signature saved!", { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light" })
		setLoading(false)
	};

	const typeSignatureChange = (e) => {
		setTypedSignature(e.target.value)
		setExistingNumberData({ ...ExistingNumberData, error: [], ready: false })
	}


	useEffect(() => {

		setExistingNumberData({ ...ExistingNumberData, "signature_font": "Creattion", "ready": false, "error": [] })
		if (ExistingNumberData?.signature_font) {

			if (trimmedDataURL != "") {
				setExistingNumberData({ ...ExistingNumberData, "ready": true, "error": [] })
			}
		}
	}, [typedSignature, trimmedDataURL, padEnable])


	// popup modal for signature
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			zIndex: 999
		},
	};

	let subtitle;
	const [modalIsOpen, setIsOpen] = useState(false);


	function openModal() {
		setIsOpen(true);
	}

	function afterOpenModal() {
		// references are now sync'd and can be accessed.
		// subtitle.style.color = '#f00';
	}

	function closeModal() {
		setIsOpen(false);
	}

	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Please confirm and sign these data:</h2>
			{/* <p>Sign the below and save your signature as an image.</p> */}


			{padEnable ?
				<>
					<span className="text-center d-block">

						{/* <button className="clearSignature" onClick={openModal}>OPEN SIGNATURE BOX</button> */}
					</span>

					<Modal isOpen={modalIsOpen}
						onAfterOpen={afterOpenModal}
						onRequestClose={closeModal}
						style={customStyles}
						contentLabel="Example Modal">
						{/* <div class="checkmark-wrapper">
              <span class="message">Ready To Go</span>
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
              </svg>
            </div> */}
						<div className="signature-box mb-1">

							<SignatureCanvas penColor='black'
								canvasProps={{ className: 'sigCanvas' }}
								ref={(ref) => { sigPad = ref }} style={{ width: "100px", height: "50px" }} />
						</div>

						{(trimmedDataURL || ExistingNumberData.signature) && <b>YOUR SIGNATURE</b>}
						<div className="d-flex justify-content-between mt-3">
							<button type="button" title="Reset" className="clearSignature" onClick={() => clear()}>RESET</button>

							<button type="button" title="Confirm" className="checkSignature" onClick={() => trim()}>Save</button>
							<button onClick={closeModal} className="clearSignature">CLOSE</button>

						</div>
					</Modal>
					<div className="d-flex justify-content-between mt-3 text-center">

						{(trimmedDataURL || ExistingNumberData.signature)
							? <img className="signatureConfirmed m-auto" alt='signature'
								src={ExistingNumberData.signature ? ExistingNumberData.signature : trimmedDataURL} />
							: null}
					</div>

				</>
				:
				<>
					<div className="row">
						<div className="col-lg-12 col-12">
							<label>Type your signature</label>
							<input placeholder="Sign Here" type="text" className="pmp-field" value={typedSignature} onChange={typeSignatureChange} />
						</div>
						{/* <div className="col-lg-6 col-12">
							<label>Select Signature Font</label>
							<div className="col position-relative">
								<div className="country-select-new-number" href="#" onClick={() => setDropDown(dropdown = !dropdown)}>
									{ExistingNumberData.signature_font ? ExistingNumberData.signature_font : "Please select the font"}
								</div>
								{dropdown &&
									<ul className="dropdown-menu visible-solid" aria-labelledby="navbarDarkDropdownMenuLink" onClick={() => setDropDown(dropdown = !dropdown)}>
										<li onClick={() => handleFontChange(1)}><div className="dropdown-item" href="#">Antro Vectra</div></li>
										<li onClick={() => handleFontChange(2)}><div className="dropdown-item" href="#">Arty Signature</div></li>
										<li onClick={() => handleFontChange(3)}><div className="dropdown-item" href="#">Creattion</div></li>
										<li onClick={() => handleFontChange(4)}><div className="dropdown-item" href="#">Southam</div></li>
									</ul>
								}
							</div>
						</div> */}
					</div>
					{(typedSignature || ExistingNumberData.signature)
						? <div ref={div} className={"signatureConfirmed topAdjustment text-center " + ExistingNumberData.signature_font} style={{ width: "auto", margin: "0 auto" }}>{typedSignature}</div>
						: null}
					{
						typedSignature &&
						<div className="d-flex justify-content-between mt-3">
							<button type="button" title="Reset" className="clearSignature" onClick={() => clearFields()}>RESET</button>
							{(typedSignature || ExistingNumberData.signature) && <b >YOUR SIGNATURE</b>}
							<button disabled={loading} type="button" title="Confirm Signature" className="checkSignature" onClick={() => exportAsImage(div.current)}>
								{
									loading ?
										<>
											<span>Save &nbsp;</span>
											<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
										</>
										:
										"Save"
								}
							</button>
						</div>

					}

				</>
			}
			<button onClick={() => padHandler(!padEnable)} type="button" className="add-number-btn">{!padEnable ? <span onClick={openModal}>Draw Signature Instead</span> : "Write Initials"}</button>
			<FormFooter action={'save-signature'} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default Signature
