import React, { useState, useContext, useRef } from 'react';
import ValidationError from './ValidationError';
import useAuth from 'hooks/useAuth';
import { toast } from 'react-toastify';
import { SignupContext } from 'contexts/FormsContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PinField } from 'react-pin-field';
import { useEffect } from 'react';

const EmailVerification = () => {

	const ref = useRef();
	const history = useHistory();
	const { verifyUser } = useAuth();
	const [tryCount, setTryCount] = useState(0);
	const { checkUserVerification } = useAuth();
	const [checkingCode, setCheckingCode] = useState(false);
	const [textMessagePin, setTextMessagePin] = useState(false);
	const { signupData } = useContext(SignupContext)
	const { handleSubmit, setError, formState: { errors }, clearErrors } = useForm();

	const checkOTP = async () => {

		const e = ref.current.map(map => map.value).join("")
		if (!e) {
			setError("code", { message: "Please enter code" })
			return false;
		}

		setTryCount(tryCount + 1);
		setCheckingCode(true);
		try {
			const response = await checkUserVerification({
				type: 'email',
				code: e,
				email: signupData?.email,
			});

			if (response.bool) {
				if (response.result.email) {
					toast.success('Verified Successfully');
					setTextMessagePin(true);
					history.push('/signup/phone-verification')
				} else {
					toast.error('Invalid pin');
				}
				setCheckingCode(false);
			}
		} catch (error) {
			setError("code", { message: error.message })
			setCheckingCode(false);
		}
	};

	const resendCodeHandler = async (e) => {
		console.log(ref.current.forEach(input => (input.value = "")));
		setCheckingCode(true);
		setTextMessagePin(false);
		await verifyUser({ 'email': signupData?.email, 'name': signupData?.first_name }).then((resp) => {
			if (resp?.bool) {
				toast.success(resp.message);
			}
			setCheckingCode(false);
		}).catch(err => {
			console.log(err)
			setCheckingCode(false);
		})
	}

	useEffect(() => {

		return () => {
			setTextMessagePin(false)
			setCheckingCode(false)
			setTryCount(0)
		}
	}, [])


	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(checkOTP)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Please verify your email</h2>
					<div className="row mb-3">
						<div className="col">
							<label className="mb-3">Enter the 6-digit verification code we sent to your email to verify your account.</label>
							<div className="code">
								<div className="digits">
									<PinField
										onChange={() => clearErrors('code')}
										ref={ref}
										length={6}
										inputMode="numeric"
										disabled={tryCount >= 5 || checkingCode}
										className={(textMessagePin ? 'complete' : '') + ' pin-field'}
										onComplete={checkOTP}
										autoFocus
									/>
								</div>
								<div className="resend-code">
									<button onClick={resendCodeHandler} type="button" className="border-0 bg-transparent">
										<img src="/images/forms/icon-resend-code-blue.png" alt="" />
										Resend the code
									</button>
								</div>
							</div>
						</div>
					</div>
					{/* From Footer */}
					<>
						<button type="button" onClick={() => history.goBack()} data-title="Previous" className="prev-step elaboration right">
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default EmailVerification
