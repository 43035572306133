/* eslint-disable no-unused-vars */
import SelectNumber from './new-number/SelectNumber'
import React, { useState, useContext } from 'react'
import { NewNumberContext } from 'contexts/FormsContext'
import './assets/css/order.css'
import './assets/css/general.css'
import OrderSummary from './new-number/OrderSummary'
import PaymentDetails from './new-number/PaymentDetails'
import Success from './new-number/Success'
import CreateProfile from './new-number/CreateProfile'
import AddOns from './new-number/AddOns'
import Plans from './new-number/Plans'

const NewNumber = ({ activeStep, setStep }) => {
	let { NewNumberData } = useContext(NewNumberContext)
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			setStep(activeStep + 1)
		}
	}
	return (
		<>
			{!NewNumberData?.userData ? //will change after auth
				activeStep === 1 &&
				<CreateProfile activeStep={activeStep} setStep={setStep} />
				:
				activeStep === 1 &&
				<SelectNumber activeStep={activeStep} setStep={setStep} />
			}
			{/* {activeStep === 2 &&
        <SelectFeatures activeStep={activeStep} setStep={setStep} />
      } */}
			{activeStep === 2 &&
				<Plans activeStep={activeStep} setStep={setStep} />
			}
			{activeStep === 3 &&
				<AddOns activeStep={activeStep} setStep={setStep} />
			}
			{/* {activeStep === 4 &&
        <Signature activeStep={activeStep} setStep={setStep} />
      } */}
			{activeStep === 4 &&
				<PaymentDetails activeStep={activeStep} setStep={setStep} />
			}
			{activeStep === 5 &&
				<OrderSummary activeStep={activeStep} setStep={setStep} />
			}
			{activeStep === 6 &&
				<Success />
			}
			{
				NewNumberData.error &&
				<div className="crossmark-wrapper">
					<span className="message">Please correct errors below</span>
					<svg className="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
						<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
						<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
					</svg>
				</div>
			}
			{
				NewNumberData.ready &&
				<div className="checkmark-wrapper">
					<span className="message">Ready To Go</span>
					<svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
						<path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
					</svg>
				</div>
			}
			{
				NewNumberData.processing &&
				<div className="circlemark-wrapper">
					<span className="message">Processing the form please wait</span>
					<svg className="circlemark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
						<circle className="circlemark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
						<circle className="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3"></circle>
					</svg>
				</div>
			}
		</>
	)
}

export default NewNumber
