/* eslint-disable no-unused-vars */
import React from "react";
import { useContext, useState } from "react";
import { ExistingNumberContext } from "contexts/FormsContext";
import PhoneInput from 'react-phone-input-2'
import Tip from "../../forms/sub-components/widgets/Tip";
import { formatPhone, unformatedPhone } from "general/FormatNumber";
import startsWith from 'lodash.startswith';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import ValidationError from "../ValidationError";
import { useForm } from "react-hook-form";
import RoundedButton from "components/Buttons/RoundedButton";
import { checkPortability, createOrderStepTracking, orderCreate, orderUpdate, updateOrderStepTracking } from "redux/actions/OrderAction";
import { toast } from "react-toastify";
import '../assets/css/first-phone.scoped.css'

const PortablilityCheck = () => {
	return (
		<div className="d-flex my-1">
			<span className="spinner-border spinner-border-sm text-info portability-check-spinner" role="status" aria-hidden="true"></span>
			{/* <span className="mx-1">Checking portability on {capitalize(name)} </span> */}
			<span className="mx-1">Checking your number please wait...</span>
		</div>
	)
}
const PortablilitySuccess = () => {
	return (
		<div>
			<span className="fa-stack">
				<i className="fa fa-circle-thin text-success fa-stack-2x"></i>
				<i className="fa fa-check text-success fa-stack-1x"></i>
			</span> {" "}
			Hurray! this number is portable.
		</div>
	)
}
const PortablilityFail = () => {
	return (
		<div>
			<span className="fa-stack">
				<i className="fa fa-circle-thin text-danger fa-stack-2x"></i>
				<i className="fa fa-times text-danger fa-stack-1x"></i>
			</span> {" "}
			Sorry! the number is not portable.
		</div>
	)
}

const FirstPhone = ({ setStep, activeStep, stepKey, tip, prevStep, tipBody, tipQuestion }) => {

	const countryList = useSelector(state => state.streetMap?.countries?.map(value => { return { 'index': value.id, 'value': value.id, 'label': value.name, "code": value.code.toLowerCase() } }));
	const dispatch = useDispatch();
	const { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [tipEnable, setTipEnable] = useState(false)
	const [loading, setLoading] = useState(false)

	/**
	 * checking status are not-checked, checking, passed, failed
	 */
	const [checkingPortability, setCheckingPortability] = useState({
		status: "not-checked",
		isPortable: false,
		component: <></>
	})

	// console.log(checkingPortability)
	const [phoneNumber, setPhoneNumber] = useState(ExistingNumberData?.first_number?.phoneNumber)

	const { handleSubmit, formState: { errors }, setError, clearErrors, setValue } = useForm({
		defaultValues: {
			'first_number': ExistingNumberData?.first_number?.phoneNumber || "",
		}
	});

	const handleOnChange = (e, value) => {
		// validate phone via library
		if (e.length > 1) {
			clearErrors() // remove validation errors on change
			const phoneNumber = parsePhoneNumber(e, value.countryCode.toUpperCase())
			// checking if number is valid or not via libphonenumber-js
			if (!phoneNumber.isValid() || phoneNumber.getType() === undefined) {
				setExistingNumberData({ ...ExistingNumberData, 'ready': false })
				setError('first_number', {
					message: "Number is not valid"
				})
				return false
			}

			if (phoneNumber.isValid() && phoneNumber.getType()) {
				let phoneNumberP = {
					phoneNumber: formatPhone(e),
					dialCode: value.dialCode,
					name: value.name,
					countryCode: value.countryCode,
					countryId: countryList.filter(row => row.code === value.countryCode)[0]?.value,
					number_type: phoneNumber.getType()
				}
				setValue('first_number', e)
				setPhoneNumber(e)
				setExistingNumberData({ ...ExistingNumberData, 'first_number': phoneNumberP, error: [], processing: false })
			}
		}
	}

	const checkPortabilityHandler = async (name) => {

		setCheckingPortability({
			...checkingPortability,
			status: "checking",
			component: <PortablilityCheck />
		})
		const numberDetails = ExistingNumberData?.first_number;

		const payload = {
			'phone_number': unformatedPhone(numberDetails.phoneNumber),
			'country_code': numberDetails.countryCode,
			'country_id': numberDetails.countryId,
			'vendor': name,
		}

		// bandwidth portability check

		return await dispatch(checkPortability(payload, ExistingNumberData.userData.token))
			.then(resp => resp.result.portable)
			.catch(error => error)
	}

	const submitHandler = async (e) => {
		setLoading(true)

		const [p1, p2, p3, p4] = await Promise.allSettled([
			checkPortabilityHandler("inteliquent"),
			checkPortabilityHandler("bandwidth"),
			checkPortabilityHandler("telnyx"),
			checkPortabilityHandler("flowroute")
		]);

		if (p1.value || p2.value || p3.value || p4.value) {
			setCheckingPortability({
				...checkingPortability,
				status: "passed",
				isPortable: true,
				component: <PortablilitySuccess />
			})
		} else {
			setCheckingPortability({
				...checkingPortability,
				status: "failed",
				isPortable: false,
				component: <PortablilityFail />
			})
			showToast("error", "Phone number is not Portable ")
			setLoading(false)
			return false;
		}

		// if order already created it will just update the order otherwise it will create new
		if (ExistingNumberData?.order_did.length) {

			const payload = {
				'number_id': ExistingNumberData.order_did[0]?.id, // order did Id
				'order_id': ExistingNumberData.order_did[0]?.order_id, // order did Id
				"number": unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
				"country_id": ExistingNumberData?.first_number?.countryId
			}

			await dispatch(orderUpdate(payload, ExistingNumberData.userData.token)).then(async (response) => {

				await dispatch(updateOrderStepTracking({
					'order_id': payload.order_id,
					'step_id': stepKey,
					'state_body': ExistingNumberData,
				}, ExistingNumberData?.stepTrackingId, ExistingNumberData.userData.token))
				setExistingNumberData({ ...ExistingNumberData, error: [], processing: false, ready: false })
				setLoading(false)
				showToast()
				setStep(activeStep + 1)
			}).catch(exp => {
				console.log(exp)
				// setExistingNumberData({ ...ExistingNumberData, error: ["error"], processing: false, ready: false })
				setLoading(false)
			})

		} else {

			if (ExistingNumberData?.first_number === "") {
				setError('first_number', { 'message': "Number field is required" })
				setLoading(false);
				return false;
			}

			const payload = {
				"new_number": false,
				"user_id": ExistingNumberData.user_id,
				"authorized_first_name": ExistingNumberData.firstname,
				"authorized_last_name": ExistingNumberData.lastname,
				"number": unformatedPhone(ExistingNumberData?.first_number?.phoneNumber),
				"country_id": ExistingNumberData?.first_number?.countryId
			}

			await dispatch(orderCreate(payload, ExistingNumberData.userData.token)).then(async response => {

				await dispatch(createOrderStepTracking({
					'order_id': response?.result?.order_id,
					'step_id': 1,
					'active_funnel': 0,
					'state_body': ExistingNumberData,
				}, ExistingNumberData.userData.token)).then(respData => {
					setExistingNumberData({
						...ExistingNumberData,
						'order_id': response?.result?.order_id,
						'order_did': [response.result],
						'stepTrackingId': respData['result']?.id,
						error: [],
						processing: false,
						ready: false
					})
				}).catch(errr => console.log(errr))
				setLoading(false)
				showToast()
				setStep(activeStep + 1)

			}).catch(exp => {
				setExistingNumberData({ ...ExistingNumberData, processing: false, ready: false })
				setError('first_number', { 'message': exp?.message })
				console.log(exp)
				setLoading(false)
			})
		}
	}

	const showToast = (type = 'success', message = "Your Data has been saved") => {
		toast[type](message, { position: toast.POSITION.BOTTOM_CENTER, autoClose: 1500, theme: "light", })
	}

	useEffect(() => {
		setExistingNumberData({ ...ExistingNumberData, ready: false, error: [] })

		if (phoneNumber) {
			if (unformatedPhone(phoneNumber)?.length >= 11) {
				setExistingNumberData({ ...ExistingNumberData, error: [], "ready": true })
			} else {
				setExistingNumberData({ ...ExistingNumberData, error: ["Invalid Length"], "ready": false })
			}
		}

	}, [phoneNumber])

	return (
		<>
			<form onSubmit={handleSubmit(submitHandler)}>
				<ValidationError errors={errors} />
				<div className="step">
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Let's start with the first phone number you want to move (port) to us ?</h2>
					<div className="row align-items-center">
						<div className="col">
							<PhoneInput
								country={'us'}
								inputClass="default"
								onlyCountries={['us', 'ca']}
								value={phoneNumber}
								countryCodeEditable={false}
								containerClass={(ExistingNumberData.error.includes("initial-number") ? "error" : "") + " PhoneInput"}
								onChange={(e, value) => handleOnChange(e, value)}
								placeholder="Enter Phone Number"
								isValid={(inputNumber, country, countries) => {
									return countries.some((country) => {
										return startsWith(inputNumber, country.dialCode) || startsWith(country.dialCode, inputNumber);
									});
								}}
							/>
						</div>
						<div className="col">
							{
								checkingPortability?.component
							}
						</div>
					</div>
					<Tip tipBody={tipBody} tipQuestion={tipQuestion} />

					{/* From Footer */}
					<>
						<div className={"footer"} >
							<div className="d-flex flex-column align-items-center">
								<RoundedButton loading={loading} loadingText={"Proceed"} showLoader={false}>
									Proceed
								</RoundedButton>
								<div className="pe">
									Press Enter
									<img src="/images/forms/icon-enter.png" alt="" />
								</div>
							</div>

						</div>
						{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
						<button type="button" data-title="Previous" onClick={() => setStep(activeStep - 1)} className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}>
							<img src="/images/forms/icon-prev.png" alt="" />
						</button>
						{/* <a className="next-step" onclick="form.steps('next')"> */}
						<button type="submit" data-title="Next" className="next-step elaboration right">
							<img src="/images/forms/icon-next.png" alt="" />
						</button>
					</>
					{/* From Footer end */}
				</div>

			</form>
		</>
	)
}

export default FirstPhone
