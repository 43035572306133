import { ExistingNumberContext } from "contexts/FormsContext";
import { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import AddCarrier from "../../forms/sub-components/widgets/AddCarrier";
import CarrierPill from "../../forms/sub-components/widgets/CarrierPill";
import FormFooter from "./FormFooter";


const Carrier = ({ setStep, activeStep }) => {

	const carrierList = useSelector((state) => state.carrier)
	// const topCarriers = ["Verizon Wireless", "AT&T Wireless", "T-Mobile"];
	const carrierMain = carrierList.filter(values => values.is_main)
	// const carrierSearchPills = carrierList.filter(values => !carrierList.includes(values.name))
	const carrierSearchPills = carrierList.filter(values => !values?.is_main)
	let { ExistingNumberData, setExistingNumberData } = useContext(ExistingNumberContext)
	const [payload, setPayload] = useState({
	})

	const [list, setList] = useState(carrierSearchPills);
	const filterCarriers = (e) => {
		let data = carrierSearchPills.filter(row => row.name.toUpperCase().includes(e.target.value.toUpperCase()))
		setList(data)
	}

	useEffect(() => {

		setExistingNumberData({ ...ExistingNumberData, "ready": ExistingNumberData.carrier === "" ? false : true })

		setPayload({
			'carrier_id': ExistingNumberData.carrier
		})
	}, [ExistingNumberData.carrier])


	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
			<h2 className="lg-heading">Which carrier/phone company do you have the service with?</h2>
			<div className="row">
				<div className="col">
					<div className="three_main_carriers">
						<div className="row">
							{carrierMain.map((row, index) => {

								return (
									<CarrierPill key={index} data={row} main={row.is_main} />
								)
							})}
						</div>
					</div>
					<div className="input-group mt-3 w-100 wrapper_search_carriers">
						<input type="search" className="form-control border-0 search_carriers" placeholder="Search Carrier" onChange={(e) => filterCarriers(e)} />
						<button className="btn bg-white shadow-none border-0 m-0" type="button" id="button-addon2"><i className="fa fa-search"
							aria-hidden="true"></i></button>
					</div>
					<div className="other_main_carriers">
						<div className="row">
							{/* {carrierSearchString !== ''
								?
								carrierSearchPills.map((row, index) => (
									row.key.includes(carrierSearchString) ? <CarrierPill key={index} data={row} main={false} /> : ""
								))
								: */}
							{

								list.map((row, index) => (
									<CarrierPill key={index} data={row} main={row.is_main} />
								))
							}
						</div>
					</div>
				</div>
			</div>
			<AddCarrier newlyAddedCarrier={(e) => { setList([...list, e]) }} />
			<FormFooter action={'order-carrier-update'} payloadForUpdate={payload} activeStep={activeStep} setStep={setStep} />
		</div>
	)
}

export default Carrier
