import { CoverageContext } from "contexts/FormsContext";
import React, { useContext, useState } from "react";
import FormFooter from "./FormFooter";

// import { OrderContext } from "contexts/OrderContext";

const Dropdown = ({ setStep, activeStep }) => {
	//   let [activeFormat, setFormat] = useState('')
	const { coverage, setCoverage } = useContext(CoverageContext)
	const [form, setForm] = useState(coverage.accountFor)
	const handleChange = (e) => {
		setForm(e.target.value)
		setCoverage({ ...coverage, accountFor: e.target.value })
	}
	return (
		<div className="step">
			<h5 className="mb-2 sm-heading">PORTABILITY CHECK</h5>
			<h2 className="lg-heading mb-5">To complete your registration please<br />let us know if this account is for</h2>
			<div className="row mb-4">
				<div className="col">
					<select name="accountFor" defaultValue={form} onChange={(e) => handleChange(e)} className="orderDropdown">
						<option value="">Select</option>
						<option value="ParkMyPhone">Mobile Number</option>
						{/* <option selected={form.accountFor === 'ParkMyPhone'} value="ParkMyPhone">Mobile Number</option> */}
						<option value="ParkMyCellPhone">Landline</option>
						{/* <option selected={form.accountFor === 'ParkMyCellPhone'} value="ParkMyCellPhone">Landline</option> */}
						<option value="ParkMyFax">Fax</option>
						{/* <option selected={form.accountFor === 'ParkMyFax'} value="ParkMyFax">Fax</option> */}
						<option value="ParkMy800">Toll Free</option>
						{/* <option selected={form.accountFor === 'ParkMy800'} value="ParkMy800">Toll Free</option> */}
					</select>
				</div>
			</div>
			<FormFooter action={'update'} activeStep={activeStep} setStep={setStep} />
		</div >
	)
}

export default Dropdown
