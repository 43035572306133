import Sidebar from "components/Customer/Sidebar";
import TopHeader from "components/Customer/TopHeader";
import Loading from "components/Loader/Loading";
import AppContext from "contexts/AppContext";
import { Suspense, useState } from "react";
import { useEffect } from "react";
import 'Theme/v1/css/style.css'
import 'Theme/v1/css/custom.css'
import 'Theme/v1/vendors/simplebar/css/simplebar.css'
import 'Theme/v1/css/vendors/simplebar.css'

import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { renderRoutes } from "react-router-config";
import useAuth from "hooks/useAuth";
import { toast } from "react-toastify";
import { Accordion, Button, Card } from "react-bootstrap";
import echo from "echo";
import { useDispatch } from "react-redux";
import { checkMyNotifications, getMyNotifications } from "redux/actions/NotificationAction";
import { Link } from "react-router-dom";

const Admin = () => {

	const dispatch = useDispatch()
	const { routes } = useContext(AppContext);
	const [sidebar, setSidebar] = useState(true);
	const [isAlive, setIsAlive] = useState(false);
	const [loading, setLoading] = useState({ "email": false, "phone": false });
	const [emailOTP, setEmailOTP] = useState("");
	const [phoneOTP, setPhoneOTP] = useState("");
	const [showOTPBox, setShowOTPBox] = useState(false);
	const [showOTPBoxPhone, setShowOTPBoxPhone] = useState(false);
	const { user, verifyUser, checkUserVerification } = useAuth();
	const [notifications, setnotifications] = useState({});

	const verifyEmailClickHandler = async (type) => {

		if (type === "email") {
			setLoading({ "email": true });
			await verifyUser({ 'email': user.email, 'name': user.first_name }).then(({ mail, phone }) => {
				if (mail?.original?.bool) {
					toast.success("Kindly Check your email for the code");
					setShowOTPBox(true);
				}
				toast.info(mail);
			})
			setLoading({ "email": false });
		}
		else if (type === "phone") {
			setLoading({ "phone": true });
			await verifyUser({ 'phone_no': user.phone, 'name': user.first_name }).then(({ mail, phone }) => {

				if (phone?.original?.bool) {
					toast.success("Kindly Check your phone for the code");
					setShowOTPBoxPhone(true);
				} else {

					toast.warning(phone);
				}
				// toast.info(phone);
			})
			setLoading({ "phone": false });
		} else {
			toast.error("Invalid type")
		}
	}

	const verifyOTP = async (type) => {

		if (type === "email") {
			setLoading({ "email": true });

			await checkUserVerification({ 'type': 'email', 'code': emailOTP, 'email': user.email }).then((response) => {
				if (response.bool) {
					if (response.result?.email) {
						toast.success("Verified Successfully");
						// history.push('/')
					} else {

						toast.error("Invalid code");
					}
				} else {
					toast.success(response.message);
				}

			})
			setLoading({ "email": false });
		} else if (type === 'phone') {
			setLoading({ "phone": true });
			await checkUserVerification({ 'type': 'phone', 'code': phoneOTP, 'phone': user.phone }).then((response) => {
				if (response.bool) {
					toast.success("Verified Successfully");
				} else {
					toast.success(response.message);
				}
			})
			setLoading({ "phone": false });
		} else {
			toast.error('No type selected');
		}
	}

	useEffect(() => {
		setIsAlive(true)
		dispatch(checkMyNotifications())
		// dispatch(getMyNotifications())
		const channel = echo.channel('user-notification');
		channel.listen('.current-user-notifications', (data) => {
			if (Object.keys(data).length > 0) {
				// toast.success("You have Notifications")
				setnotifications(data.message)
			}
		});

		return () => {
			channel.stopListening('.current-user-notifications');
			setnotifications({})
		};

	}, [])

	return (
		<>
			<Helmet>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
				<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap" rel="stylesheet" />
				<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/prismjs@1.23.0/themes/prism.css" />

			</Helmet>
			{
				!isAlive ?
					<Loading />
					:
					<>
						<Sidebar toggle={sidebar} />
						<div className="wrapper main-wrapper d-flex flex-column min-vh-100">
							<TopHeader sidebar={sidebar} toggleSidebar={() => setSidebar(!sidebar)} />
							<div className="body px-1 px-sm-3 pb-3">
								<div className="container-fluid">
									{/* Did Notifications (e.g. missing destinations) */}

									{
										notifications?.did ?
											<Accordion>
												<Card style={{ maxWidth: "100%" }} className="shadow-sm">
													<Accordion.Toggle eventKey="0" as={'span'}>
														<Button className="border-0 shadow-0 m-0 w-100 text-start btn btn-info bg-gradient p-3">
															<span className="text-white">
																You have <b>({notifications?.did.length}) New</b> DID Notifications. Click here to view.
															</span>
														</Button>
													</Accordion.Toggle>
													<Accordion.Collapse eventKey="0">
														<Card.Body>
															<ul className="notifications_list m-0">
																{
																	notifications?.did.map(didRow => {
																		return (
																			<li>
																				<>
																					<span dangerouslySetInnerHTML={{ __html: didRow?.description }}>
																					</span> &nbsp;
																					<Link to={didRow?.record_url} className="click_noti">Click here</Link>
																				</>
																			</li>
																		)
																	})
																}
															</ul>
														</Card.Body>
													</Accordion.Collapse>
												</Card>
											</Accordion>
											:
											<></>
									}
								</div>
							</div>
							{user.email_verified_at !== true && user.phone_verified_at !== true ?
								<Suspense fallback={<Loading />}>{renderRoutes(routes)}</Suspense>
								:
								// If user has unverified email or phone
								<div className="unverified-user">
									<div className="card w-75">

										<div className="card-body">
											<p>Hi <span className="fw-bold">{user.name.split(' ')[0]}</span>, We're happy you signed up. To start exploring,</p>
											{!user.email_verified_at &&
												<>
													<p className="p-0 m-0">Please confirm your email address.</p>
													<div className="py-3 d-flex w-25">
														{showOTPBox ?
															<>
																<input type="text" name="otp" className="form-control" value={emailOTP} onChange={(e) => setEmailOTP(e.target.value)} />
																<button className="btn text-light fw-bold btn-info mx-2" onClick={() => verifyOTP('email')}>Verify</button>
															</>
															:
															<button onClick={() => verifyEmailClickHandler('email')} className="btn btn-info text-light fw-bold btn-sm" disabled={loading?.email}>
																{
																	loading.email ?
																		<>
																			<span>Verify now </span>
																			<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																		</>
																		:
																		"Verify now"
																}
															</button>
														}
													</div>
												</>
											}
											{!user.phone_verified_at &&
												<>
													<p className="p-0 m-0">Please confirm your phone number.</p>
													<div className="py-3 d-flex w-25">
														{showOTPBoxPhone ?
															<>
																<input type="text" name="otp" className="form-control" value={phoneOTP} onChange={(e) => setPhoneOTP(e.target.value)} />
																<button className="btn text-light fw-bold btn-info mx-2" onClick={() => verifyOTP('phone')}>Verify</button>
															</>
															:
															<button onClick={() => verifyEmailClickHandler('phone')} className="btn btn-info text-light fw-bold btn-sm" disabled={loading?.phone}>
																{
																	loading.phone ?
																		<>
																			<span>Verify now </span>
																			<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																		</>
																		:
																		"Verify now"
																}
															</button>
														}
													</div>
												</>
											}
											<p>Thanks! </p>
										</div>
									</div>
								</div>
							}
						</div>
					</>
			}
		</>
	);
};

export default Admin;
