/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useContext, useState, useRef } from "react";

import PinField from "react-pin-field"
import { SignupContext } from "contexts/FormsContext";
import ValidationError from "./ValidationError";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { formatPhone, unformatedPhone } from "general/FormatNumber";
import useAuth from "hooks/useAuth";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { checkValidateViaAPI } from "redux/actions/UserAction";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const PhoneVerification = () => {
	const history = useHistory()
	const dispatch = useDispatch(false)
	const [loading, setLoading] = useState(false)
	const [tryCount, setTryCount] = useState(0)
	const [checkingCode, setCheckingCode] = useState(false)
	const { signupData, setSignupData } = useContext(SignupContext)

	const ref = useRef();
	const { verifyUser, checkUserVerification } = useAuth();

	const [verify_req_sent, set_verify_req_sent] = useState(false)
	const [textMessagePin, setTextMessagePin] = useState(false);

	const [phoneNumberInput, setPhoneNumberInput] = useState({
		first_phone: signupData.first_phone || {}
	})

	const [errors, setErrors] = useState({})
	const { handleSubmit } = useForm();

	const handleOnChange = (e, value) => {
		let phoneNumberP = { phoneNumber: formatPhone(e), dialCode: value.dialCode, name: value.name, countryCode: value.countryCode }
		setPhoneNumberInput(phoneNumberP);
	}

	const submitHandler = async (e) => {

		ref.current?.map(row => row.value = "") //reset old value if exist
		setTryCount(0)
		setTextMessagePin(false)
		setLoading(true)
		if (phoneNumberInput?.phoneNumber) {

			if (unformatedPhone(phoneNumberInput?.phoneNumber).length === 11) {
				setErrors({})
				await dispatch(checkValidateViaAPI({ "phone": unformatedPhone(phoneNumberInput?.phoneNumber) })).then(async (response) => {

					await verifyUser({ 'phone_no': "+" + unformatedPhone(phoneNumberInput?.phoneNumber), 'name': signupData?.first_name }).then((resp) => {
						if (resp?.bool) {
							toast.success(resp.message);
							set_verify_req_sent(true)
						}
						setLoading(false)
					})
				}).catch(err => {
					setErrors({ "phone": { message: err.message } })
					setLoading(false)
					return false;
				})

			} else {
				setErrors({
					firstPhone: {
						message: "Length is not valid"
					}
				})
				setLoading(false)
			}
		} else {
			setErrors({
				firstPhone: {
					message: "This field is required"
				}
			})
			setLoading(false)
		}
	}

	const checkOTP = async (e) => {
		setTryCount(tryCount + 1)
		setCheckingCode(true)
		await checkUserVerification({ 'type': 'phone', 'code': e, 'phone': "+" + unformatedPhone(phoneNumberInput?.phoneNumber) }).then((response) => {
			if (response?.bool) {
				if (response?.result?.phone) {
					toast.success("Verified Successfully");
					setTextMessagePin(true)
					setSignupData({ ...signupData, 'first_phone': phoneNumberInput })
					history.push('/signup/account-for')
				} else {

					toast.error("Invalid pin");
				}
				setCheckingCode(false)
			} else {
			}
		}).catch(err => {
			console.log("Error", err.getMessage())
		})
		// setSignupData({ ...signupData, 'first_phone': phoneNumberInput })
		// setTextMessagePin(true)
		// setStep(activeStep + 1);
	}

	useEffect(() => {
		return () => {
			ref?.current?.map(data => data.value = "")
		}
	}, [])

	return (
		<>
			<ValidationError errors={errors} />
			<div className="step">
				<form onSubmit={handleSubmit(submitHandler)}>
					<h5 className="mb-2 sm-heading">WE PROVIDE PERSONALIZED SERVICE TO OUR CUSTOMERS</h5>
					<h2 className="lg-heading">Can we get a current working mobile phone number?</h2>
					<div className="row mb-3">
						<div className="col-6 d-flex">
							<PhoneInput
								inputClass="default"
								country={'us'}
								onlyCountries={['us', 'ca']}
								countryCodeEditable={false}
								onChange={(e, value) => handleOnChange(e, value)}
							/>
							<button type="submit" className={loading ? "bg-secondary phoneMaskContinue h-100" : "phoneMaskContinue h-100"} id="code-button" disabled={loading}>

								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
									<path fill="var(--ci-primary-color, currentColor)" d="M474.444,19.857a20.336,20.336,0,0,0-21.592-2.781L33.737,213.8v38.066l176.037,70.414L322.69,496h38.074l120.3-455.4A20.342,20.342,0,0,0,474.444,19.857ZM337.257,459.693,240.2,310.37,389.553,146.788l-23.631-21.576L215.4,290.069,70.257,232.012,443.7,56.72Z" className="ci-primary"></path>
								</svg>

							</button>
						</div>
					</div>
					{
						verify_req_sent &&
						<div className="row mb-3">
							<div className="col">
								<label className="mb-3">Enter 6 digit verification code sent to above number to verify your identity.</label>
								<div className="code">
									<div className="digits">
										<PinField length={6}
											ref={ref}
											inputMode="numeric"
											disabled={tryCount >= 5 || checkingCode}
											className={(textMessagePin ? "complete" : "") + " pin-field"} onComplete={checkOTP} autoFocus />
									</div>
									<a href="#" className="resend-code">
										<button type="submit" className="border-0 bg-transparent">
											<img src="/images/forms/icon-resend-code-blue.png" alt="" />
											Resend the code
										</button>

									</a>
								</div>
								<div id="timer"></div>
							</div>
						</div>
					}

					{/* From Footer */}

					{textMessagePin &&
						<>
							<div className={"footer"} >
								{/* <a className="proceed-btn" id="cond_btn" onclick="form.steps('next')"> */}
								<div className="d-flex flex-column align-items-center">
									<button type='submit' className="proceed-btn mb-2" disabled={loading} onClick={() => { history.push('/signup/account-for') }}>
										{
											loading ?
												<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
												:
												"Proceed"
										}
									</button>
								</div>

							</div>
						</>
					}
					{/* <a className="prev-step disabled" onclick="form.steps('previous')"> */}
					<button type="button" data-title="Previous" onClick={() => { history.goBack() }}

						className={signupData.activeSocialFormat != "email" ? "prev-step elaboration right" : "prev-step elaboration right"}
					// className={(activeStep === 0 ? "prev-step disabled" : "prev-step") + " elaboration right"}
					>
						<img src="/images/forms/icon-prev.png" alt="" />
					</button>
					{/* <a className="next-step" onclick="form.steps('next')"> */}
					<button type="submit" data-title="Next" className="next-step elaboration right">
						<img src="/images/forms/icon-next.png" alt="" />
					</button>
					{/* From Footer end */}
				</form>
			</div>
		</>
	)
}

export default PhoneVerification
