/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import Footer from 'components/Public/Footer'
import Topbar from 'components/Public/Topbar'
import Title from 'general/Title'
import Initial from './sub-components/signup-components/Initial'
import PersonalData from './sub-components/signup-components/PersoanalData'
import PhoneVerification from './sub-components/signup-components/PhoneVerification'
import Goals from './sub-components/signup-components/Goals'
import Success from './sub-components/signup-components/Success'
import React, { useState } from 'react'
import { SignupProvider } from 'contexts/FormsContext'
// import './assets/css/order.css'
// import './assets/css/general.css'
import { useRef } from 'react'
import { useEffect } from 'react'

const SignupVerify = () => {

  let [activeStep, setActiveStep] = useState(0)
  let [error, setError] = useState(false)
  let [processing, setProcessing] = useState(false)
  let [ready, setReady] = useState(false)
  let [maxStepReached, setMaxStepReached] = useState(0)
  let [audio, setAudio] = useState(true)
  const prevStep = useRef();
  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevStep.current = activeStep;
  }, [activeStep]);
  const setStep = (e) => {
    if (e >= 0) {
      maxStepReached = e > maxStepReached ? setMaxStepReached(e) : maxStepReached
      activeStep = setActiveStep(e);
      console.log("previous step count " + prevStep.current)
    }
  }
  let [activeFunnel, setFunnel] = useState(0)
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setStep(activeStep + 1)
    }
  }
  const stepTitle = [
    "Sign Up With",
    "Name & Email",
    "Phone Number",
    "Goals",
  ]
  const markupForStepsCounter = () => {
    const maxSteps = 4;
    let stepsCounterMarkup = [];

    for (let index = 0; index < maxSteps; index++) {
      stepsCounterMarkup.push(
        <li data-title={stepTitle[index]} className={(index == activeStep ? "active " : index < activeStep ? "accessable active " : maxStepReached >= index ? "accessable " : "") + "navigationItem elaboration bottom"} onClick={() => index == activeStep ? setStep(index) : index < activeStep ? setStep(index) : maxStepReached >= index ? setStep(index) : ""}></li>
      );
    }
    return stepsCounterMarkup;
  };


  return (
    <>
      <Title title={"Signup Form"} />
      <Topbar />
      <div className="stepsnavigation">
        {markupForStepsCounter()}
      </div>
      <div className={(audio ? '' : 'disabled ') + 'audioToggle'} onClick={() => setAudio(!audio)} >
        <i className={audio ? 'fa fa-volume-up' : 'fa fa-volume-off'}></i>
      </div>
      <div className="signup_body">
        <form id="example-advanced-form" action="#" className="main wizard clearfix signup" onKeyDown={handleKeyDown}>
          {error &&
            <div class="crossmark-wrapper">
              <span class="message">Please correct errors below</span>
              <svg class="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
                <path class="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
                <path class="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
              </svg>
            </div>
          }
          {ready &&
            <div class="checkmark-wrapper">
              <span class="message">Ready To Go</span>
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"></circle>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
              </svg>
            </div>
          }
          {processing &&
            <div class="circlemark-wrapper">
              <span class="message">Processing the form please wait</span>
              <svg class="circlemark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="circlemark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
                <circle class="loader-path" cx="26" cy="26" r="17" fill="none" stroke="#ffffff" stroke-width="3"></circle>
              </svg>
            </div>
          }
          <SignupProvider>
            {activeStep === 0 &&
              <Initial activeStep={activeStep} setStep={setStep} />
            }
            {activeStep === 1 &&
              <PersonalData activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} />
            }
            {activeStep === 2 &&
              <PhoneVerification activeStep={activeStep} setStep={setStep} prevStep={prevStep.current} />
            }
            {activeStep === 3 &&
              <Goals activeStep={activeStep} setStep={setStep} />
            }
            {activeStep === 4 &&
              <Success activeStep={activeStep} setStep={setStep} />
            }
          </SignupProvider>
        </form>
      </div>
      <Footer />
    </>
  )
}

export default SignupVerify
