import { GET_SERVICES, GET_SERVICE_TYPES } from "redux/actions/ServicesAction"

const initialState = {
	services: {},
	serviceTypes: []
}


const ServicesReducer = function (state = initialState, action) {
	switch (action.type) {
		case GET_SERVICES:
			return {
				...state,
				services: {...action.payload}
			}
		case GET_SERVICE_TYPES:
			return {
				...state,
				serviceTypes: [...action.payload]
			}
		default:
			return { ...state }

	}
}

export default ServicesReducer
