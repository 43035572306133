import React from 'react'

const ValidationError = ({ errors }) => {
	return (
		<>
			{
				Object.keys(errors).length !== 0 ?
					Object.keys(errors).map((row, i) =>
						<>
							<div className="crossmark-wrapper" key={i}>
								<span className="message">{errors[Object.keys(errors)[0]]?.message ?? "Please correct errors below"}</span>
								{/* <span className="message">{errors[row]?.message ?? "Please correct errors below"}</span> */}
								<svg className="crossmark addClass animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
									<circle className="crossmark__circle addClass animateElement" cx="26" cy="26" r="25" fill="none"></circle>
									<path className="cross__path cross__path--right addClass animateElement" fill="none" d="M16,16 l20,20"></path>
									<path className="cross__path cross__path--left addClass animateElement" fill="none" d="M16,36 l20,-20"></path>
								</svg>
							</div>
						</>
					)
					:
					null
			}
		</>
	)
}

export default ValidationError
